import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


export const initialState = {
    filters: null
}

const scheduleFilterSlice = createSlice({
    name: 'scheduleFilters',
    initialState,
    reducers: {
        filterSetAll: (state, action) => {
            state.filters = action.payload
        },
    },
})

const { actions, reducer } = scheduleFilterSlice;
export const { filterSetAll } = actions;

export default reducer;
