import { takeLeading, call, put } from "redux-saga/effects";
import { history } from "../../App";

import { auth, usersService } from "../../services";
import {
  loadingEdit,
  errorEdit,
  authEdit,
  successEdit,
  loadingMainPageEdit,
  sentCodeEdit,
} from "../slices/auth.slice";
import { userSet } from "../slices/personality.slice";
import Cookies from "universal-cookie";
import { contentEdit, modalEdit } from "../slices/modal.slice";
import { USER_ROLES } from "constants/permissons";
export const cookies = new Cookies();

export function* checkTokenAuth(error) {
  const message = error.response.data.message;
  if (
    message === "Access denied" ||
    message === "Your account has been blocked" ||
    message === "Token missing" ||
    message === "Invalid token" ||
    message === "invalid token" ||
    message === "jwt malformed" ||
    message === "jwt expired"
  ) {
    try {
      const response = yield call(auth.getTokens, {
        userId: localStorage.getItem("userId"),
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      return "auth";
    } catch (error) {
      yield put(authEdit(false));
      return "notAuth";
    }
  } else {
    return message;
  }
}

function* signUp(action) {
  yield put(loadingEdit(true));
  try {
    yield call(auth.checkSignUpVerificationCode, {
      email: action.payload.email,
      code: action.payload.code,
    });
    const response = yield call(auth.signUp, {
      name: action.payload.name,
      email: action.payload.email,
      password: action.payload.password,
      phone: action.payload.phone,
      studioAddress: action.payload.studioAddress,
      studioName: action.payload.studioName,
    });
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("userId", response.data.user.id);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    yield put(authEdit(true));
    yield put(
      userSet({
        ...response.data.user,
        isAdmin: response.data.user.role === USER_ROLES.ADMIN ? true : false,
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* signIn(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(auth.signIn, {
      email: action.payload.email,
      password: action.payload.password,
    });
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("userId", response.data.user.id);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    yield put(authEdit(true));
    yield put(
      userSet({
        ...response.data.user,
        isAdmin: response.data.user.role === USER_ROLES.ADMIN ? true : false,
      })
    );
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* sendVerificationCode(action) {
  yield put(loadingEdit(true));
  try {
    yield call(auth.sendVerificationCode, action.payload);
    yield put(sentCodeEdit(true));
    yield put(successEdit("Code has been sent to your email"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* resetPassword(action) {
  yield put(loadingEdit(true));
  try {
    yield call(auth.resetPassword, action.payload);
    yield put(
      contentEdit(
        "Password has been sent on your email. Please login to your account"
      )
    );
    yield put(modalEdit("Confirm"));
    yield history.push("/");
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* logOut(action) {
  yield put(loadingEdit(true));
  try {
    yield call(auth.logOut, action.payload);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    yield put(authEdit(false));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* getUserByToken(action) {
  yield put(loadingMainPageEdit(true));
  try {
    const response = yield call(usersService.getUserByToken);
    yield put(
      userSet({
        ...response.data,
        isAdmin: response.data.role === 1 ? true : false,
      })
    );
    yield put(authEdit(true));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingMainPageEdit(false));
  }
}

function* changePassword(action) {
  yield put(loadingEdit(true));
  try {
    yield call(auth.changePassword, action.payload);
    yield put(contentEdit("Password has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchAuthSaga() {
  yield takeLeading("LOG_IN", signIn);
  yield takeLeading("SIGN_UP", signUp);
  yield takeLeading("SEND_VERIFICATION_CODE", sendVerificationCode);
  yield takeLeading("RESET_PASSWORD", resetPassword);
  yield takeLeading("LOG_OUT", logOut);
  yield takeLeading("GET_USER_BY_TOKEN", getUserByToken);
  yield takeLeading("CHANGE_PASSWORD", changePassword);
}
