import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../FormWrapper/index.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorEdit, successEdit } from "../../../redux/slices/auth.slice";
import PasswordField from "../Field/PasswordField";
import Field from "../Field";
import FormWrapper from "../FormWrapper";
import { Link } from "react-router-dom";

const loginFormYup = {
  email: yup
    .string()
    .required("This field is required")
    .max(255, "Email is too long - should be 255 chars maximum"),
  password: yup
    .string()
    .required("No password provided.")
    .max(255, "Password is too long - should be 255 chars maximum"),
};

const LoginForm = () => {
  const { error, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(yup.object(loginFormYup).required()),
  });

  const onSubmit = (value) => {
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    dispatch({
      type: "LOG_IN",
      payload: { ...value, code: value.code?.trim() },
    });
  };

  return (
    <FormWrapper clearErrors={clearErrors} reset={reset}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles["auth-form"]}>
        <div className={styles[`auth-form__answer`]}>
          {error && <div className="text-danger">{error}</div>}
          {success && <div className="text-success">{success}</div>}
        </div>
        <div className={styles.inputsWrapper}>
          <Field
            registerProps={register("email")}
            label={"Email"}
            error={errors.email}
            type={"email"}
          />
          <PasswordField
            registerProps={register("password")}
            error={errors.password}
            label={"Password"}
          />
        </div>
        <input type="submit" className={styles["submit"]} value={"Log In"} />
        <div className={styles.forgotPassword}>
          <Link to="/resetPassword">Forgot password</Link>
        </div>
      </form>
    </FormWrapper>
  );
};

export default LoginForm;
