import { CommonService } from './CommonService';

export class BoardService extends CommonService {

    entity;

    constructor(name_entity) {
        super(`${name_entity}`);
        this.entity = name_entity;
    }
    updateBoardTime = (body) => this.request('patch', `update-board-time`, '', body);
}
