import { CommonService } from "./CommonService";
import { AuthService } from "./AuthService";
import { UsersService } from "./UsersService";
import { BoardService } from './BoardService';
import { SubscriptionService } from './Subscription';
import { ScheduleService } from "./ScheduleService";


export const teachersService = new CommonService('teacher');
export const levelsService = new CommonService('level');
export const stickersService = new CommonService('sticker');
export const stylesService = new CommonService('style');
export const studioService = new CommonService('studio');

export const scheduleService = new ScheduleService('schedule'); 
export const boardsService = new BoardService('board');
export const subscriptionsService = new SubscriptionService('subscription');
export const usersService = new UsersService();
export const auth = new AuthService();
