import React, { useEffect } from 'react';
import { modalEdit } from '../../redux/slices/modal.slice';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './index.module.scss';
import { subscriptionsSetAll } from '../../redux/slices/subscriptions.slice';
import { selectAll as selectAllSubscription } from '../../redux/slices/subscriptions.slice';
import OneSubscription from '../../components/OneSubscription';
import { Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { errorEdit, loadingEdit } from '../../redux/slices/status.slice';

/*   Basic:
         Up to 3 rooms
         Up to 60 individual classes
         $249.99 / Year
Premium:
         Up to 5 rooms
         Up to 110 individual classes
         $349.99 / Year
Pro:
         Unlimited rooms
         Unlimited classes
         $499.99 / Year */
function Subscription() {
    const subscriptions = useSelector(selectAllSubscription);
    const dispatch = useDispatch();
    const { isAdmin, subscription } = useSelector(state => state.personality.user)
    const { loading, error } = useSelector(state => state.status)

    useEffect(() => {
        dispatch({ type: 'SUBSCRIPTIONS_GET_ALL' });
        return () => {
            dispatch(errorEdit(null));
            dispatch(loadingEdit(false))
        }
    }, [])

    let count = 1;
    const addSubscription = () => {
        if (!isAdmin) return
        dispatch(modalEdit('Subscriptions'))
    }
    //console.log( )
    return (

        <>

            {!loading ? (
                <>
                    {error ? (<div className={styles.centerWrapper}>
                        <div className="text-danger">{error}</div>
                    </div>) : (<>  <div className="mainHeader">
                        <span>Subscriptions</span>
                        {isAdmin ? (<Button onClick={addSubscription} className="addResourceButton text-decoration-none p-0 ms-3"
                            size="md"
                            variant="link">Add new +
                        </Button>) : null}

                    </div>
                        <div className={styles.subscriptions}>
                            <div className={styles.overlay}>

                                <Container className="py-0" fluid>
                                    <Row className="py-0">
                                        {subscription?.length > 0 ? (<>
                                            <OneSubscription data={{ subscriptionName: '', studiosNumber: subscription[0].studiosNumber, stickersNumber: subscription[0].stickersNumber, price: '', payment: subscription[0].validUntil, color: 'orange' }} />
                                        </>) : (<>
                                            {subscriptions.map((item, i) => {
                                                const payment = subscription?.id === item.id ? subscription.validUntil : false
                                                let color = 'Orange';
                                                if (count === 2) color = 'Green';
                                                if (count === 3) color = 'Magenta';
                                                count === 3 ? count = 1 : count++
                                                return (<Col className="px-0 " sm={12} lg={4} key={item.id} >
                                                    <div className={styles.subscription}>
                                                        <OneSubscription data={{ ...item, color, payment }} />
                                                    </div>
                                                </Col>)
                                            })}
                                        </>)}

                                    </Row>
                                </Container>
                            </div>
                        </div></>)}

                </>
            ) : (
                <div className={styles.centerWrapper}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

        </>
    )
}


export default Subscription