import React from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { modalEdit } from '../../redux/slices/modal.slice';
import { removeChosenLevel } from '../../redux/slices/levels.slice';
import { removeChosenSticker } from '../../redux/slices/stickers.slice';
import { removeChosenStyle } from '../../redux/slices/styles.slice';
import { removeChosenTeacher } from '../../redux/slices/teachers.slice';
import { removeChosenBoard } from '../../redux/slices/boards.slice';
import { removeChosenStudio } from '../../redux/slices/studio.slice';
import { removeChosenSubscription } from '../../redux/slices/subscriptions.slice';
import { removeChosenEvent } from '../../redux/slices/events.slice';
const Close = () => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(removeChosenLevel())
        dispatch(removeChosenSticker())
        dispatch(removeChosenStyle())
        dispatch(removeChosenTeacher())
        dispatch(removeChosenStudio());
        dispatch(removeChosenSubscription());
        dispatch(removeChosenEvent())
        dispatch(modalEdit(false));
    }

    return (
        <div className={styles.close} onClick={onClose}>
            <div></div>
            <div ></div>
        </div>)
}


function Modal({ entity, children, title }) {
    const { chosenLevel } = useSelector(state => state.levels);
    const { chosenStyle } = useSelector(state => state.styles);
    const { chosenTeacher } = useSelector(state => state.teachers);
    const { chosenSticker } = useSelector(state => state.stickers);
    const { chosenStudio } = useSelector(state => state.studio);
    const { chosenSubscription } = useSelector(state => state.subscriptions);

    const nameTitle = (chosenLevel || chosenStyle || chosenTeacher || chosenSticker || chosenSubscription || chosenStudio) ? `Edit the ${entity}` : `Add a new ${entity}`

    return (
        <div className={styles.overlay}>
            <div className={styles.window}>
                <Close />
                {entity ? <h3 className={styles.title} >
                    {!title ? nameTitle : null}
                </h3> : null}
                {title ? <div className={styles.title}>
                    {title}
                </div> : null}
                {children}
            </div>
        </div>
    );
}

export default Modal;

