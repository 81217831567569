import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import './index.scss';
import UserInfoTab from "../../components/UserInfoTab";
import RadioButton from "../../components/RadioButton";
import ScheduleTable from "../../components/ScheduleTableTab";
import Spinner from 'react-bootstrap/Spinner';
import { tabSwitcher } from "../../constants/variables";
import { useDispatch, useSelector } from "react-redux";
import { history } from '../../App';
import stylesScss from './index.module.scss';
import { BsArrowLeft } from 'react-icons/bs';
import { selectAll as selectAllTeachers } from '../../redux/slices/teachers.slice';
import { selectAll as selectAllLevels } from '../../redux/slices/levels.slice';
import { selectAll as selectAllStyles } from '../../redux/slices/styles.slice';
import { addChosenBoard } from '../../redux/slices/boards.slice.js'
import { Button } from "react-bootstrap";
import { modalEdit } from "../../redux/slices/modal.slice";

const INFO_TAB = "Info";

function UserOverview({ isDefaultScreen, changeIsDefaultScreen }) {
    const params = useParams();
    const { isAdmin, id, studioName } = useSelector(state => state.personality.user)
    const path = history.location.pathname.slice(1);
    const teacher = useSelector(selectAllTeachers);
    const levels = useSelector(selectAllLevels);
    const styles = useSelector(selectAllStyles);
    const { loadingOneUser } = useSelector(state => state.users);
    const { loadingAllOptions, errorAllOptions } = useSelector(state => state.status);
    const [board, setBoard] = useState({});
    const [currentTab, setTab] = useState(path === 'profile' ? tabSwitcher[0].label : tabSwitcher[1].label);
    const { chosenUser } = useSelector(state => state.users);
    const dispatch = useDispatch();

    useEffect(() => {
      setTab(path === 'profile' ? tabSwitcher[0].label : tabSwitcher[1].label);
    }, [path])

    useEffect(() => {
      dispatch({ type: "OPTIONS_GET_ALL", payload: { userId: isAdmin ? params.id : id } })
      dispatch({ type: "BOARDS_GET_ALL", query: { userId: isAdmin ? params.id : id } })
      if (isAdmin && !chosenUser) {
        dispatch({ type: "GET_ONE_USER", payload: { userId: params.id || localStorage.getItem('userId') } });
      }
      return () => {
        changeIsDefaultScreen(true)
      }
    }, []);

    const data = { Teachers: teacher, Levels: levels, Styles: styles };
    const userStudioName = studioName ? `[${studioName}]` : '';
    const userHeaderName = path === 'profile' ? 'Profile' : `${userStudioName} My Schedule`
    const userHeaderAdmin = params.id ? `${chosenUser?.name || 'User - #' + params.id}` : "My Schedule";

    const onBordAdd = () => {
      dispatch(modalEdit('BoardsAdd'));
    }

    const onBordEdit = useCallback(() => {
      if (!board) return;

      if (board.boardName) {
          dispatch(addChosenBoard(board));
          dispatch(modalEdit('BoardsEdit'));
      }
    }, [board, dispatch]);
    
    return (<>
      {!loadingAllOptions && !loadingOneUser ? (<>
        {isDefaultScreen ? (<div className="mainHeader">
          <div className={stylesScss.header}>
            <div className={stylesScss.leftPart}>
              {isAdmin ? <BsArrowLeft onClick={() => history.push('/users')} style={{ cursor: 'pointer' }} /> : null}
              <span>{isAdmin ? userHeaderAdmin : userHeaderName}</span>
              {currentTab === tabSwitcher[1].label && 
                (   
                  <>
                    <Button onClick={onBordEdit} className="addResourceButton text-decoration-none p-0 ms-3"
                      size="md"
                      variant="link">Edit
                    </Button>
                    <Button onClick={onBordAdd} className="addResourceButton text-decoration-none p-0 ms-3"
                      size="md"
                      variant="link">Add new +
                    </Button>
                  </>
                )}
            </div>
          </div>
        </div>) : (null)}
          <div className="mainContainer">
            <div className={stylesScss.buttons}>
              <div>
                {isAdmin ? (<div className="tabsContainer">
                  {tabSwitcher.map((item, idx) => (
                    <RadioButton key={`tabSwitcher-${idx}`} onChangeHandle={setTab} value={item.value}
                      uniqId={`tabSwitcher-${idx}`} activeValue={currentTab} size={"md"}>
                      {item.label}
                    </RadioButton>
                  ))}
                </div>) : null}
                {!isDefaultScreen && (currentTab === tabSwitcher[1].label) ? (<Button onClick={onBordAdd} className="addResourceButton text-decoration-none p-0 ms-3"
                  size="md"
                  variant="link">Add new +
                </Button>) : null}
              </div>
              <button className={stylesScss.editButton} onClick={() => changeIsDefaultScreen((isOpenMenu) => !isOpenMenu)}>
                {isDefaultScreen ? 'Full screen ' : 'Default screen'}
              </button>
            </div>
          {currentTab === INFO_TAB ? (
            <UserInfoTab data={data} />
          ) : (
            <ScheduleTable board={board} setBoard={setBoard}/>
          )}
        </div>
      </>) : (
        <div className={stylesScss.spinnerWrapper}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {errorAllOptions ? (<div className={stylesScss.spinnerWrapper}>
        <div className="text-danger">{errorAllOptions}</div>
      </div>) : null}
    </>
  );
}

export default UserOverview;
