import { RequestService } from "./RequestService";

export class UsersService extends RequestService {

    constructor() {
        super('users');
    }

    getAll = (queryParams) => this.request('get', `search-users-admin`, '', '', {
        page: 1,
        size: 25,
        sortField: 'registrated',
        sortDirection: 'DESC',
        filterValue: '',
        ...queryParams,
    });

    getOne = (queryParams) => this.request('get', `get-user`, '', '', queryParams);
    getUserByToken = () => this.request('get', 'get-user-by-token');
    changeProfileData = (id, body) => this.request('put', `profile`, id, body);
    deletePaidSubscription = (body, queryParams) => this.request('delete', `delete-user-subscription`, '', body, queryParams);
    updatePaidSubscription = (body) => this.request('patch', `edit-user-subscription`, '', body);
    createPaidSubscription = (body) => this.request('post', `create-user-subscription`, '', body);

}



