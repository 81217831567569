import { fork, all, takeLeading, put, call } from "redux-saga/effects";
import authSaga from "./auth.saga";
import { getAll as getAllTeachers } from "./teachers.saga";
import { getAll as getAllStyles } from "./styles.saga";
import { getAll as getAllLevels } from "./levels.saga";
import {
  loadingAllOptionsEdit,
  errorAllOptionsEdit,
} from "../slices/status.slice";
import watchTeachersSaga from "./teachers.saga";
import watchSubscriptionsSaga from "./subscriptions.saga";
import watchStylesSaga from "./styles.saga";
import watchStickersSaga from "./stickers.saga";
import watchLevelsSaga from "./levels.saga";
import watchEventsSaga from "./events.saga";
import watchUsersSaga from "./users.saga";
import watchBoardsSaga from "./boards.saga";
import watchRoomsSaga from "./studio.saga";

export function* handlOptions(action) {
  yield put(loadingAllOptionsEdit(true));
  try {
    yield all([
      call(getAllTeachers, action),
      call(getAllStyles, action),
      call(getAllLevels, action),
    ]);
  } catch (error) {
    if (error.response) {
      yield put(errorAllOptionsEdit(error.response.data.message));
    } else {
      yield put(
        errorAllOptionsEdit("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(loadingAllOptionsEdit(false));
  }
}

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(watchTeachersSaga),
    fork(watchSubscriptionsSaga),
    fork(watchStylesSaga),
    fork(watchStickersSaga),
    fork(watchLevelsSaga),
    fork(watchEventsSaga),
    fork(watchUsersSaga),
    fork(watchBoardsSaga),
    fork(watchRoomsSaga),
    takeLeading("OPTIONS_GET_ALL", handlOptions),
  ]);
}
