import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const subscriptionsAdapter = createEntityAdapter();

export const initialState = subscriptionsAdapter.getInitialState({
    chosenSubscription: null,
    loading: false,
    error: false,
    success: null
})

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        subscriptionsSetAll: (state, action) => {
            subscriptionsAdapter.setAll(state, action.payload);
        },
        subscriptionAddOne: (state, action) => {
            subscriptionsAdapter.addOne(state, action.payload);
        },
        subscriptionUpdateOne: (state, action) => {
            subscriptionsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload },);
        },
        subscriptionRemoveOne: (state, action) => {
            subscriptionsAdapter.removeOne(state, action.payload);
        },
        removeChosenSubscription: (state, action) => {
            state.chosenSubscription = null
        },
        addChosenSubscription: (state, action) => {
            state.chosenSubscription = action.payload
        },
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        successEdit: (state, action) => {
            state.success = action.payload
        },

    },
})

const { actions, reducer } = subscriptionsSlice;
export const { subscriptionsSetAll, subscriptionAddOne, subscriptionUpdateOne, subscriptionRemoveOne, addChosenSubscription, removeChosenSubscription, loadingEdit, 
    errorEdit, successEdit  } = actions;

export default reducer;
export const { selectAll } = subscriptionsAdapter.getSelectors(state => state.subscriptions);
