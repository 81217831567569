import { classDuration, timeSlotInterval } from "constants/variables";
import { getHours, getMinutes } from "utilities";

const getOptions = (data, entity) => {
  return data.map((item) => ({ value: item.color || item[`${entity}Name`], label: item[`${entity}Name`], entity: entity, id: item.id }))
}
  
const getLengthOptions = () => {
  const lengthOptions = [];
  for (let i = classDuration.min.minutesInterval_5; i < classDuration.max; i += timeSlotInterval.minutes_5) {
    const label = getTime(i);
    lengthOptions.push({ value: getHours(i), label, entity: 'length' });
  }

  return lengthOptions;
}

const getTime = (value) => {
  const hour = Math.floor(getHours(value));
  const minutes = Math.round(value % 60);
  const minutesText = minutes > 0 ? minutes === 5 ? `0${minutes} m` : `${minutes} m` : '';
  return (hour > 0 ? `${hour} h ` : '') + minutesText;
}
  
const getDuration = (value) => {
  const hour = Math.floor(value);
  const minutes = getMinutes(value - hour);
  return `0${hour > 0 ? `${hour}` : '0'}:${minutes > 0 ? `${minutes}` : '00'}`
}

export {
  getOptions,
  getLengthOptions,
  getTime,
  getDuration
}