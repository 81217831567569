import { classDuration, timeSlotInterval } from "constants/variables";

export const getLengthOptions = (chosenSticker, length = timeSlotInterval.minutes_15) => {
    let defaultLength = '';
    const lengthOptions = [];
    const increment =
    length === timeSlotInterval.minutes_15
      ? classDuration.min.minutesInterval_15
      : classDuration.min.minutesInterval_5;
        
    for (let i = increment; i < classDuration.max; i += length) {
        const hour = Math.floor(getHours(i));
        const minutes = Math.round(i % 60);
        const minutesText = minutes > 0 ? minutes === timeSlotInterval.minutes_5 ? `0${minutes} m` : `${minutes} m` : '';
        const label = (hour > 0 ? `${hour} h ` : '') + minutesText;

        lengthOptions.push({ value: getHours(i), label, entity: "length" });
        
        if (Math.round(getMinutes(chosenSticker?.length)) === i) {
            defaultLength = { value: getHours(i), label, entity: "length" }
        }
    }
    return [lengthOptions, defaultLength];
}
export const getBackgroundColor = (event) => {

    let background = event.levels.length < 2 ? event.levels[0] : `linear-gradient(${180}deg, `;
    if (event.levels.length > 1) {
        event.levels.forEach((color, i) => {
            i !== event.levels.length - 1 ? background += `${color}, ` : background += `${color})`
        })
    }
    return background
}

export const getTimeSlotInterval = (length) => {
    const minutes = Math.round(getMinutes(length));

    if (minutes % timeSlotInterval.minutes_5 === 0 && minutes % timeSlotInterval.minutes_15 !== 0) {
        return timeSlotInterval.minutes_5
    }
        
    return timeSlotInterval.minutes_15
}

export const getMinutes = (value) => value * 60;
export const getHours = (value) => value / 60;
export const getHourLengthFromMilliseconds = (value) => value / 60000 / 60;