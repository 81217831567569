import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { HslaStringColorPicker   } from "react-colorful";
import styles from "./index.module.scss";
import { errorEdit, successEdit, loadingEdit } from '../../../redux/slices/status.slice';

const DEFAULT_COLOR = 'hsl(175, 90%, 75%)'

function LevelForm() {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(state => state.status);
  const [action, setAction] = useState('LEVEL_ADD_ONE');
  const { chosenLevel } = useSelector(state => state.levels);
  const { isAdmin, id } = useSelector(state => state.personality.user);
  const { chosenUser } = useSelector(state => state.users);
  const [clickDeleteItem, setClickDeleteItem] = useState(false);
  const [colorHex, changeColorHook] = useState(chosenLevel?.color || DEFAULT_COLOR);
  const [active, setActive] = useState(chosenLevel ? true : false);
  const [errorColor, setErrorColor] = useState(null)

  const objectYup = {
    levelName: yup.string()
      .required("This field is required")
      .min(1, 'Name is too short - should be 1 chars minimum.'),
  };

  const schema = yup.object(objectYup).required();
  const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        levelName: chosenLevel?.levelName || '',
    }
  });


  const onSubmit = (value) => {
    if (!active) {
      setErrorColor('This field is required');
      return
    }

    setErrorColor(false)
    dispatch(successEdit(null));
    dispatch(errorEdit(null));
    dispatch(loadingEdit(false));
    if (action === 'LEVEL_REMOVE_ONE' && !clickDeleteItem) {
      setClickDeleteItem(true);
      return
    }

    const payload = {
      levelName: value.levelName,
      color: colorHex,
      levelId: chosenLevel?.id || '',
      userId: isAdmin ? chosenUser.id : id,
    }

    dispatch({ type: action, payload })
  };

  useEffect(() => {
    if (success) {
      reset();
      changeColorHook(chosenLevel?.color || DEFAULT_COLOR)
    }
  }, [chosenLevel?.color, reset, success]);

  
  useEffect(() => {
    return () => {
      clearErrors();
      reset();
      dispatch(successEdit(null));
      dispatch(errorEdit(null));
      dispatch(loadingEdit(false));
      setErrorColor(null)
    }
  }, [clearErrors, dispatch, reset])

  return (<>
    {loading ? (
      <div className={styles.spinnerWrapper}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>) : (
      <>
        {clickDeleteItem ? (<div className={styles.confirm}>Are you sure you want to delete this Level? </div>) : null}
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {clickDeleteItem ? null : (
            <>
              <div className={styles.formAnswer}>
                {error ? <div className="text-danger">{error}</div> : null}
                {success ? <div className="text-success">{success}</div> : null}
              </div>
              <label >Level</label>  <input  {...register("levelName")} />
              {errors.levelName ? (
                <div className={styles.formAnswer}>
                    <div className="text-danger">{errors.levelName?.message}</div>
                </div>) : null}
                <label>Color</label>
                <div className={styles.colorPicker}>
                  <HslaStringColorPicker  
                    onClick={() => setActive(true)}
                    color={colorHex}
                    onChange={color => {
                        changeColorHook(color);
                    }}
                  />
                  <div style={{ background: colorHex}} className={styles.color}></div>
                </div>
                {errorColor ? (
                  <div className={styles.formAnswer}>
                      <div className="text-danger">{errorColor}</div>
                </div>) : null}
            </>)}

            {!chosenLevel ? <input type="submit" className={styles.submit} value="Save" onClick={() => setAction('LEVEL_ADD_ONE')} /> : (
              <div className={styles.buttons}>
                {clickDeleteItem ? (<>
                  <button className={styles.submitDelete} onClick={() => setClickDeleteItem(false)}> No</button>
                  <input type="submit" className={styles.submitUpdate} value="Yes" />
                </>) : (<>
                  <input type="submit" className={styles.submitDelete} value="Delete" onClick={() => setAction('LEVEL_REMOVE_ONE')} />
                  <input type="submit" className={styles.submitUpdate} value="Save" onClick={() => setAction('LEVEL_UPDATE_ONE')} />
                </>)}
              </div>
            )}
        </form>
      </>)
    }
  </>)
}

export default LevelForm;





