import React, { useState } from "react";
import { RxEyeClosed, RxEyeOpen } from 'react-icons/rx';
import styles from "../../FormWrapper/index.module.scss";

const PasswordField = ({registerProps, error, label}) => {
	const [isOpenPassword, setIsOpenPassword] = useState(false)

	return (
		<div className={styles.oneInput}>
			<label >{label}</label>
			<div className={styles.password}>
				<input  {...registerProps} type={isOpenPassword ? 'text' : 'password'} data-testid={registerProps.name}/>
				<div className={styles.iconEye}>
					{isOpenPassword ? <RxEyeOpen size={24} color='#979AA8' onClick={() => setIsOpenPassword(false)} /> :
					<RxEyeClosed size={24} color='#979AA8' onClick={() => setIsOpenPassword(true)} />}
				</div>
				{error && (
				<div className={styles[`auth-form__answer`]}>
						<div className="text-danger">{error?.message}</div>
				</div>)}
			</div>
		</div>
	)
};

export default PasswordField;