import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const stylesAdapter = createEntityAdapter();

export const initialState = stylesAdapter.getInitialState({
    chosenStyle: null,
})

const stylesSlice = createSlice({
    name: 'styles',
    initialState,
    reducers: {
        stylesSetAll: (state, action) => {
            stylesAdapter.setAll(state, action.payload);
        },
        styleAddOne: (state, action) => {
            stylesAdapter.addOne(state, action.payload);
        },
        styleUpdateOne: (state, action) => {
            stylesAdapter.updateOne(state, { id: action.payload.styleId, changes: { styleName: action.payload.styleName } },);
        },
        styleRemoveOne: (state, action) => {
            stylesAdapter.removeOne(state, action.payload);
        },
        addChosenStyle: (state, action) => {
            state.chosenStyle = action.payload
        },
        removeChosenStyle: (state, action) => {
            state.chosenStyle = null
        },

    },
})

const { actions, reducer } = stylesSlice;
export const { stylesSetAll, styleAddOne, styleUpdateOne, styleRemoveOne, addChosenStyle, removeChosenStyle } = actions;

export default reducer;
export const { selectAll } = stylesAdapter.getSelectors(state => state.styles);
