import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { ReactComponent as PDFIcon } from "../../assets/img/icons/pdf.svg";
import { modalEdit } from '../../redux/slices/modal.slice';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { selectAll as selectAllStickers } from '../../redux/slices/stickers.slice'
import { selectAll as selectAllStyles } from '../../redux/slices/styles.slice';
import { selectAll as selectAllTeachers } from '../../redux/slices/teachers.slice';
import { selectAll as selectAllLevels } from '../../redux/slices/levels.slice';
import { useReactToPrint } from "react-to-print";
import EventsPull from "./EventsPull";
import { getOptions, getLengthOptions } from "./helpers";
import { TIME_SLOTS } from "constants/variables";

export const defaultFilters = {
  length: [],
  levels: [],
  styles: [],
  teachers: []
};

const DEFAULT_CARD_HEIGHT = 26;
const STICKER_STYLE_TOP_RATIO = 6.45;

const CardsPull = (props) => {
  const allStyles = useSelector(selectAllStyles);
  const allTeachers = useSelector(selectAllTeachers);
  const allLevels = useSelector(selectAllLevels);
  const allStickers = useSelector(selectAllStickers);
  const [filters, setFilters] = useState(defaultFilters);
  const [eventsPull, setEventsPull] = useState(allStickers);
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    documentTitle: `${props.board?.label || "1"}-${props.weekday}.pdf`.replace(" ", "_"),
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        props.setSlotTime(TIME_SLOTS.MINUTES_15);
        resolve();
      });
    },
    content: () => {
      const nodeClone = props.refProps.current.cloneNode(true)
      nodeClone.getElementsByClassName("fc-scroller-harness fc-scroller-harness-liquid")[0].style.overflow = "visible";
      nodeClone.getElementsByClassName("fc-scroller-harness fc-scroller-harness-liquid")[0].style.borderTop = "0.5mm solid grey";
      nodeClone.getElementsByClassName("fc-scroller fc-scroller-liquid-absolute")[0].style.overflow = "visible";
      const stickers = nodeClone.getElementsByClassName("fc-timegrid-event-harness fc-timegrid-event-harness-inset");
      const tableTimeRows = nodeClone.querySelectorAll('.fc-timegrid-slots table tbody tr');
      let startTimeDay = tableTimeRows[0].querySelector('td');
      startTimeDay = parseInt(startTimeDay.getAttribute('data-time'));

      for (let i = 0; i < tableTimeRows.length; i++) {
        tableTimeRows[i].style.height = '5mm';
      }

      for (let i = 0; i < tableTimeRows.length; i+=2) {
        tableTimeRows[i].querySelectorAll('td').forEach((item) => {
          item.style.border = 'none';
          item.style.borderTop = '0.5mm solid grey';
        })
      }

      for (let i = 0; i < stickers.length; i++) {
          const sticker = stickers[i].getElementsByClassName("test")[0];
          sticker.querySelector('div').style.overflow = 'hidden';
          const length = sticker.getAttribute('data-length');

          if (length <= 0.5) {
            sticker.querySelectorAll('[data-id="card-item"]').forEach((item) => {
              item.style.fontSize = '9.5px';
            });
          }

          if (length >= 1.5) {
            sticker.querySelectorAll('[data-id="card-item"]').forEach((item) => {
              item.style.overflow = 'auto';
              item.style.display = 'block';
              item.style.webkitLineClamp = 'initial';
              item.style.webkitBoxOrient = 'horizontal';
              item.style.wordBreak = 'normal';
            });
          }
          
          const timeStartSticker = sticker.getAttribute('data-time');
          stickers[i].style.height = `${length * DEFAULT_CARD_HEIGHT}mm`;
          const countPoint = (timeStartSticker > startTimeDay ? timeStartSticker - startTimeDay : startTimeDay - timeStartSticker) / 0.249;
          stickers[i].style.top = `${countPoint !== 0 ? (STICKER_STYLE_TOP_RATIO + ((countPoint - 1) * STICKER_STYLE_TOP_RATIO)) : 0}mm`;
          stickers[i].style.bottom = '0mm';
      }

      const header = nodeClone.querySelector(".fc-col-header");
      header.style.height = '5mm';

      const container = document.createElement('div');
      const dayOfWeekTitle = document.createElement('h2');
      dayOfWeekTitle.textContent = props.weekday;
      dayOfWeekTitle.style.textAlign = 'center';
      dayOfWeekTitle.style.textTransform = 'capitalize';
      container.append(dayOfWeekTitle, nodeClone);

      return container;
    },
    copyStyles: true,
  });

  const onSelectChange = (field) => (values) => {
    setFilters((prevState) => (
      (
        {
          ...prevState,
          [field]: values
        }
      )
    ))
  }

  const onRemoveHandle = (field) => (event) => {
    const name = event.target.getAttribute("name");
    const newValues = filters[field].filter((val) => String(val.value) !== name)
    const removedValue = filters[field].find((val) => String(val.value) === name);
    if (!removedValue) return;

    onSelectChange(field)(
      newValues,
      { name, action: "remove-value", removedValue }
    );
  }

  useEffect(() => {
    setEventsPull(allStickers)
  }, [allStickers])

  useEffect(() => {
    const activeFilters = Object.values(filters).filter(item => item.length > 0);

    if (activeFilters.length > 0) {
      const newEvents = allStickers.filter((item) => (
        Object.keys(filters).some((key) => {
          if (key === 'length') {
            return filters[key].some(el => el.value === item[key]);
          }
          
          if (item[key].length > 0 && key !== 'length') {
            return item[key].some(element => filters[key].some(el => el.value === element));
          }

          return false;
        })
      ));

      setEventsPull(newEvents)
    } else {
      setEventsPull(allStickers)
    }
  }, [allStickers, filters])
  
  return (
    <div className='app-sidebar'>
      <div className="d-flex my-3 justify-content-between">
        <Button onClick={handlePrint} className="greyButton" variant="outline-primary"><PDFIcon /></Button>
        <Button className="primaryButton" variant="primary" onClick={() => {
            dispatch(modalEdit("Stickers"))
        }}>
            Add class
        </Button>
      </div>
      <Accordion className="accordionItem" flush>
        <Accordion.Item eventKey="0">
            <Accordion.Header><h5 className="m-0">Class Filter</h5></Accordion.Header>
            <Accordion.Body>
              <div className="d-flex flex-column mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Level</p>
                  <Select
                    name="levels"
                    controlShouldRenderValue={false}
                    onChange={onSelectChange("levels")}
                    isClearable={false}
                    isMulti
                    value={filters.levels}
                    options={getOptions(allLevels, 'level')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Style</p>
                  <Select
                    onChange={onSelectChange("styles")}
                    isClearable={false}
                    controlShouldRenderValue={false}
                    isMulti
                    value={filters.styles}
                    name="style"
                    options={getOptions(allStyles, 'style')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Teacher</p>
                  <Select
                    onChange={onSelectChange("teachers")}
                    isClearable={false}
                    controlShouldRenderValue={false}
                    isMulti
                    value={filters.teachers}
                    name="teacher"
                    options={getOptions(allTeachers, 'teacher')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <span>Length</span>
                  <Select
                    onChange={onSelectChange("length")}
                    controlShouldRenderValue={false}
                    isClearable={false}
                    isMulti
                    value={filters.length}
                    name="length"
                    options={getLengthOptions()}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <EventsPull onRemoveHandle={onRemoveHandle} eventsPull={eventsPull} filters={filters}/>
      </Accordion>
    </div>
  )
}

export const CardsPullMemo = React.memo(CardsPull);
