import { takeLeading, call, put } from "redux-saga/effects";
import {
  teachersSetAll,
  teacherAddOne,
  teacherUpdateOne,
  teacherRemoveOne,
  removeChosenTeacher,
} from "../slices/teachers.slice.js";

import { loadingEdit, errorEdit, successEdit } from "../slices/status.slice";
import { teachersService, stylesService } from "../../services";
import { stylesSetAll } from "../slices/styles.slice.js";
import { modalEdit, contentEdit } from "../slices/modal.slice.js";

export function* getAll(action) {
  const response = yield call(teachersService.getAll, {
    userId: action.payload.userId,
  });
  yield put(teachersSetAll(response.data));
}

function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(teachersService.create, action.payload);
    const responseStyles = yield call(stylesService.getAll, {
      userId: action.payload.userId,
    });
    yield put(stylesSetAll(responseStyles.data));
    yield put(
      teacherAddOne({
        id: response.data.teacher.id,
        ...action.payload,
      })
    );
    yield put(successEdit("New teacher has created"));
  } catch (error) {
    console.log(error);
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(teachersService.update, action.payload);
    const responseStyles = yield call(stylesService.getAll, {
      userId: action.payload.userId,
    });
    yield put(stylesSetAll(responseStyles.data));

    yield put(teacherUpdateOne(action.payload));
    yield put(removeChosenTeacher());
    yield put(contentEdit("The teacher has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(teachersService.delete, {
      teacherId: action.payload.teacherId,
      userId: action.payload.userId,
    });
    yield put(teacherRemoveOne(action.payload.teacherId));
    yield put(removeChosenTeacher());
    yield put(contentEdit("The teacher has been deleted"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchTeachersSaga() {
  yield takeLeading("TEACHERS_GET_ALL", getAll);
  yield takeLeading("TEACHER_ADD_ONE", addOne);
  yield takeLeading("TEACHER_UPDATE_ONE", updateOne);
  yield takeLeading("TEACHER_REMOVE_ONE", removeOne);
}
