import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../FormWrapper/index.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorEdit, successEdit } from "../../../redux/slices/auth.slice";
import PasswordField from "../Field/PasswordField";
import FormWrapper from "../FormWrapper";

const ChangePasswordForm = () => {
  const { error, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.personality.user);

  const changePasswordFormYup = {
    oldPassword: yup
      .string()
      .required("No password provided.")
      .max(255, "Password is too long - should be 255 chars maximum"),
    newPassword: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(255, "Password is too long - should be 255 chars maximum")
      .matches(
        /[a-zA-Z]+/,
        "Password should contain at least one latin letter"
      ),
    confirmPassword: yup
      .string()
      .required("No password provided.")
      .test("optionality", "Passwords must match", function (value) {
        const { confirmPassword, newPassword } = getValues();
        return confirmPassword.trim() === newPassword.trim();
      }),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(yup.object(changePasswordFormYup).required()),
  });

  const onSubmit = (value) => {
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: {
        email: email,
        password: value.oldPassword,
        newPassword: value.newPassword,
      },
    });
  };

  return (
    <FormWrapper clearErrors={clearErrors} reset={reset}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles["auth-form"]}>
        <div className={styles[`auth-form__answer`]}>
          {error && <div className="text-danger">{error}</div>}
          {success && <div className="text-success">{success}</div>}
        </div>
        <div className={styles.inputsWrapper}>
          <PasswordField
            registerProps={register("oldPassword")}
            error={errors.oldPassword}
            label={"Old password"}
          />
          <PasswordField
            registerProps={register("newPassword")}
            error={errors.newPassword}
            label={"New password"}
          />
          <PasswordField
            registerProps={register("confirmPassword")}
            error={errors.confirmPassword}
            label={"Confirm password"}
          />
        </div>
        <input
          type="submit"
          className={styles["submit"]}
          value={"Change Password"}
        />
      </form>
    </FormWrapper>
  );
};

export default ChangePasswordForm;
