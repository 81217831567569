import React from "react";
import styles from "../FormWrapper/index.module.scss";

const Field = ({registerProps, label, type = 'text', error, disabled = false, fieldClassName = true}) => (
	<div className={fieldClassName ? styles.oneInput : undefined}>
		<label >{label}</label>
		<input  {...registerProps} type={type} disabled={disabled} data-testid={registerProps.name}/>
		{error && (
			<div className={styles[`auth-form__answer`]}>
				<div className="text-danger">{error?.message}</div>
			</div>
		)}
	</div>
)

export default Field;