import styles from "./index.module.scss";
import { Button, Container, ToggleButton, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import RadioButton from "../RadioButton";
import { radiosTabs } from "../../constants/variables";
import { modalEdit } from '../../redux/slices/modal.slice';
import { useSelector, useDispatch } from 'react-redux';
import { errorEdit, successEdit, loadingEdit } from '../../redux/slices/status.slice';
import { selectAll as selectAllStyles, addChosenStyle } from '../../redux/slices/styles.slice';
import { selectAll as selectAllTeachers, addChosenTeacher } from '../../redux/slices/teachers.slice';
import { selectAll as selectAllLevels, addChosenLevel } from '../../redux/slices/levels.slice';
import Spinner from 'react-bootstrap/Spinner';
import ProfileInformation from "../ProfileInformation";
import { useEffect, useLayoutEffect } from "react";

import { history } from "../../App";
const tabs = {
    Teachers: ["Name", "Style",],
    Levels: ["Level", "Color"],
    Styles: [],
}
const TeacherRow = ({ item }) => {
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(addChosenTeacher(item));
        dispatch(modalEdit('Teachers'));
    }



    const getStyles = () => {
        let styles = '';
        for (let i = 0; i < item.teacherAndstyles?.length; i++) {
            if ((i + 1) === item.teacherAndstyles.length) {
                styles += `${item.teacherAndstyles[i].style.styleName}`;
                continue;
            };
            styles += `${item.teacherAndstyles[i].style.styleName}, `;
        }

        return styles;
    }


    return (
        <tr onClick={openModal}>
            <td>{item.teacherName}</td>
            <td>{item.teacherAndstyles.length > 0 ? getStyles() : 'None'}</td>
        </tr>
    )
}
const LevelsRow = ({ item }) => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(addChosenLevel(item));
        dispatch(modalEdit('Levels'));
    }
    return (
        <tr onClick={openModal}>
            <td>{item.levelName}</td>
            <td>
                <div className={styles.color}>
                    <div style={{ background: item.color }} />
                </div>
            </td>
        </tr>
    )
}
const selectedRow = {
    Teachers: (item, index) => <TeacherRow item={item} key={`teachersRow-${index}`} />,
    Levels: (item, index) => <LevelsRow item={item} key={`levelsRow-${index}`} />
}

function UserInfoTab({ data }) {
    const [radioValue, setRadioValue] = useState(radiosTabs[0].name);
    const dispatch = useDispatch();
    /*     const teacher = useSelector(selectAllTeachers);
        const levels = useSelector(selectAllLevels);
        const styles = useSelector(selectAllStyles);
        const data = { Teachers: teacher, Levels: levels, Styles: styles } */

    const openModal = (item) => {
        dispatch(addChosenStyle(item));
        dispatch(modalEdit('Styles'));
    }

    const getTeachers = (item) => {
        let teachers = '';
        for (let i = 0; i < item.teacherAndstyles.length; i++) {
            if ((i + 1) === item.teacherAndstyles.length) {
                teachers += `${item.teacherAndstyles[i].teacher.teacherName}`;
                continue;
            };
            teachers += `${item.teacherAndstyles[i].teacher.teacherName}, `;
        }

        return teachers;
    }

    return (
        <Container fluid style={{marginTop: '20px'}}>
            <Row>
                <Col className="ps-0" sm={12} lg={9}>
                    <div className={styles.tableContainer}>
                        <div className={styles.additionalHead}>
                            {radiosTabs.map((radio, idx) => (
                                <RadioButton uniqId={`userInfoTab-${idx}`} key={`radio-${idx}`} onChangeHandle={setRadioValue} size={"md"}
                                    activeValue={radioValue} value={radio.value}>
                                    {radio.name}
                                </RadioButton>
                            ))}
                            <Button className={styles.addBtn} size="md" variant="link" onClick={() => { dispatch(modalEdit(radioValue)) }}>Add new {radioValue} +</Button>
                        </div>
                        {radioValue !== "Styles" ? (
                            <Table className={styles.tableWithTabs} hover striped={false}>
                                <thead>
                                    <tr>
                                        {tabs[radioValue].map((item, index) => {
                                            return (
                                                <th key={`theadKey-${index}`}>{item}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[radioValue].map((item, index) => {
                                        return selectedRow[radioValue](item, index)
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <div className={styles.cardsContainer}>
                                {data[radioValue].map((item, index) => {
                                    return (
                                        <div key={`stylesList-${index}`} className={styles.card} onClick={() => openModal(item)} >
                                            <h4>{item.styleName}</h4>
                                            <span>Teachers:</span>
                                            <span>{item.teacherAndstyles.length > 0 ? getTeachers(item) : "None"}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                        }
                    </div>
                </Col>
                <Col sm={12} lg={3}>
                    <ProfileInformation />
                </Col>
            </Row>
        </Container>
    )
}

export default UserInfoTab;
