import React from 'react';
import { Button } from "react-bootstrap";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import WeekDay from './WeekDay';
import { weekDays } from "../../../constants/variables";
import { useDispatch } from "react-redux";
import { addChosenDay, addChosenBoard, addActiveDays, removeChosenDay } from '../../../redux/slices/boards.slice';
import { modalEdit } from "../../../redux/slices/modal.slice";
import styles from '../index.module.scss';

export default function WeekDaysHeader(props) {
  const {board, onUndoHandle, eventsHistory, setWeekday, weekday} = props;
  const days = board?.boardDays || [];
  const activeDays = [];
  const dispatch = useDispatch();

  const addWeekDay = (modal, value) => {
    if (modal === 'add') {
        dispatch(removeChosenDay());
        dispatch(addActiveDays(activeDays));
    } else {
        dispatch(addChosenDay({ ...value, activeDays, boardId: board.id }));
    }

    dispatch(addChosenBoard(board))
    dispatch(modalEdit('Day'));
  }

  return (
    <div className="additionalHead">
        {weekDays.map((day) => {
          const dayData = days[day] || {};

          if (dayData.end || dayData.start) {
            const itemLabel = day.charAt(0).toUpperCase() + day.slice(1);
            activeDays.push(itemLabel);

            return (
              <WeekDay day={day} setWeekday={setWeekday} weekday={weekday} itemLabel={itemLabel} addWeekDay={addWeekDay} dayData={dayData} key={day} />
            )
          }

          return null;
        })}

        <Button className="text-decoration-none p-0 me-2" size="lg" variant="link" onClick={() => addWeekDay('add')}>+</Button>
        <Button disabled={eventsHistory.length === 0} className="ms-auto" size="lg" variant="link" onClick={onUndoHandle} > <div className={styles.goBack} >
            <AiOutlineArrowLeft />
            <span>Step Back</span>
        </div></Button>
    </div>
  )
}