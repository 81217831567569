import Table from 'react-bootstrap/Table';

import styles from './index.module.scss';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { modalEdit } from '../../redux/slices/modal.slice';

function Settings() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { email } = useSelector(state => state.personality.user);


    const logOut = () => {
        dispatch({ type: 'LOG_OUT', payload: { email } })
    }



    return (
        <>
            <div className="mainHeader">
                Settings
            </div>
            <div className={styles.settings}>
                <div className={styles.overlay}>
                    <Table striped={false}>
                        <tbody>
                            <tr >
                                <td valign='middle'>
                                    <div className={styles.description}>
                                        <div>Change password</div>
                                        <div>Press “Change” to reset your password</div>
                                        <button className={styles.button}> Change</button>
                                    </div>
                                </td>
                                <td valign='middle'><button className={styles.button} onClick={() => { dispatch(modalEdit('ChangePassword')) }}> Change</button></td>
                            </tr>
                            <tr >
                                <td valign='middle'>
                                    <div className={styles.description} >
                                        <div>Privacy Policy</div> {/* */}
                                        <a href='/documents/privacyPolicy' >
                                            <button className={styles.button}>Read</button>
                                        </a>
                                    </div>
                                </td>
                                <td valign='middle'>
                                    <a href='/documents/privacyPolicy' >
                                        <button className={styles.button}>Read</button>
                                    </a></td>
                            </tr>
                            <tr >
                                <td valign='middle'>
                                    <div className={styles.description} >
                                        <div>Terms and conditions</div> {/* */}
                                        <a href='/documents/termsAndConditions' >
                                            <button className={styles.button}>Read</button>
                                        </a>
                                    </div>
                                </td>
                                < td valign='middle'> <a href='/documents/termsAndConditions' >
                                    <button className={styles.button}>Read</button>
                                </a></td>
                            </tr>
                            <tr >
                                <td valign='middle'>
                                    <div className={styles.description}>
                                        <div>Log Out</div>
                                        <div>Press “Log Out” button to leave the account </div>
                                        <button className={styles.button}>Log out</button>
                                    </div>
                                </td>
                                <td valign='middle'><button className={styles.button} onClick={logOut}>Log out</button></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}

export default Settings;
