import Table from 'react-bootstrap/Table';
import styles from './index.module.scss';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addChosenUser } from '../../redux/slices/users.slice';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';

function UsersTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, countUsers, error, loading } = useSelector(state => state.users);
  const { isAdmin, id } = useSelector(state => state.personality.user);


  const onRowClick = (item) => {
    dispatch(addChosenUser(item));
    navigate(`${item.id}`)
  }
  const [page, setPage] = useState(1);
  const countPages = Math.ceil(countUsers / 25);


  useEffect(() => {
    if (isAdmin && (users.length === 0)) {
      dispatch({ type: 'USERS_GET_ALL', payload: { userId: id, page: page } })
    }
  }, [])


  const changePage = (numberPage) => {
    let value = numberPage;
    if (numberPage > countPages) value = countPages;
    if (numberPage < 1) value = 1;
    setPage(value);
    dispatch({ type: 'USERS_GET_ALL', payload: { userId: id, page: value } })
  }



  const getPaginationItem = () => {
    let pages = [];
    const length = countPages < 4 ? countPages : 3;
    for (let i = 0; i < length; i++) {
      pages.push(<Pagination.Item active={page === i + 1 ? true : false} onClick={() => changePage(i + 1)}>{i + 1}</Pagination.Item>)
    }
    return pages
  }

  const PaginationItem = getPaginationItem();
  
  return (
    <div>
      {!loading ? (<>
        {error ? (<><div className={styles.centerWrapper}>
          <div className="text-danger">{error}</div>
        </div></>) : (<>
          <>
            <Table hover striped={false}>
              <thead>
                <tr>
                  <th>Studio name</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subscription plan</th>
                  <th>Days in the system</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => {
                  if (item.role === 1) return
                  const date = Math.floor((new Date() - new Date(item.registrated)) / (1000 * 60 * 60 * 24));
                  const subscription = item.subscription?.[0];
                  return (
                    <tr key={`usersTable-${index}`} onClick={() => onRowClick(item)}>
                      <td>{item.studioName}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>

                        {subscription ? (<>
                          <div>
                            {subscription.studiosNumber === null ? <span><b>Unlimited</b></span> : <>Up to <span><b>{subscription.studiosNumber}</b></span> </>} Room{subscription.studiosNumber > 1 || subscription.studiosNumber === null ? 's, ' : ', '}
                            <span>{subscription.stickersNumber === null ? null : <><span><b>{subscription.stickersNumber}</b></span> </>}</span>  Classe{subscription.stickersNumber > 1 || subscription.stickersNumber === null ? 's' : ''}
                          </div>
                          <div>till <b>{subscription.validUntil}</b></div>
                        </>) : <div>-</div>}
                      </td>
                      <td>{date > 0 ? date : 1}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {countPages > 1 ? (
              <Pagination>
                <Pagination.First onClick={() => changePage(1)} />
                <Pagination.Prev onClick={() => changePage(page - 1)} />
                {(page < 3) ? (
                  <>
                    {PaginationItem}
                  </>) : (<>
                    <Pagination.Item active={page === 1 ? true : false} onClick={() => changePage(1)}>1</Pagination.Item>
                    {page > 3 ? <Pagination.Ellipsis /> : null}
                    <Pagination.Item onClick={() => changePage(page - 1)} >{page - 1}</Pagination.Item>
                    {countPages === 3 ? <Pagination.Item active onClick={() => changePage(page)}>{page}</Pagination.Item> : null}
                    {countPages !== page ? <Pagination.Item active onClick={() => changePage(page)}>{page}</Pagination.Item> : null}
                    {((countPages !== page + 1) && countPages !== page) ? <Pagination.Item onClick={() => changePage(page + 1)} >{page + 1}</Pagination.Item> : null}
                  </>)}
                {(countPages === 2 || countPages === 3) ? null : <>
                  {(page === countPages) || (page === countPages - 1) || (page === countPages - 2) ? null : <Pagination.Ellipsis />}

                  <Pagination.Item active={page === countPages ? true : false} onClick={() => changePage(countPages)}>{countPages}</Pagination.Item>
                  <Pagination.Next onClick={() => changePage(page + 1)} />
                  <Pagination.Last onClick={() => changePage(countPages)} />
                </>
                }

              </Pagination>) : null}
          </></>)}
      </>) : (
        <div className={styles.centerWrapper}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

    </div>
  );
}

export default UsersTable;
