import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const studioAdapter = createEntityAdapter();

export const initialState = studioAdapter.getInitialState({
    chosenStudio: null,
    loading: false,
    error: false,
    succes: null
})

const studioSlice = createSlice({
    name: 'studio',
    initialState,
    reducers: {
        studioSetAll: (state, action) => {
            studioAdapter.setAll(state, action.payload);
        },
        studioAddOne: (state, action) => {
            studioAdapter.addOne(state, { id: action.payload.id, studioName: action.payload.studioName, scheduleSticker: [], title: action.payload.studioName });
        },
        studioUpdateOne: (state, action) => {
            studioAdapter.updateOne(state, {
                id: action.payload.studioId,
                changes: { studioName: action.payload.studioName }
            },);
        },
        studioRemoveOne: (state, action) => {
            studioAdapter.removeOne(state, action.payload);
        },
        addChosenStudio: (state, action) => {
            state.chosenStudio = action.payload
        },
        removeChosenStudio: (state, action) => {
            state.chosenStudio = null
        },
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        successEdit: (state, action) => {
            state.success = action.payload
        },

    },
})

const { actions, reducer } = studioSlice;
export const { studioSetAll, studioAddOne, studioUpdateOne, studioRemoveOne, addChosenStudio, removeChosenStudio, loadingEdit, errorEdit, successEdit } = actions;

export default reducer;
export const { selectAll } = studioAdapter.getSelectors(state => state.studio);
