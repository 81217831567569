import React from 'react';
import { BgLines } from '../../assets/img';
import { modalEdit } from '../../redux/slices/modal.slice';
import { addChosenSubscription } from '../../redux/slices/subscriptions.slice';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';

function OneSubscription({ data }) {
    const { subscriptionName, studiosNumber, stickersNumber, price, payment, color, info } = data;
    const classNameWrapper = [
        styles.wrapperSubscription,
        styles[`wrapperSubscription${color}`],
        styles[`wrapperSubscription${info ? '__info' : ''}`]

    ].join(' ');
    const dispatch = useDispatch();
    const { isAdmin, id } = useSelector(state => state.personality.user)

    const openModal = () => {
        if (!isAdmin) return
        dispatch(addChosenSubscription({ ...data, paid: info ? true : false }));
        dispatch(modalEdit('Subscriptions'));
    }
    return (
        <div className={styles.center} onClick={!isAdmin ? null : openModal}>
            <div className={classNameWrapper}>
                <div className={styles.topLine}></div>
                <div className={styles.header} >
                    <div className={styles.name}>{subscriptionName}</div>
                    {price ? <div className={styles.coast}>$ {price}<span> /per year</span></div> : null}

                </div>
                <ul className={info ? styles.infoList : ''}>
                    <li>{studiosNumber === null ? <span>Unlimited</span> : <>Up to <span>{studiosNumber}</span> </>} Room{studiosNumber > 1 || studiosNumber === null ? 's' : ''} Per Schedule </li>
                    <li>{stickersNumber === null ? <span>Unlimited</span> : <>Up to <span>{stickersNumber}</span> </>}  Classe{stickersNumber > 1 || stickersNumber === null ? 's' : ''} Per Schedule </li>
                </ul>
                <div className={styles.payment} style={{ visibility: isAdmin ? 'display' : 'display' }}>
                    {payment ? <div >Payment till {payment}</div> : null}
                    {!payment && !isAdmin ? (<>
                        <button onClick={() => dispatch({
                            type: 'BUY_SUBSCRIPTION', payload: {
                                "subscriptionId": data.id,
                                "userId": id
                            }
                        })}>Purchase</button>
                    </>) : null}

                </div>
            </div>
        </div>
    )
}


export default OneSubscription


{/* <div className={styles.center} onClick={forbidEdit || !isAdmin ? null : openModal}>
<div className={classNameWrapper}>
    <div className={styles.topLine}></div>
    <div className={styles.header} >
        <div className={styles.name}>{subscriptionName}</div>
        {price ? <div className={styles.coast}>$ {price}<span> /per year</span></div> : null}

    </div>
    <ul>
        <li>{studiosNumber === null ? <span>Unlimited</span> : <>Up to <span>{studiosNumber}</span> </>} Room{studiosNumber > 1 || studiosNumber === null ? 's' : ''} Per Schedule </li>
        <li><span>{stickersNumber === null ? <span>Unlimited</span> : <>Up to <span>{stickersNumber}</span> </>}</span>  Classe{stickersNumber > 1 || stickersNumber === null ? 's' : ''} Per Schedule </li>
    </ul>
    <div className={styles.payment} style={{ visibility: isAdmin ? 'hidden' : 'display' }}>
        {payment ? <div >Payment till {payment}</div> :
            <button onClick={() => dispatch({
                type: 'BUY_SUBSCRIPTION', payload: {
                    "subscriptionId": data.id,
                    "userId": id
                }
            })}>Purchase</button>}
    </div>
    <BgLines className={styles.bottomLines} />
</div>
</div> */}