import './App.scss';
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
    Route,
    Routes,
    Navigate,
    BrowserRouter,
    unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Authorization from "./pages/Authorization";
import Wrapper from './components/Wrapper';
import UserOverview from "./pages/UserOverview";
import Settings from './pages/Settings';
import Subscription from './pages/Subscription';
import Terms from './components/Terms';
import {cookies} from './redux/sagas/auth.saga';
import Schedules from './pages/Schedules';
import Spinner from 'react-bootstrap/Spinner';
import Privacy  from './components/Privacy';

import {createBrowserHistory} from 'history'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fonts.css';

const history = createBrowserHistory();


function App() {
    const {isAuthorized, loadingMainPage} = useSelector(state => state.auth);
    const {isAdmin, subscription} = useSelector(state => state.personality.user)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: 'GET_USER_BY_TOKEN'});
    }, []);


    return (
        <>
         <HistoryRouter history={history}>
            {loadingMainPage  ?
                <div className='centerWrapper'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> : (
                <Routes>
                    {!isAuthorized ? (
                        <>
                            <Route path='/' element={<Authorization/>}/>
                            <Route path='/signUp' element={<Authorization/>}/>
                            <Route path='/signUpEnterCode' element={<Authorization/>}/>
                            <Route path='/resetPassword' element={<Authorization/>}/>
                            <Route path='/*' element={<Navigate replace to='/'/>}/>

                        </>
                    ) : (
                        <>
                            {isAdmin ? (<>
                                <Route path='/users' element={<Wrapper BaseComponent={Schedules}/>}/>
                                <Route path='/users/:id' element={<Wrapper BaseComponent={UserOverview}/>}/>
                                <Route path='/subscription' element={<Wrapper BaseComponent={Subscription}/>}/>
                                <Route path='/settings' element={<Wrapper BaseComponent={Settings}/>}/>
                                <Route path='/*' element={<Navigate replace to='/users'/>}/>
                            </>) : (<>
                            {subscription?.length > 0 ? (<>
                                <Route path='/schedule' element={<Wrapper BaseComponent={UserOverview}/>}/>
                                    <Route path='/profile' element={<Wrapper BaseComponent={UserOverview}/>}/>
                                    <Route path='/subscription' element={<Wrapper BaseComponent={Subscription}/>}/>
                                 
                            </>) : null}
                            <Route path='/settings' element={<Wrapper BaseComponent={Settings}/>}/>
                            <Route path='/subscription' element={<Wrapper BaseComponent={Subscription}/>}/>
                            <Route path='/*' element={<Navigate replace to={ subscription?.length > 0  ? '/schedule' :  '/subscription'}/>}/>
                                </>
                            )

                            }
                        </>
                    )
                    }
                    
                    <Route path='/documents/termsAndConditions' element={<Terms />}/>
                    <Route path='/documents/privacyPolicy' element={ <Privacy /> }/>
                </Routes>
                )}
       </HistoryRouter> </>

    );
}

export default App;
export {history}
