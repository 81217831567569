import { RequestService } from "./RequestService";

export class AuthService extends RequestService {

    constructor() {
        super('auth');
    }


    signUp = (body) => this.request('POST', 'sign-up', '', body);
    sendVerificationCode = (body) => this.request('POST', 'send-verification-code', '', body);
    checkSignUpVerificationCode = (body) => this.request('POST', 'check-sign-up-verification-code', '', body);
    resetPassword = (body) => this.request('POST', 'reset-password', '', body);

    signIn = (body) => this.request('POST', 'sign-in', '', body);
    logOut = (body) => this.request('POST', 'logout', '', body);
    changePassword = (body) => this.request('POST', 'change-password', '', body);
    getTokens = (params) => this.request('GET', 'get-tokens', '', '', params, { 'Authorization': 'Bearer ' + localStorage.getItem('refreshToken') });


}

