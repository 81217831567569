import React from "react";
import InputMask from "react-input-mask";
import styles from "../../FormWrapper/index.module.scss";

export const PHONE_NUMBER_SIZE = 11;
const PHONE_MASK = "+1 (999) 999-9999";

const PhoneField = ({registerProps, label, error, disabled = false, fieldClassName = true}) => (
	<div className={fieldClassName ? styles.oneInput : undefined}>
		<label >{label}</label>
		<InputMask  {...registerProps} mask={PHONE_MASK} disabled={disabled} data-testid={registerProps.name}/>
		{error && (
			<div className={styles[`auth-form__answer`]}>
				<div className="text-danger">{error?.message}</div>
			</div>
		)}
	</div>
)

export default PhoneField;