import React from 'react';
import UsersTable from "../../components/UsersTable";
import styles from "./index.module.scss"

function Schedules({ pageName = "Users" }) {

  return (
    <>
      <div className="mainHeader">
        {pageName}
      </div>
      <div className="mainContainer">
        <div className={styles.tableContainer}>
          <UsersTable />
        </div>

      </div>
    </>
  );
}

export default Schedules;
