import axios from 'axios';
import { cookies } from '../redux/sagas/auth.saga';
export class RequestService {

    url = process.env.REACT_APP_API_URL || "http://localhost:8080";

    constructor(name_entity) {
        this.url += `/${name_entity}`;
    }

    request = async (method = "", path = "", id = "", body = "", queryParams = "", headers = {},) => {
        const requestOptions = {
            method: method || "get",
            url: this.url,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                ...headers
            },
            responseType: 'json',

        };


        if (Object.keys(queryParams).length > 0) requestOptions['params'] = queryParams;
        if (body) requestOptions['data'] = body;
        if (path) requestOptions.url = requestOptions.url + `/${path}`;
        if (id) requestOptions.url = requestOptions.url + '/' + id;
        const res = await axios(requestOptions);

        return await res;

    }

}




