import React, { Fragment} from 'react';
import RadioButton from "../../../RadioButton";

export default function WeekDay(props) {
  const {day, setWeekday, weekday, itemLabel, dayData, addWeekDay} = props;
  
  return (
    <Fragment key={`radio-${day}`}>
      <RadioButton
        classes="weekDays"
        uniqId={`userInfoTab-${day}`}
        onChangeHandle={setWeekday}
        size="md"
        activeValue={weekday}
        value={day}
      >
        {itemLabel}
        <span
          onClick={() =>
            addWeekDay('edit', {
              dayName: day,
              availabilityTime: [{ [itemLabel]: [dayData] }],
            })
          }
          className="editDots p-0 me-2"
        >
          ...
        </span>
      </RadioButton>
    </Fragment>
  )
}