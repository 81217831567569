import { takeLeading, call, put } from "redux-saga/effects";
import {
  stickersSetAll,
  stickerAddOne,
  stickerUpdateOne,
  stickerRemoveOne,
  addChosenSticker,
  removeChosenSticker,
} from "../slices/stickers.slice";

import { loadingEdit, errorEdit, successEdit } from "../slices/status.slice";
import { stickersService } from "../../services";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";
import {
  addOneToHistory,
  removeOneFromHistory,
  changeHistory,
} from "../slices/events.slice";

export function* getAll(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(stickersService.getAll, {
      userId: action.payload.userId,
      boardId: action.payload.boardId,
    });
    yield put(stickersSetAll(response.data));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(stickersService.create, action.payload.body);
    yield put(
      stickerAddOne({
        id: response.data.sticker.id,
        ...action.payload.body,
      })
    );
    if (!action.payload.isUndo) {
      const event = {
        reversedType: "STICKER_REMOVE_ONE",
        eventBody: response.data.sticker,
      };
      yield put(addOneToHistory(event));
      yield put(successEdit("New class has created"));
    } else {
      yield put(
        changeHistory({
          newId: response.data.sticker.id,
          oldId: action.payload.body.id || action.payload.body.stickerId,
        })
      );
      yield put(removeOneFromHistory());
    }
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  const body = {
    ...action.payload.body,
    stickerId: action.payload.body.id || action.payload.body.stickerId,
  };
  try {
    yield call(stickersService.update, body);
    yield put(stickerUpdateOne(body));

    yield put(addChosenSticker(body));
    yield put(successEdit("The class has  been updated"));

    if (!action.payload.isUndo) {
      const event = {
        reversedType: "STICKER_UPDATE_ONE",
        eventBody: action.payload.prevEvent,
      };
      yield put(addOneToHistory(event));
      yield put(removeChosenSticker());
      yield put(contentEdit("The class has  been updated"));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(removeOneFromHistory());
    }
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  const body = action.payload.body ? action.payload.body : action.payload;
  try {
    yield call(stickersService.delete, {
      stickerId: body.stickerId || body.id,
      userId: body.userId,
    });
    yield put(stickerRemoveOne(body.stickerId || body.id));
    yield put(removeChosenSticker());

    if (!action.payload.isUndo) {
      const event = {
        reversedType: "STICKER_ADD_ONE",
        eventBody: body,
      };
      yield put(addOneToHistory(event));
      yield put(contentEdit("The class has been deleted"));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(removeOneFromHistory());
    }
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchStickersSaga() {
  yield takeLeading("STICKERS_GET_ALL", getAll);
  yield takeLeading("STICKER_ADD_ONE", addOne);
  yield takeLeading("STICKER_UPDATE_ONE", updateOne);
  yield takeLeading("STICKER_REMOVE_ONE", removeOne);
}
