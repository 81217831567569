import React, { useState, useEffect } from 'react'; // Не убирать  не будет работать 

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './index.module.scss';


const Terms = () => {


    return (
        <div className={styles.terms}>
            <Container>
                <Row>
                    <Col>

                        <div className={styles.header}>
                            <h1>Terms of Service:</h1>
                            <h2>Welcome to Studio Shuffle. By using our services, you agree to the following terms of service:</h2>
                        </div>
                        <ol>
                            <li><b>Acceptance of Terms</b><br />
                                By using Studio Shuffle, you agree to be bound by these Terms of Service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</li>
                            <li><b>User Account</b><br />
                                In order to use the Studio Shuffle platform, you must create an account. You agree to provide accurate, current and complete information during the registration process and to update such information as necessary to maintain its accuracy. You are responsible for safeguarding your password and for any activities or actions under your account. Studio Shuffle reserves the right to suspend or terminate your account at any time, without notice, for any reason.</li>
                            <li><b>Subscription and Payment</b><br />
                                Studio Shuffle is a subscription-based service. You agree to pay all fees associated with your subscription in a timely manner. Failure to pay your subscription fee may result in the suspension or termination of your account.</li>
                            <li><b>Intellectual Property</b><br />
                                All content and materials available on Studio Shuffle, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations, and software, is the property of Studio Shuffle or its content suppliers and protected by United States and international copyright laws.</li>
                            <li><b>Prohibited Use</b><br />
                                You may not use Studio Shuffle for any illegal or unauthorized purpose.</li>
                            <li><b>Disclaimer of Warranties</b><br />
                                Studio Shuffle is provided on an "as is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of merchantability and fitness for a particular purpose.</li>
                            <li><b>Limitation of Liability</b><br />
                                In no event shall Studio Shuffle be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Studio Shuffle, even if Studio Shuffle has been notified orally or in writing of the possibility of such damage.</li>
                            <li><b>Indemnification</b><br />
                                You agree to indemnify and hold Studio Shuffle, its affiliates, officers, agents, employees, and partners harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of Studio Shuffle.</li>
                            <li><b>Modification of Terms</b><br />
                                Studio Shuffle reserves the right to modify these Terms of Service at any time without notice. Your continued use of Studio Shuffle following any changes to these Terms of Service constitutes acceptance of those changes.</li>
                            <li><b>Governing Law</b><br />
                                These Terms of Service shall be governed by and construed in accordance with the laws of the United States and the state of Ohio.</li>
                        </ol>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Terms
