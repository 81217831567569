import { takeLeading, call, put } from "redux-saga/effects";
import {
  addChosenUser,
  usersSetAll,
  editChosenUser,
  updateUser,
} from "../slices/users.slice";
import {
  loadingEdit,
  errorEdit,
  countUsersEdit,
  profileLoadingEdit,
  profileErrorEdit,
} from "../slices/users.slice";
import { usersService } from "../../services";
import { modalEdit, contentEdit } from "../slices/modal.slice";

export function* getAll(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(usersService.getAll, action.payload);
    yield put(usersSetAll(response.data.rows));
    yield put(countUsersEdit(response.data.count));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export function* getOneUser(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(usersService.getOne, {
      userId: action.payload.userId,
    });
    yield put(addChosenUser(response.data));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export function* changeProfileData(action) {
  yield put(profileLoadingEdit(true));
  try {
    const { email, ...value } = action.payload;
    yield call(usersService.changeProfileData, action.id, {
      ...value,
    });
    yield put(editChosenUser({ ...value }));
    yield put(updateUser({ ...value }));
    yield put(contentEdit("The user has been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(profileErrorEdit(error.response.data.message));
    } else {
      yield put(
        profileErrorEdit("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(profileLoadingEdit(false));
  }
}

export default function* watchUsersSaga() {
  yield takeLeading("USERS_GET_ALL", getAll);
  yield takeLeading("GET_ONE_USER", getOneUser);
  yield takeLeading("CHANGE_PROFILE_DATA", changeProfileData);
}
