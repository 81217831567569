import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from 'react-bootstrap/Spinner';
import { FormCheck } from 'react-bootstrap';
import Select from 'react-select'
import { selectAll as selectAllStyles } from '../../../redux/slices/styles.slice';
import { selectAll as selectAllTeachers } from '../../../redux/slices/teachers.slice';
import { selectAll as selectAllLevels } from '../../../redux/slices/levels.slice';
import { modalEdit } from '../../../redux/slices/modal.slice';
import { errorEdit, successEdit } from '../../../redux/slices/status.slice';
import { getLengthOptions, getTimeSlotInterval } from '../../../utilities';
import { customStylesSelect } from '../customStyleSelect';
import styles from "./index.module.scss";
import './index';
import moment from "moment";
import { timeSlotInterval } from 'constants/variables';

function StickerForm(props) {
    const dispatch = useDispatch();
    const { loading, error, success } = useSelector(state => state.status);
    const { chosenSticker } = useSelector(state => state.stickers);
    const { chosenBoard } = useSelector(state => state.boards);
    const { id, isAdmin } = useSelector(state => state.personality.user);
    const { chosenUser } = useSelector(state => state.users);
    const allStyles = useSelector(selectAllStyles);
    const allTeachers = useSelector(selectAllTeachers);
    const allLevels = useSelector(selectAllLevels);
    const [action, setAction] = useState('STICKER_ADD_ONE');
    const [clickDeletItem, setClickDeletItem] = useState(false);
    const [interval, setInterval] = useState(chosenSticker?.length ? getTimeSlotInterval(chosenSticker.length) : timeSlotInterval.minutes_15);
    const [lengthOptions, setLengthOption] = useState([]);
    const [defaultLengthValue, setDefaultLengthValue] = useState('');

    const onChangeCheckbox = (props) => {
        setInterval(+props.target.value);
    }

    useEffect(() => {
        dispatch(errorEdit(null));
        dispatch(successEdit(null));
        return () => {
            clearErrors();
            reset();
            dispatch(successEdit(null));
            dispatch(errorEdit(null));
        }
    }, [])

    useEffect(() => {
        clearErrors();
        reset();
    }, [props.path])

    useEffect(() => {
        if (success) reset();
    }, [success])

    useEffect(() => {
        if (error) setClickDeletItem(false);
    }, [error])


    const objectYup = {
        name: yup.string()
            .required("This field is required")
            .min(2, 'Name is too short - should be 2 chars minimum.'),
        length: yup.lazy(value => {
            switch (typeof value) {
              case 'object':
                return yup.object({
                    value: yup.number().required("This field is required")
                });
              case 'string':
                return yup.string().required("This field is required");
              default:
                return yup.mixed().required("This field is required");
            }
          }),
    };

    const schema = yup.object(objectYup).required();
    const { register, handleSubmit, formState: { errors }, clearErrors, reset, control, getValues, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: chosenSticker?.className || '',
            isReusable: chosenSticker?.isReusable || false,
        }
    });

    const getOptions = (data, entity) => {
        const options = data.map((item) => ({
            value: entity === 'level' ? item.color : item[`${entity}Name`],
            label: item[`${entity}Name`],
            id: item.id
        }))
        return options;
    }

    const getDefaultOptionsMulti = (entity) => {
        if (chosenSticker) {
            if (entity === "teachers") {
                let data = []
                chosenSticker[entity]?.map((item) => (
                    data.push({ value: item, label: item })
                ));
                chosenSticker.teachersId?.map((item, index) => (
                    data[index].id = item
                ));
                return data
            }
            if (entity !== "levels") {
                return chosenSticker[entity]?.map((item) => (
                    { value: item, label: item, id: item.id })) || []
            }
            if (entity === "levels") {
                let values = [];
                allLevels.forEach(item => {
                    const isExist = chosenSticker.levels.some(el => el === item.color);
                    if (isExist) values.push({ value: item.color, label: item.levelName, id: item.id })
                })

                return values;
            }
        } else {
            return []
        }
    }

    const teachersDefaultOprions = getDefaultOptionsMulti("teachers");
    const stylesDefaultOptions = getDefaultOptionsMulti("styles");
    const levelsDefaultOptions = getDefaultOptionsMulti("levels");

    const setLengthOptionData = useCallback((intervalValue) => {
        const [optionsLength, defaultLength] = getLengthOptions(chosenSticker, intervalValue);
        setLengthOption(optionsLength);
        setDefaultLengthValue(defaultLength);
        chosenSticker?.length && setValue('length', defaultLength);
    }, [chosenSticker, setValue])

    useEffect(() => {
        const currentStickerTimeInterval = chosenSticker?.length ? getTimeSlotInterval(chosenSticker.length) : interval;

        if (chosenSticker?.length && currentStickerTimeInterval !== interval) {
            setLengthOptionData(interval);
        } else {
            setLengthOptionData(currentStickerTimeInterval);
        } 
    }, [chosenSticker, interval, setLengthOptionData, setValue]);

    const optionsStyles = getOptions(allStyles, 'style');
    const optionsTeachers = getOptions(allTeachers, 'teacher');
    const optionsLevels = getOptions(allLevels, 'level');

    const onSubmit = (value) => {
        dispatch(successEdit(null));
        dispatch(errorEdit(null));

        if ((action === 'STICKER_REMOVE_ONE' || action === 'EVENTS_REMOVE_ONE') && !clickDeletItem) {
            setClickDeletItem(true);
            return
        }
        let body;

        if (props.isEvent) {
            body = {
                // backend accept different fields of event id :/
                stickerId: chosenSticker.id,
                scheduleId: chosenSticker.id,
                id: chosenSticker.id,
                "length": value.length?.value || '',
                className: value.name,
                start: chosenSticker.start,
                end: moment(chosenSticker.start, "HH:mm").add(value.length?.value, "hours").format("HH:mm"),
                "styles": value.style?.map(item => item.label) || [],
                "teachers": value.teacher?.map(item => item.label) || [],
                "teachersId": value.teacher?.map(item => item.id) || [],
                "levels": value.level?.map(item => item.value) || [],
                studioId: chosenSticker.studioId,
                prevStudioId: chosenSticker.studioId,
                userId: isAdmin ? chosenUser.id : id,
                dayOfTheWeek: chosenSticker.dayOfTheWeek,
                "levelsId": value.level?.map(item => item.id) || [],
            }
        }
        else {
            body = {
                className: value.name,
                userId: isAdmin ? chosenUser.id : id,
                "length": value.length?.value || '',
                "styles": value.style?.map(item => item.label) || [],
                "teachers": value.teacher?.map(item => item.label) || [],
                "teachersId": value.teacher?.map(item => item.id) || [],
                "levels": value.level?.map(item => item.value) || [],
                "levelsId": value.level?.map(item => item.id) || [],
                "stickerId": chosenSticker?.id || '',
                'isReusable': value.isReusable,
                'boardId': chosenBoard?.id || '',
            };
        }
        dispatch({ type: action, payload: { body, prevEvent: chosenSticker, addSticker: false, deleteSticker: false, fromForm: true } });
        reset();
    };

    const currentValues = getValues();
    
    return (<>
        {loading ? (
            <div className={styles.spinnerWrapper}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>) : (
            <>
                {clickDeletItem ? (
                    <div className={styles.confirm}>Are you sure you want to delete this Class? </div>) : null}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    {clickDeletItem ? null : (<>
                        <div className={styles.formAnswer}>
                            {error ? <div className="text-danger">{error}</div> : null}
                            {success ? <div className="text-success">{success}</div> : null}
                        </div>
                        <label>Name</label> <input  {...register("name")} />
                        {errors.name ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.name?.message}</div>
                            </div>) : null}
                        {allLevels.length > 0 ? (<>
                            <label>Level</label>
                            <Controller
                                render={
                                    ({ field: { onChange, onBlur, value, ref } }) => <Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                        styles={customStylesSelect}
                                        name="level"
                                        options={optionsLevels}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        menuPlacement="top"
                                        isMulti
                                        defaultValue={currentValues?.level?.length > 0 ? currentValues.level : levelsDefaultOptions}
                                    />
                                }
                                control={control}
                                name="level"
                                defaultValue={currentValues?.level?.length > 0 ? currentValues.level : levelsDefaultOptions}
                            /></>) : (<div className={styles.add}>
                                <label>No Levels Created</label>
                                <div onClick={() => dispatch(modalEdit('Levels'))}>Add Level +</div>

                            </div>)}

                        {errors.level ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.level?.message}</div>
                            </div>) : null}

                        {allTeachers.length > 0 ? (<><label>Teacher</label>
                            <Controller
                                render={
                                    ({ field: { onChange, onBlur, value, ref } }) => {
                                        return <Select
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            styles={customStylesSelect}
                                            name="teacher"
                                            options={optionsTeachers}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            menuPlacement="top"
                                            isMulti
                                            defaultValue={currentValues?.teacher?.length > 0 ? currentValues.teacher : teachersDefaultOprions}
                                        />
                                    }
                                }
                                control={control}
                                name="teacher"
                                defaultValue={currentValues?.teacher?.length > 0 ? currentValues.teacher : teachersDefaultOprions}
                            /></>) : (<div className={styles.add}>
                                <label>No Teachers Created</label>
                                <div onClick={() => dispatch(modalEdit('Teachers'))}>Add Teacher+</div>

                            </div>)}

                        {errors.teacher ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.teacher?.message}</div>
                            </div>) : null}
                        {allStyles.length > 0 ? (<>
                            <label>Style</label>
                            <Controller
                                render={
                                    ({ field: { onChange, onBlur, value, ref } }) => <Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                        styles={customStylesSelect}
                                        name="styles"
                                        options={optionsStyles}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        menuPlacement="top"
                                        isMulti
                                        defaultValue={currentValues?.style?.length > 0 ? currentValues.style : stylesDefaultOptions}
                                    />
                                }
                                control={control}
                                name="style"
                                defaultValue={currentValues?.style?.length > 0 ? currentValues.style : stylesDefaultOptions}
                            />
                        </>) : (<div className={styles.add}>
                            <label>No Styles Created</label>
                            <div onClick={() => dispatch(modalEdit('Styles'))}>Add Style +</div>

                        </div>)}

                        {errors.style ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.style?.message}</div>
                            </div>) : null}
                        <label>The length</label>
                        <Controller
                            render={
                                ({ field: { onChange, onBlur, value, ref } }) => <Select
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    value={value || defaultLengthValue}
                                    styles={customStylesSelect}
                                    name="length"
                                    options={lengthOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPlacement="top"
                                    defaultValue={currentValues?.length?.length > 0 ? currentValues.length : defaultLengthValue}
                                />
                            }
                            control={control}
                            name="length"
                            defaultValue={currentValues?.length?.length > 0 ? currentValues.length : defaultLengthValue}
                            {...register("length")}
                        />
                        <>
                            <FormCheck type="radio" label="5 minutes" value={timeSlotInterval.minutes_5} checked={timeSlotInterval.minutes_5 === interval} onChange={onChangeCheckbox} required={false}/>
                            <FormCheck type="radio" label="15 minutes" value={timeSlotInterval.minutes_15} checked={timeSlotInterval.minutes_15 === interval} onChange={onChangeCheckbox} required={false}/>
                        </>
                        {errors.length ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.length?.message}</div>
                            </div>) : null}
                        {!props.isEvent && <div className={styles.checkbox}>
                            <input type="checkbox" {...register("isReusable")} />
                            <label>This class has duplicates</label>
                        </div>
                        }

                        {errors.isReusable ? (
                            <div className={styles[`auth-form__answer`]}>
                                <div className="text-danger">{errors.isReusable.message}</div>
                            </div>) : null}
                    </>)}
                    {!chosenSticker ? <input type="submit" className={styles.submit} value="Save"
                        onClick={() => setAction('STICKER_ADD_ONE')} /> : (
                        props.isEvent ? (

                            <div className={styles.buttons}>
                                {clickDeletItem ? (<>
                                    <button className={styles.submitDelete}
                                        onClick={() => setClickDeletItem(false)}> No
                                    </button>
                                    <input type="submit" className={styles.submitUpdate} value="Yes" />

                                </>) : (<><input type="submit" className={styles.submitDelete} value="Delete"
                                    onClick={() => setAction('EVENTS_REMOVE_ONE')} />
                                    <input type="submit" className={styles.submitUpdate} value="Save"
                                        onClick={() => setAction('EVENTS_UPDATE_ONE')} /></>)}

                            </div>
                        ) : (
                            <div className={styles.buttons}>
                                {clickDeletItem ? (<>
                                    <button className={styles.submitDelete}
                                        onClick={() => setClickDeletItem(false)}> No
                                    </button>
                                    <input type="submit" className={styles.submitUpdate} value="Yes" />

                                </>) : (<>     <input type="submit" className={styles.submitDelete} value="Delete"
                                    onClick={() => setAction('STICKER_REMOVE_ONE')} />
                                    <input type="submit" className={styles.submitUpdate} value="Save"
                                        onClick={() => setAction('STICKER_UPDATE_ONE')} /></>)}

                            </div>
                        )

                    )}
                </form>
            </>)}
    </>)
}


export default StickerForm;



