import React, {useEffect} from "react";
import { useSelector , useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { errorEdit, successEdit, sentCodeEdit } from '../../../redux/slices/auth.slice';
import styles from "./index.module.scss";

const FormWrapper = ({children, clearErrors, reset}) => {
  const { loading} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    clearErrors();
    reset();
    dispatch(sentCodeEdit(false))
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    
    return () => {
      dispatch(errorEdit(null));
      dispatch(successEdit(null));
      dispatch(sentCodeEdit(false));
      clearErrors();
      reset();
  }
  }, [clearErrors, dispatch, reset])

  return (
    <>
      {loading ? (
        <div className={styles["spinner-wrapper"]}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>) : (
          <>
            {children}
          </>
      )}
    </>
  )
}

export default FormWrapper;