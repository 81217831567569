import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "@redux-saga/core";

import auth from './slices/auth.slice';
import modal from './slices/modal.slice';
import teachers from './slices/teachers.slice';
import subscriptions from './slices/subscriptions.slice';
import styles from './slices/styles.slice';
import stickers from './slices/stickers.slice';
import levels from './slices/levels.slice';
import status from './slices/status.slice';
import scheduleFilters from './slices/scheduleFilters.slice';
import events from "./slices/events.slice";
import personality from './slices/personality.slice';
import users from './slices/users.slice';
import boards from './slices/boards.slice';
import studio from './slices/studio.slice';

import rootSaga from './sagas';
import { history } from '../App'


const sagaMiddleware = createSagaMiddleware();

const initialState = {

};

const store = configureStore({
  reducer: { auth, modal, teachers, subscriptions, styles, stickers, levels, status, scheduleFilters, events, personality, users, boards, studio },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(sagaMiddleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga, history);

export default store;
