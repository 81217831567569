import { BiTime, BiUser } from "react-icons/bi";
import React from "react";
import styles from "./index.module.scss";
import { TIME_SLOTS } from "constants/variables";
import { getDuration } from "components/FiltersSidebar/helpers";
import { getHourLengthFromMilliseconds } from "utilities";

const getClassName = (length, className, slotDuration, studiosLength) => {
  const stylesArray = [];

  if (slotDuration === TIME_SLOTS.MINUTES_15) {
    if (length >= 2) {
      stylesArray.push(styles[`${className}_line-clamp`]);
    }
  } else {
    if (length > 0.5) {
      stylesArray.push(styles[`${className}_uncut`]);
    }
  }
  // For standard screen sizes
  if (studiosLength === 7) {
    stylesArray.push(styles["class-card_standard"]);
  }
  // For large screen sizes
  if (studiosLength > 9) {
    if (length === 0.5) {
      stylesArray.push(styles["class-card_small"]);
    }
    
    if (length < 0.5) {
      stylesArray.push(styles[`${className}_min-duration`]);
    }
  }

  if (studiosLength > 6) {
    stylesArray.push(styles[`${className}_small`]);
  } else if (studiosLength < 4) {
    stylesArray.push(styles[`${className}_large`]);

    if (length === 0.5) {
      stylesArray.push(styles["class-card_small"]);
    }
  } else {
    stylesArray.push(styles[`${className}_medium`]);

    if (length === 0.5) {
      stylesArray.push(styles["class-card_small"]);
    }
  }

  return stylesArray.join(" ");
};

const ClassCard = (eventInfo) => {
  const { event, timeText } = eventInfo;
  const slotDuration = getDuration(
    getHourLengthFromMilliseconds(
      eventInfo.view.calendar.currentData.options.slotDuration.milliseconds
    )
  );
  const studiosLength =
    eventInfo.view.calendar.currentData.resourceSource.meta.length;
  const hours = new Date(event.start).getHours();
  const minutes = new Date(event.start).getMinutes() / 60;
  const text = eventInfo.event.extendedProps.className || event.title;
  const length = eventInfo.event?.extendedProps.length;
  const background = eventInfo.event.extendedProps.background;
  const classNameCard = [
    styles.card,
    getClassName(length, "card", slotDuration, studiosLength),
  ].join(" ");
  const classNameCardItem = [
    styles["card__item"],
    getClassName(length, "card__item", slotDuration, studiosLength),
  ].join(" ");

  return (
    <div
      className={["test", styles.cardWrapper].join(" ")}
      data-length={length}
      style={{ height: "100%", background: background }}
      data-time={hours + minutes}
    >
      <div className={classNameCard} style={{ background: background }}>
        <div className={classNameCardItem} data-id="card-item">
          <b style={{ color: "black" }}>{text}</b>
        </div>
        <div className={classNameCardItem} data-id="card-item">
          <BiUser
            className="me-1 "
            width={13}
            size={18}
            color={background ? "black" : "grey"}
          />
          <b style={{ color: "black" }}>
            {event.extendedProps.teachers?.join(", ") || "None"}
          </b>
        </div>
        <div
          className={[
            classNameCardItem,
            styles.time,
            styles["card__item_uncut"],
          ].join(" ")}
        >
          <BiTime
            className="me-1"
            size={18}
            color={background ? "black" : "grey"}
          />
          <b style={{ color: "black" }}>{timeText || ""}</b>
        </div>
      </div>
    </div>
  );
};

export default ClassCard;
