import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { history } from '../../../App';

import { removeChosenSubscription, errorEdit, loadingEdit, successEdit } from '../../../redux/slices/subscriptions.slice';
import { modalEdit } from '../../../redux/slices/modal.slice';
import styles from "./index.module.scss";


function SubscriptionForm(props) {
    const dispatch = useDispatch();
    const { loading, error, success } = useSelector(state => state.subscriptions);
    const { chosenSubscription } = useSelector(state => state.subscriptions)
    const { isAdmin } = useSelector(state => state.personality.user);
    const { chosenUser } = useSelector(state => state.users);
    const [clickDeletItem, setClickDeletItem] = useState(false);
    const [action, setAction] = useState('SUBSCRIPTION_ADD_ONE');
    const [alert, setAlert] = useState(false);
    const _path = history.location.pathname.slice(1);
    const isPaidForm = chosenSubscription?.paid || _path !== 'subscription'


    const objectYup = {
        subscriptionName: yup.string()
            .required("This field is required"),
        /*         studiosNumber: yup.number()
                    .required("This field is required"),
                stickersNumber: yup.number()
                    .required("This field is required"), */
        price: yup.number()
            .required('This field is required')

    };

    const { subscriptionName, studiosNumber, stickersNumber, price, id, payment } = chosenSubscription || { subscriptionName: "", studiosNumber: "", stickersNumber: "", price: "", id: "", payment: "" }

    const schema = yup.object(isPaidForm ? null : objectYup).required();


    const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            subscriptionName,
            studiosNumber,
            stickersNumber,
            price,
            validUntil: payment || new Date().toISOString().split('T')[0]
        }
    });

    useEffect(() => {
        clearErrors();
        reset();
    }, [props.path])

    useEffect(() => {
        if (success) reset();
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            reset();
            dispatch(successEdit(null));
            dispatch(errorEdit(null));
            dispatch(loadingEdit(false))
        }
    }, [])


    const onSubmit = (value) => {
        dispatch(successEdit(null));
        dispatch(errorEdit(null));
        dispatch(loadingEdit(false));
        if (!isAdmin) return;
        if ((action === 'SUBSCRIPTION_REMOVE_ONE' || action === 'DELETE_PAID_SUBSCRIPTION') && !clickDeletItem) {
            setClickDeletItem(true);
            return
        }
        const price = value.price ? (Math.trunc(value.price * 100)) : null;
        dispatch({
            type: action,
            payload:
            {
                id, price: price,
                subscriptionId: id,
                subscriptionName: value.subscriptionName,
                studiosNumber: value.studiosNumber || null,
                stickersNumber: value.stickersNumber || null,
                userId: chosenUser?.id,
                validUntil: value.validUntil || null
            }
        }); 
    };

    return (<>
        {loading ? (
            <div className={styles.spinnerWrapper}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>) : (
            <>{clickDeletItem ? (<div className={styles.confirm}>Are you sure you want to delete this  Subscription? </div>) : null}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

                    {clickDeletItem ? null : (<>
                        <div className={styles.formAnswer}>
                            {error ? <div className="text-danger">{error}</div> : null}
                            {success ? <div className="text-success">{success}</div> : null}
                        </div>
                        {isPaidForm ? null : (
                            <>
                                <label >Name</label>  <input  {...register("subscriptionName")} />
                                {errors.subscriptionName ? (
                                    <div className={styles.formAnswer}>
                                        <div className="text-danger">{errors.subscriptionName?.message}</div>
                                    </div>) : null}
                            </>
                        )}

                        <label >Rooms per schedule</label>  <input  {...register("studiosNumber")} />
                        {errors.studiosNumber ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.studiosNumber?.message}</div>
                            </div>) : null}
                        <label >Classes per schedule</label>  <input  {...register("stickersNumber")} />
                        {errors.stickersNumber ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.stickersNumber?.message}</div>
                            </div>) : null}
                        {isPaidForm ? (
                            <>
                                <label >Payment till</label>  <input type='date' {...register("validUntil")} style={{ paddingRight: 10 }} />
                                {errors.validUntil ? (
                                    <div className={styles.formAnswer}>
                                        <div className="text-danger">{errors.validUntil?.message}</div>
                                    </div>) : null}

                            </>
                        ) : (
                            <>
                                <label >Fee per schedule</label>  <div onClick={() => {
                                    if (chosenSubscription) setAlert(true);
                                }} ><input  {...register("price")} disabled={chosenSubscription ? true : false} />
                                </div>
                                {alert ? <div class="alert alert-warning" role="alert">
                                    You can't edit price. Please, create a new subscription.
                                </div> : null}

                                {errors.price ? (
                                    <div className={styles.formAnswer}>
                                        <div className="text-danger">{errors.price?.message}</div>
                                    </div>) : null}

                            </>)}

                    </>)}

                    {!chosenSubscription ? <input type="submit" className={styles.submit} value="Save" onClick={() => setAction(isPaidForm ? 'CREATE_PAID_SUBSCRIPTION' : 'SUBSCRIPTION_ADD_ONE')} /> : (
                        <div className={styles.buttons}>
                            {clickDeletItem ? (<>
                                <button className={styles.submitDelete} onClick={() => setClickDeletItem(false)}> No</button>
                                <input type="submit" className={styles.submitUpdate} value="Yes" />

                            </>) : (<>
                                <input type="submit" className={styles.submitDelete} value="Delete" onClick={() => setAction(isPaidForm ? 'DELETE_PAID_SUBSCRIPTION' : 'SUBSCRIPTION_REMOVE_ONE')} />
                                <input type="submit" className={styles.submitUpdate} value="Save" onClick={() => setAction(isPaidForm ? 'UPDATE_PAID_SUBSCRIPTION' : 'SUBSCRIPTION_UPDATE_ONE')} />
                            </>)}

                        </div>)}
                </form></>)}
    </>)
}

export default SubscriptionForm;





