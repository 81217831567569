import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        chosenUser: null,
        loading: false,
        error: null,
        countUsers: null,
        profileLoading: false,
        profileError: null
    },
    reducers: {
        usersSetAll: (state, action) => {
            state.users = action.payload
        },
        addChosenUser: (state, action) => {
            state.chosenUser = action.payload
        },
        editChosenUser: (state, action) => {
            const subscription = action.payload.subscription || null
            let newData = action.payload;
            if (subscription) {
                newData = {
                    subscription: subscription ? [{
                        ...state.chosenUser.subscription?.[0],
                        ...subscription
                    }] : []
                }
            }
            state.chosenUser = {
                ...state.chosenUser,
                ...newData
            }
        },
        updateUser: (state, action) => {
            const subscription = action.payload.subscription || null
            let newData = action.payload;
            state.users = state.users.map(item => {
                if (subscription) {
                    newData = {
                        subscription: subscription ? [{
                            ...item.subscription?.[0],
                            ...subscription
                        }] : []
                    }
                }

                return action.payload.id == item.id ? {
                    ...item,
                    ...newData

                } : item

            })
        },
        removeChosenUser: (state, action) => {
            state.chosenUser = null
        },
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        profileLoadingEdit: (state, action) => {
            state.profileLoading = action.payload
        },
        profileErrorEdit: (state, action) => {
            state.profileError = action.payload
        },
        countUsersEdit: (state, action) => {
            state.countUsers = action.payload
        },

    }
})

const { actions, reducer } = usersSlice;

export default reducer;
export const { profileLoadingEdit, profileErrorEdit, usersSetAll, addChosenUser, removeChosenUser, loadingEdit, errorEdit, countUsersEdit, editChosenUser, updateUser } = actions;



