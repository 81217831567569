import React, {useEffect} from "react";
import FilterLabel from './FilterLabel';
import { BiTime, BiUser } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { getBackgroundColor, getMinutes } from "../../../utilities";
import { addChosenSticker} from '../../../redux/slices/stickers.slice';
import { modalEdit } from '../../../redux/slices/modal.slice';
import {defaultFilters} from '../../FiltersSidebar'
import { Draggable } from "@fullcalendar/interaction";
import moment from "moment/moment";
import { getDuration, getTime } from "../helpers";

const EventsPull = ({eventsPull, onRemoveHandle, filters}) => {
  const dispatch = useDispatch();
  
  const openModal = (item) => {
    dispatch(addChosenSticker(item));
    dispatch(modalEdit('Stickers'));
  };

  useEffect(() => {
    // made events from pull draggable
    const draggableEl = document.getElementById('events-pull')
    const draggable = new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        const data = JSON.parse(eventEl.getAttribute('data-event'))
        const background = getBackgroundColor(data)
        // data what will add in Fullcalendar event obj
        return {
          id: data.id,
          studioId: data.studioId,
          title: data.className,
          className: data.className,
          start: moment(data.date).format(),
          end: moment(data.date).add(data.length, "hours").format(),
          backgroundColor: data.levels.length > 0 ? data.levels[0] : "",
          teachers: data.teachers,
          teachersId: data.teachersId,
          styles: data.styles,
          levels: data.levels,
          background,
          duration: getDuration(data.length),
        }
      }
    })
  
    return () => draggable.destroy()
  }, []);

  return (
    <div id="events-pull" className="searchResults mt-3">
      {Object.keys(defaultFilters).map((filterName) => 
        <FilterLabel filters={filters} filterName={filterName} onRemoveHandle={onRemoveHandle} key={filterName}/>
      )}
      <h5>Search results</h5>
      <div id='external-events' className='cardsPull'>
        {eventsPull.map((item, index) => {
          const background = getBackgroundColor(item)

          return (
            <div
              onClick={() => openModal(item)}
              key={`eventsPull-${index}`}
              className='fc-event events'
              data-event={JSON.stringify(item)}
              style={{ background }}
            >
              <b style={{ color: 'black' }} className="title">
                {item.className || "none"}
              </b>
              <b style={{ color: 'black' }}>
                <BiUser className="me-1 " width={13} size={18} color={background ? 'black' : 'grey'} />
                {item.teachers?.join(", ") || "none"}
              </b>
              <b style={{ color: 'black' }}>
                <BiTime className="me-1" size={18} color={background ? 'black' : 'grey'} />
                {item.length ? getTime(getMinutes(item.length)) : ""}
              </b>
            </div>
          )
        })}
      </div>
    </div>
  )
};

export default EventsPull;