import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const stickersAdapter = createEntityAdapter();

export const initialState = stickersAdapter.getInitialState({
    chosenSticker: null,
})

const stickersSlice = createSlice({
    name: 'stickers',
    initialState,
    reducers: {
        stickersSetAll: (state, action) => {
            stickersAdapter.setAll(state, action.payload);
        },
        stickerAddOne: (state, action) => {
            stickersAdapter.addOne(state, action.payload);
        },
        stickerUpdateOne: (state, action) => {
            stickersAdapter.updateOne(state, {
                id: action.payload.stickerId, changes:action.payload
            },);

        },
        stickerRemoveOne: (state, action) => {
            stickersAdapter.removeOne(state, action.payload);
        },
        addChosenSticker: (state, action) => {
            state.chosenSticker = action.payload
        },
        removeChosenSticker: (state, action) => {
            state.chosenSticker = null
        }

    },
})

const { actions, reducer } = stickersSlice;
export const { stickersSetAll, stickerAddOne, stickerUpdateOne, stickerRemoveOne, addChosenSticker, removeChosenSticker } = actions;

export default reducer;
export const { selectAll } = stickersAdapter.getSelectors(state => state.stickers);
