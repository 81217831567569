import {ToggleButton} from "react-bootstrap";
import styles from "./index.module.scss";

export default function RadioButton({classes,onChangeHandle, activeValue, value, children,size, uniqId}) {
    return (
        <ToggleButton
            className={`${classes} ${styles.radioButton}`}
            size={size}
            id={uniqId}
            type="radio"
            variant="outline-primary"
            value={value}
            checked={activeValue === value}
            onChange={(e) => onChangeHandle(e.currentTarget.value)}
        >
            {children}
        </ToggleButton>)
}


