import { takeLeading, call, put } from "redux-saga/effects";
import {
  studioSetAll,
  studioAddOne,
  studioUpdateOne,
  studioRemoveOne,
  removeChosenStudio,
  loadingEdit,
  errorEdit,
  successEdit,
} from "../slices/studio.slice";
import { studioService } from "../../services";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";

export function* getAll(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(studioService.getAll, action.payload);
    yield put(studioSetAll(response.data));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(studioService.create, action.payload);
    yield put(studioAddOne(response.data.studio));
    yield put(successEdit("New room has been created"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(studioService.update, action.payload);
    yield put(studioUpdateOne(action.payload));
    yield put(removeChosenStudio());
    yield put(contentEdit("The room has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(studioService.delete, action.payload);
    yield put(studioRemoveOne(action.payload.studioId));
    yield put(removeChosenStudio());
    yield put(contentEdit("The room has been deleted"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchRoomsSaga() {
  yield takeLeading("ROOMS_GET_ALL", getAll);
  yield takeLeading("ROOM_ADD_ONE", addOne);
  yield takeLeading("ROOM_UPDATE_ONE", updateOne);
  yield takeLeading("ROOM_REMOVE_ONE", removeOne);
}
