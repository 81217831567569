import React, { useState, useEffect } from 'react'; // Не убирать  не будет работать 

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './index.module.scss';


const Privacy = () => {


    return (
        <div className={styles.terms}>
            <Container>
                <Row>
                    <Col>

                        <div className={styles.header}>
                            <h1>Privacy Policy:</h1>
                            <div>At Studio Shuffle, we take the privacy of our users seriously. This privacy policy outlines how we collect, use, and protect your personal information when you use our software and website.</div>
                        </div>
                        <ol>
                            <li><b>Information Collection and Use:</b><br />
                                <ul>
                                    <li> We may collect personal information from you when you sign up for a Studio Shuffle account, including your name, email address, phone number, and payment information. We use this information to provide you with our software services and to communicate with you about your account.</li>
                                    <li> We may also collect non-personal information such as your IP address, browser type, and operating system when you use our website. This information helps us to improve our website and better understand how our users are using our software.</li>
                                </ul>



                            </li>
                            <li><b> Information Sharing:</b><br />
                                We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our website and providing our services, as well as to comply with legal requirements.
                            </li>

                            <li><b>Data Security:</b><br />
                                We take data security seriously and have implemented measures to protect your personal information. However, no transmission of data over the internet can be guaranteed to be 100% secure, and we cannot guarantee the security of your data.</li>

                            <li><b>Your Rights:</b><br />
                                You have the right to access, correct, or delete your personal information that we hold. You can update your account information by logging into your account. If you want to delete your account or have any questions about your personal information, please contact us at <email><b>info@studioshuffle.com</b></email></li>

                            <li><b>Changes to the Privacy Policy:</b><br />
                                We may update this privacy policy from time to time to reflect changes in our practices. We will notify you of any material changes to the privacy policy by email or by posting a notice on our website.</li>

                            <li><b>Contact Us:</b><br />
                                If you have any questions or concerns about our privacy policy, please contact us at <email><b>info@studioshuffle.com</b></email></li>

                        </ol>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Privacy
