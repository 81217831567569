import React, { Component, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { customStylesSelectForTime } from '../customStyleSelect';
import { getTimeOptions, getDefaultTimeValue, getTimeOptionsTeacher } from '../options';
import { errorEdit, successEdit, loadingEdit } from '../../../redux/slices/status.slice';
import styles from "./index.module.scss";
import { useParams } from "react-router-dom";
import { modalEdit } from "../../../redux/slices/modal.slice";
import { options } from '@fullcalendar/core/preact';
import { Availability, week } from '../TeacherForm';


function DaysOfWeekForm() {
    const { chosenDay, activeDays, chosenBoard } = useSelector(state => state.boards);
    const options = getTimeOptions();


    const getDefailtValue = () => {
        let count = 0;
        let defaultValueElement = [];
        if (chosenDay) {
            chosenDay["availabilityTime"].forEach((item, i) => {
                const day = Object.keys(item)[0];
                const values = item[day];
                values.forEach(element => {
                    defaultValueElement.push({
                        day: { value: day, index: week.findIndex(item => item.value === day) },
                        beginTime: element.start,
                        finishTime: element.end
                    });
                    count++;
                })
            })
        } else {
            count = 1;
            defaultValueElement = []
        }
        return [count, defaultValueElement]
    };
    const [count, defaultValueElement] = getDefailtValue();
    const [countAvailabilityTeacher, setCountAvailabilityTeacher] = useState(count);
    const [defaultValue, setDefaultValue] = useState(defaultValueElement);
    const dispatch = useDispatch();
    const { loading, error, success } = useSelector(state => state.status);
    const [action, setAction] = useState('DAY_ADD_ONE');
    const { id } = useSelector(state => state.personality.user);

    const { isAdmin } = useSelector(state => state.personality.user);
    const { chosenUser } = useSelector(state => state.users);
    const [clickDeletItem, setClickDeletItem] = useState(false);


    useEffect(() => {
        return () => {
            clearErrors();
            reset();
            dispatch(successEdit(null));
            dispatch(errorEdit(null));
            dispatch(loadingEdit(false));
        }
    }, [])

    useEffect(() => {
        if (success) {
            reset();
        }

    }, [success])


    const objectYup = {
        availability: yup.array()
            .required("This field is required")
            .test('optionality', "Fields is required. Incorrect time", function (value) {
                let result = true;
                let message = '';
                const { path, createError } = this;
                for (let i = 0; i < value.length; i++) {
                    if (value[i] === null) break;
                    const { day, beginTime, finishTime } = value[i];
                    if (day) {
                        if ((beginTime && !finishTime) || (!beginTime && finishTime)) {
                            result = false;
                            message = "Choose start and end time";
                            break;
                        }
                        if (beginTime > finishTime) {
                            result = false;
                            message = "Choose correct time";
                            break;
                        }
                        if (!beginTime && !finishTime) {
                            setValue(`availability.${i}`, { ...value[i], beginTime: '00:00:00', finishTime: '23:00:00' });

                        }
                    } else {
                        result = false;
                        message = "Choose day";
                        break;
                    }
                }
                return result || createError({ path, message });
                ;
            })
    };


    const schema = yup.object(objectYup).required();
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset,
        control,
        getValues,
        setValue,
        resetField
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            availability: defaultValue
        }
    });


    const getListAvailability = () => {
        const ListAvailability = [];
        for (let i = 0; i < countAvailabilityTeacher; i++) {
            ListAvailability.push(<Availability count={i} getValues={getValues} setValue={setValue} key={`availabilityList-${i}`} errors={errors}
                defaultValue={defaultValue[i] || null} options={options} activeDays={activeDays ? activeDays : null}
                editDay={chosenDay ? true : false} />)
        }
        return ListAvailability;
    }


    const getAvailabilityTime = (value) => {
        const daysOfWeek = [null, null, null, null, null, null, null]
        value.availability?.forEach(item => {
            if (item === null) return;
            const day = item.day.value;
            const index = item.day.index;
            if (daysOfWeek[index]) {
                daysOfWeek[index][day].push({ start: item.beginTime, end: item.finishTime });
            } else {
                daysOfWeek[index] = { [day]: [{ start: item.beginTime, end: item.finishTime }] }
            }


        });

        return daysOfWeek.filter(item => item !== null)
    }


    const onSubmit = (value) => {
        dispatch(successEdit(null));
        dispatch(errorEdit(null));
        dispatch(loadingEdit(false));
        if (action === 'DAY_REMOVE_ONE' && !clickDeletItem) {
            setClickDeletItem(true);
            return
        }

        const day = value.availability[0]?.day.value.toLowerCase();
        const payload = {
            userId: isAdmin ? chosenUser.id : id,
            boardId: chosenBoard.id,
            boardTime: {
                ...chosenBoard.boardDays,
                [day]: {
                    "start": action === 'DAY_REMOVE_ONE' ? null : value.availability[0].beginTime,
                    "end": action === 'DAY_REMOVE_ONE' ? null : value.availability[0].finishTime
                }
            }

        }


        dispatch({ type: "UPDATE_BOARD_TIME", payload });
    };


    return (<>
        {loading ? (
            <div className={styles.spinnerWrapper}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>) : (
            <> {
                (activeDays?.length === 7 && !chosenDay) ? <div className={styles.alert}>
                    All days have been added
                </div> : (<>
                    {clickDeletItem ? (
                        <div className={styles.confirm}>Are you sure you want to delete this Days? </div>) : null}
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        {clickDeletItem ? null : (<>
                            <div className={styles.formAnswer}>
                                {error ? <div className="text-danger">{error}</div> : null}
                                {success ? <div className="text-success">{success}</div> : null}
                            </div>
                            {getListAvailability()}

                            {errors.availability ? (
                                <div className={styles.formAnswer}>
                                    <div className="text-danger">{errors.availability?.message}</div>
                                </div>) : null}
                            <div className={styles.availabilityFooter}>
                            </div>

                        </>)}
                        {!chosenDay ? <input type="submit" className={styles.submit} value="Save"
                            onClick={() => setAction('DAY_ADD_ONE')} /> : (
                            <div className={styles.buttons}>
                                {clickDeletItem ? (<>
                                    <button className={styles.submitDelete} onClick={() => setClickDeletItem(false)}> No
                                    </button>
                                    <input type="submit" className={styles.submitUpdate} value="Yes" />

                                </>) : (<> <input type="submit" className={styles.submitDelete} value="Delete"
                                    onClick={() => setAction('DAY_REMOVE_ONE')} />
                                    <input type="submit" className={styles.submitUpdate} value="Save"
                                        onClick={() => setAction('DAY_UPDATE_ONE')} /></>)}

                            </div>)}
                    </form>

                </>)
            }

            </>)}
    </>)
}

export default DaysOfWeekForm;


