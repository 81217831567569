export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const radiosTabs = [
    {name: 'Teachers', value: 'Teachers'},
    {name: 'Levels', value: 'Levels'},
    {name: 'Style', value: 'Styles'},
];

export const tabSwitcher = [
    {
        label: "Info",
        value: "Info"
    },
    {
        label: "Schedule",
        value: "Schedule"
    }
];

export const timeSlotInterval = {
    minutes_5: 5,
    minutes_15: 15
}

export const classDuration = {
    min: {
        minutesInterval_5: 25,
        minutesInterval_15: 30
    },
    max: 240
}

export const TIME_SLOTS = {
    MINUTES_5: '00:05',
    MINUTES_15: '00:15'
}
