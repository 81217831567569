import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const teachersAdapter = createEntityAdapter();

export const initialState = teachersAdapter.getInitialState({
    chosenTeacher: null,
})

const teachersSlice = createSlice({
    name: 'teachers',
    initialState,
    reducers: {
        teachersSetAll: (state, action) => {
            teachersAdapter.setAll(state, action.payload);
        },
        teacherAddOne: (state, action) => {
            teachersAdapter.addOne(state, action.payload);
        },
        teacherUpdateOne: (state, action) => {
            teachersAdapter.updateOne(state, {
                id: action.payload.teacherId,
                changes: {
                    teacherName: action.payload.teacherName,
                    teacherAndstyles: action.payload.teacherAndstyles,
                    availabilityTime: action.payload.availabilityTime
                }
            },);
        },
        teacherRemoveOne: (state, action) => {
            teachersAdapter.removeOne(state, action.payload);
        },
        addChosenTeacher: (state, action) => {
            state.chosenTeacher = action.payload
        },
        removeChosenTeacher: (state, action) => {
            state.chosenTeacher = null
        },

    },
})

const { actions, reducer } = teachersSlice;
export const { teachersSetAll, teacherAddOne, teacherUpdateOne, teacherRemoveOne, addChosenTeacher, removeChosenTeacher } = actions;

export default reducer;
export const { selectAll } = teachersAdapter.getSelectors(state => state.teachers);


/* import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        modal: null
    },
    reducers: {
        modalEdit: (state, action) => {
            state.modal = action.payload
        },
    }
})

const { actions, reducer } = modalSlice;

export default reducer;
export const { modalEdit } = actions; */
