import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../FormWrapper/index.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  errorEdit,
  successEdit,
  sentCodeEdit,
} from "../../../redux/slices/auth.slice";
import PasswordField from "../Field/PasswordField";
import Field from "../Field";
import PhoneField, { PHONE_NUMBER_SIZE } from "../Field/PhoneField";
import FormWrapper from "../FormWrapper";
import { AiOutlineArrowLeft } from "react-icons/ai";

const SignUpForm = () => {
  const { error, success, sentCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const signUpFormYup = {
    name: yup
      .string()
      .required("This field is required")
      .min(2, "Name is too short - should be 2 chars minimum.")
      .max(255, "Name is too long - should be 255 chars maximum"),
    studioName: yup
      .string()
      .required("This field is required")
      .min(2, "Studio Name is too short - should be 2 chars minimum.")
      .max(255, "Studio Name is too long - should be 255 chars maximum"),
    email: yup
      .string()
      .email("Please provide a valid email address.")
      .required("This field is required")
      .max(255, "Email is too long - should be 255 chars maximum"),
    phone: yup
      .string()
      .required("This field is required")
      .test("len", "Please provide a valid phone number.", (value) => {
        const valueLength = value.replace(/[\s\-()+_]/g, "").length;
        return valueLength === PHONE_NUMBER_SIZE;
      }),
    studioAddress: yup
      .string()
      .required("This field is required")
      .min(2, "Studio Address is too short - should be 2 chars minimum.")
      .max(255, "Studio Address is too long - should be 255 chars maximum"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(255, "Password is too long - should be 255 chars maximum")
      .matches(
        /[a-zA-Z]+/,
        "Password should contain at least one latin letter"
      ),
    confirmPassword: yup
      .string()
      .required("No password provided.")
      .test("optionality", "Passwords must match", function (value) {
        const { confirmPassword, password } = getValues();
        return confirmPassword.trim() === password.trim();
      }),
    terms: yup
      .boolean()
      .required(
        "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy"
      )
      .oneOf(
        [true],
        "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy"
      ),
    ...(sentCode && {
      code: yup.string().required("This field is required"),
    }),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(yup.object(signUpFormYup).required()),
  });

  const onSubmit = (value) => {
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    if (!sentCode) {
      dispatch({
        type: "SEND_VERIFICATION_CODE",
        payload: { email: value.email, type: 1 },
      });
      return;
    }
    const { phone, ...rest } = value;
    const payload = {
      ...rest,
      phone: phone.replace(/[^0-9]/g, ""),
      code: value.code?.trim(),
    };

    dispatch({ type: "SIGN_UP", payload });
  };

  const resendCode = () => {
    dispatch({
      type: "SEND_VERIFICATION_CODE",
      payload: { email: getValues().email, type: 1 },
    });
  };

  const goBack = () => {
    setValue("code", "");
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    dispatch(sentCodeEdit(false));
  };

  return (
    <FormWrapper clearErrors={clearErrors} reset={reset}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles["auth-form"]}>
        <div className={styles[`auth-form__answer`]}>
          {error && <div className="text-danger">{error}</div>}
          {success && <div className="text-success">{success}</div>}
        </div>
        <div className={styles.inputsWrapper}>
          {sentCode ? (
            <div className={styles.code}>
              <div className={styles.goBack} onClick={goBack}>
                <AiOutlineArrowLeft />
                <span>Go Back</span>
              </div>
              <div className={styles.wrapperCode}>
                <label>Enter code below</label>
                <input {...register("code")} />
                {errors.code && (
                  <div className={styles[`auth-form__answer`]}>
                    <div className="text-danger">{errors.code?.message}</div>
                  </div>
                )}
              </div>
              <div className={styles.resentCode} onClick={resendCode}>
                Resend code
              </div>
            </div>
          ) : (
            <>
              <Field
                registerProps={register("name")}
                label={"Name"}
                error={errors.name}
              />
              <Field
                registerProps={register("studioName")}
                label={"Studio Name"}
                error={errors.studioName}
              />
              <Field
                registerProps={register("email")}
                label={"Email"}
                error={errors.email}
                type={"email"}
              />
              <PhoneField
                registerProps={register("phone")}
                label={"Phone"}
                error={errors.phone}
              />
              <Field
                registerProps={register("studioAddress")}
                label={"Studio Address"}
                error={errors.studioAddress}
              />
              <PasswordField
                registerProps={register("password")}
                error={errors.password}
                label={"Password"}
              />
              <PasswordField
                registerProps={register("confirmPassword")}
                error={errors.confirmPassword}
                label={"Confirm password"}
              />
              <>
                <div className={styles["checkbox-wrapper"]}>
                  <input
                    type="checkbox"
                    {...register("terms")}
                    data-testid="terms"
                  />
                  <label>
                    I agree to the{" "}
                    <a href="/documents/termsAndConditions">
                      <span>Terms</span>
                    </a>{" "}
                    and have read the{" "}
                    <a href="/documents/privacyPolicy">
                      <span>Privacy Policy</span>
                    </a>
                  </label>
                </div>
                {errors.terms && (
                  <div className={styles[`auth-form__answer`]}>
                    <div className="text-danger">{errors.terms.message}</div>
                  </div>
                )}
              </>
            </>
          )}
        </div>
        <input type="submit" className={styles["submit"]} value={"Sign up"} />
      </form>
    </FormWrapper>
  );
};

export default SignUpForm;
