import { CommonService } from './CommonService';

export class SubscriptionService extends CommonService {

    entity;

    constructor(name_entity) {
        super(`${name_entity}`);
        this.entity = name_entity;
    }
    buySubscription = (body) => this.request('post', `create-payment-link`, '', body);
}
