import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './index.module.scss';
import logo from '../../assets/img/logo.png';
import { history } from '../../App';
import ModalConfirm from '../../components/ModalConfirm';
import LoginForm from 'components/Form/LoginForm';
import SignUpForm from 'components/Form/SignUpForm';
import ResetPasswordForm from 'components/Form/ResetPasswordForm';

const dataForm = {
  logIn: {
    title: 'Welcome!',
    subTitle: 'Please enter your details below',
    action: "LOG_IN",
    navigate: '/',
    component: <LoginForm />
  },
  signUp: {
    title: 'Create an account ',
    subTitle: 'Please enter your details below',
    action: "SIGN_UP",
    navigate: '/',
    component: <SignUpForm />
  },
  resetPassword: {
    title: 'Reset password',
    subTitle: 'Please enter your email below',
    action: "RESET_PASSWORD",
    navigate: '/',
    component: <ResetPasswordForm />
  },
}

function Authorization() {
  const _path = history.location.pathname.slice(1) || "logIn";
  const _existForm = _path !== "confirmEmail";
  const { content } = useSelector(state => state.modal);
  const { sentCode } = useSelector(state => state.auth);

  return (
    <div className={styles.wrapper}>
      {content ? <ModalConfirm /> : null}
      <Container className="py-0" fluid>
        <Row className="py-0">
          <Col className="px-0" sm={12}>
            <div className={styles["left-part"]}>
              <div className={styles.main}>
                <div className={styles.header}>
                  <img className={styles.logo} alt='logo' src={logo} />
                  <div className={styles["logo-text"]}>Studio Shuffle</div>
                </div>
                <div className={styles.content}>
                  {_existForm ? (
                    <>
                      <div className={styles.title}>{dataForm[_path]['title']} </div>
                      <div className={styles.subtitle}>{sentCode ? 'Please, enter code below' : dataForm[_path]['subTitle']}</div>
                      <div className={styles.form}>
                        {dataForm[_path].component}
                      </div>
                      {(_path === "logIn") && _existForm ? (
                        <>
                          <div className={styles.footer}>
                            Don’t have an account? <span><Link to='/signUp'>Sign up</Link></span>
                          </div>
                      </>) : null}
                    </>
                  ) : 
                  (
                    <div className={styles.textWrapper}>
                      <div >{dataForm[_path]['title']} </div>
                      <div >{dataForm[_path]['subTitle']}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Authorization;
