import React, { Component, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { customStylesSelect } from '../customStyleSelect';

import { errorEdit, successEdit, loadingEdit } from '../../../redux/slices/status.slice';
import { modalEdit } from '../../../redux/slices/modal.slice';
import { selectAll as selectAllStyles } from '../../../redux/slices/styles.slice';
import { removeChosenTeacher } from '../../../redux/slices/teachers.slice'
import styles from "./index.module.scss";
import { getTimeOptionsTeacher, getDefaultTimeValue } from '../options';

export const week = [{ label: "Mon", value: "Monday" }, { label: "Tue", value: "Tuesday" }, { label: "Wed", value: "Wednesday" }
    , { label: "Thu", value: "Thursday" }, { label: "Fri", value: "Friday" }, { label: "Sat", value: "Saturday" }, { label: "Sun", value: "Sunday" }];

export function Availability({ count, setValue, defaultValue, options, activeDays, editDay, }) {
    const [chosenDay, setChoseDay] = useState(defaultValue?.day ? defaultValue?.day.index : null);
    const [defaultStartTime, defaultEndTime] = defaultValue ? getDefaultTimeValue(defaultValue.beginTime, defaultValue.finishTime, options) : [null, null];
    const customStylesSelect = {
        control: base => ({
            ...base,
            minHeight: 50,

            border: "1px solid #D9D9D9",
            borderRadius: "10px",
            background: "#F9F9FC",
            marginBottom: 10,
            width: "180px"
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 4
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 6px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    return (
        <div className={styles.wrapperAvailability}>
            {editDay ? (<>
                <label>Availability</label>
                <div className={styles.availability}>
                    <input
                        type="button"
                        value={week[chosenDay]['label']}
                        className={styles.activeDay}
                        disabled
                    />
                </div>

            </>) : (<>
                <label>Availability</label>
                <div className={styles.availability}>
                    {week.map((item, i) => {
                        if (activeDays?.includes(item.value)) {
                            return
                        }
                        return <> <input
                            type="button"
                            value={item.label}
                            className={(chosenDay === i) ? styles.activeDay : null}
                            onClick={() => {
                                if (chosenDay === i) {
                                    setValue(`availability.${count}.day`, null);
                                    setChoseDay(null)
                                } else {
                                    setValue(`availability.${count}.day`, { value: item.value, index: i });
                                    setChoseDay(i)
                                }

                            }}
                        />
                        </>
                    })}
                </div>

            </>)}
            <label>Time</label>
            <div className={styles.time}>
                <Select
                    onChange={(choice) => {
                        setValue(`availability.${count}.beginTime`, choice.value);
                    }}
                    styles={customStylesSelect}
                    name="beginTime"
                    options={options}
                    menuPlacement="top"
                    defaultValue={defaultStartTime}
                    maxMenuHeight={220}

                />

                <div className={styles.timeItem}></div>
                <Select
                    onChange={(choice) => {
                        setValue(`availability.${count}.finishTime`, choice.value);
                    }}
                    styles={customStylesSelect}
                    name="finishTime"
                    options={options}
                    menuPlacement="top"
                    defaultValue={defaultEndTime}
                    maxMenuHeight={220}

                />
                {/*                 <div className={styles.close} onClick={() => {
                    setValue(`availability.${count}`, null);
                    setValue(`availability.${count}`, null);
                }}>
                    <div></div>
                    <div ></div>
                </div> */}
            </div>
        </div>
    )

}


function TeacherForm(props) {
    const { chosenTeacher } = useSelector(state => state.teachers);
    const getDefailtValue = () => {
        let count = 0;
        let defaultValueElement = [];
        if (chosenTeacher?.["availabilityTime"]) {
            chosenTeacher["availabilityTime"]?.forEach((item, i) => {
                const day = Object.keys(item)[0];
                const values = item[day];
                values.forEach(element => {
                    defaultValueElement.push({ day: { value: day, index: week.findIndex(item => item.value === day) }, beginTime: element.start, finishTime: element.end });
                    count++;
                })
            })
        } else {
            count = 1;
            defaultValueElement = []
        }
        return [count, defaultValueElement]
    };
    const options = getTimeOptionsTeacher();
    const [count, defaultValueElement] = getDefailtValue();


    const [countAvailabilityTeacher, setCountAvailabilityTeacher] = useState(count);
    const [defaultValue, setDefaultValue] = useState(defaultValueElement);

    const dispatch = useDispatch();
    const { loading, error, success } = useSelector(state => state.status);
    const allStyles = useSelector(selectAllStyles);

    const [action, setAction] = useState('TEACHER_ADD_ONE');

    const { id } = useSelector(state => state.personality.user);

    const { isAdmin } = useSelector(state => state.personality.user);
    const { chosenUser } = useSelector(state => state.users);
    const [clickDeletItem, setClickDeletItem] = useState(false);


    useEffect(() => {
        return () => {
            clearErrors();
            reset();
            dispatch(successEdit(null));
            dispatch(errorEdit(null));
            dispatch(loadingEdit(false));
        }
    }, [])


    useEffect(() => {
        if (success) {
            reset();
            setCountAvailabilityTeacher(count)
        }
    }, [success])

    const getStylesOptions = () => {
        return allStyles.map((item) => ({ value: item.id, label: item.styleName }))
    }

    const getDefaultOptions = () => {
        if (chosenTeacher) {
            return chosenTeacher?.['teacherAndstyles']?.map((item) => (
                { value: item.styleId, label: item.style.styleName })) || []
        } else {
            return []
        }
    }



    const objectYup = {
        teacherName: yup.string()
            .required("This field is required")
            .min(2, 'Name is too short - should be 2 chars minimum.'),
        /*         styles: yup.array()
                    .test('oneOf', "This field is required", function (value) {
                        console.log(value);
                        return true;
                    }) */
        availability: yup.array()
            .required("This field is required")
            .test('optionality', "Fields is required. Incorrect time", function (value) {
                let result = true;
                let message = '';
                const { path, createError } = this;
                for (let i = 0; i < value.length; i++) {
                    if (value[i] === null) break;
                    const { day, beginTime, finishTime } = value[i];
                    if (day) {
                        if ((beginTime && !finishTime) || (!beginTime && finishTime)) {
                            result = false;
                            message = "Choose start and end time";
                            break;
                        }
                        if (beginTime > finishTime) {
                            result = false;
                            message = "Choose correct time";
                            break;
                        }
                        if (!beginTime && !finishTime) {
                            setValue(`availability.${i}`, { ...value[i], beginTime: '00:00:00', finishTime: '23:00:00' });

                        }
                    } else {
                        if (beginTime || finishTime) {
                            result = false;
                            message = "Choose day";
                            break;
                        }
                    }
                }
                return result || createError({ path, message });;
            })
    };


    const schema = yup.object(objectYup).required();
    const { register, handleSubmit, formState: { errors }, clearErrors, reset, control, getValues, setValue, resetField } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            teacherName: chosenTeacher?.teacherName || '',
            availability: defaultValue
        }
    });


    const getListAvailability = () => {
        const ListAvailability = [];
        for (let i = 0; i < countAvailabilityTeacher; i++) {
            ListAvailability.push(<Availability reset={reset} count={i} getValues={getValues} setValue={setValue} errors={errors} defaultValue={defaultValue[i] || null} options={options} />)
        }
        return ListAvailability;
    }



    const getAvailabilityTime = (value) => {
        const daysOfWeek = [null, null, null, null, null, null, null]
        value.availability?.forEach(item => {
            if (item === null) return;
            const day = item.day.value;
            const index = item.day.index;
            if (daysOfWeek[index]) {
                daysOfWeek[index][day].push({ start: item.beginTime, end: item.finishTime });
            } else {
                daysOfWeek[index] = { [day]: [{ start: item.beginTime, end: item.finishTime }] }
            }


        });

        return daysOfWeek.filter(item => item !== null)
    }



    const getTeacherAndstyles = (value) => {
        const teacherAndstyles = [];
        const stylesId = value['styles']?.map(item => {
            teacherAndstyles.push({ styleId: item.value, style: { styleName: item.label } })
            return item.value;
        }) || [];

        return [teacherAndstyles, stylesId]
    };
    const onSubmit = (value) => {
        dispatch(successEdit(null));
        dispatch(errorEdit(null));
        dispatch(loadingEdit(false));
        if (action === 'TEACHER_REMOVE_ONE' && !clickDeletItem) {
            setClickDeletItem(true);
            return
        }

        const [teacherAndstyles, stylesId] = getTeacherAndstyles(value);
        const payload = {
            teacherName: value.teacherName,
            stylesId,
            teacherId: chosenTeacher?.id || '',
            'teacherAndstyles': teacherAndstyles,
            userId: isAdmin ? chosenUser.id : id,
            availabilityTime: value.availability?.length > 0 ? getAvailabilityTime(value) : null
        }

        dispatch({ type: action, payload })
    };


    return (<>
        {loading ? (
            <div className={styles.spinnerWrapper}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>) : (
            <>
                {clickDeletItem ? (<div className={styles.confirm}>Are you sure you want to delete this Teacher? </div>) : null}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    {clickDeletItem ? null : (<>
                        <div className={styles.formAnswer}>
                            {error ? <div className="text-danger">{error}</div> : null}
                            {success ? <div className="text-success">{success}</div> : null}
                        </div>
                        <label >Name</label>  <input {...register("teacherName")} />
                        {errors.teacherName ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.teacherName?.message}</div>
                            </div>) : null}
                        {allStyles.length > 0 ? (<>
                            <label >Styles</label>
                            <Controller
                                render={
                                    ({ field: { onChange, onBlur, value, ref } }) => <Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                        styles={customStylesSelect}
                                        defaultValue={getDefaultOptions()}
                                        isMulti
                                        name="styles"
                                        options={getStylesOptions()}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        menuPlacement="top"
                                    />
                                }
                                control={control}
                                name="styles"
                                defaultValue={getDefaultOptions()}
                                className='overflow-y-scroll'
                            />
                        </>
                        ) : (<div className={styles.add}>
                            <label>No Styles Created</label>
                            <div onClick={() => dispatch(modalEdit('Styles'))}>Add Style +</div>

                        </div>)}


                        {errors.styles ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.styles?.message}</div>
                            </div>) : null}

                        {getListAvailability()}
                        {/* {listAvailability} */}
                        {errors.availability ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.availability?.message}</div>
                            </div>) : null}
                        <div className={styles.availabilityFooter}>
                            <div
                                onClick={() => setCountAvailabilityTeacher((countAvailabilityTeacher) => countAvailabilityTeacher + 1)}
                                className={styles.addText}
                            >
                                Add Time +
                            </div>
                            {countAvailabilityTeacher > 1 ? <div
                                onClick={() => {
                                    console.log(countAvailabilityTeacher, defaultValue.length, 'countAvailabilityTeacher')
                                    if (defaultValue.length + 1 > countAvailabilityTeacher) {
                                        /* console.log('if');
                                        console.log(defaultValue.filter((item, i) => i === countAvailabilityTeacher - 1)) */
                                        setDefaultValue(defaultValue => defaultValue.filter((item, i) => i === countAvailabilityTeacher - 1))

                                    }
                                    setValue(`availability.${countAvailabilityTeacher - 1}`, null);
                                    setCountAvailabilityTeacher((countAvailabilityTeacher) => countAvailabilityTeacher - 1)
                                }}
                                className={styles.deleteText}
                            >
                                Delete Time -
                            </div> : null}
                        </div>

                    </>)}
                    {!chosenTeacher ? <input type="submit" className={styles.submit} value="Save" onClick={() => setAction('TEACHER_ADD_ONE')} /> : (
                        <div className={styles.buttons}>
                            {clickDeletItem ? (<>
                                <button className={styles.submitDelete} onClick={() => setClickDeletItem(false)}> No</button>
                                <input type="submit" className={styles.submitUpdate} value="Yes" />

                            </>) : (<> <input type="submit" className={styles.submitDelete} value="Delete" onClick={() => setAction('TEACHER_REMOVE_ONE')} />
                                <input type="submit" className={styles.submitUpdate} value="Save" onClick={() => setAction('TEACHER_UPDATE_ONE')} /></>)}

                        </div>)}

                    {errors.name ? (
                        <div className={styles.formAnswer}>
                            <div className="text-danger">{errors.name?.message.toString}</div>
                        </div>) : null}
                </form></>)}
    </>)
}

export default TeacherForm;


