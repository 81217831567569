import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loading: false,
    error: null,
    success: null,
    loadingAllOptions: false,
    errorAllOptions: false,
    loadingAuth: false,
    errorAuth: false,

}

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        successEdit: (state, action) => {
            state.success = action.payload
        },
        loadingAllOptionsEdit: (state, action) => {
            state.loadingAllOptions = action.payload
        },
        errorAllOptionsEdit: (state, action) => {
            state.errorAllOptions = action.payload
        },
        loadingAuthEdit: (state, action) => {
            state.loadingAuth = action.payload
        },
        errorAuthEdit: (state, action) => {
            state.errorAuth = action.payload
        },

    }
})

const { actions, reducer } = statusSlice;

export default reducer;
export const { loadingEdit, errorEdit, successEdit, loadingAllOptionsEdit, errorAllOptionsEdit, loadingAuthEdit, errorAuthEdit } = actions;

