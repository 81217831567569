import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { selectAll as selectAllBoards } from "../../../redux/slices/boards.slice";
import { errorEdit, successEdit } from "../../../redux/slices/boards.slice";
import Spinner from "react-bootstrap/Spinner";
import styles from "./index.module.scss";
import Select from "react-select";

function BoardForm({ chosenBoard, isAddNew = false }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { loading, error, success } = useSelector((state) => state.boards);
  const { isAdmin } = useSelector((state) => state.personality.user);
  const { id } = useSelector((state) => state.personality.user);
  const boards = useSelector(selectAllBoards);
  const [boardOptions, setBoardOptions] = useState([]);
  const [clickDeletItem, setClickDeletItem] = useState(false);
  const [action, setAction] = useState("BOARDS_ADD_ONE");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    return () => {
      clearErrors();
      reset();
      dispatch(successEdit(null));
      dispatch(errorEdit(null));
      setIsChecked(false);
    };
  }, []);

  useEffect(() => {
    const boardsOptions = boards.map((item) => ({
      value: item.id,
      label: item.boardName,
    }));

    isChecked && setBoardOptions(boardsOptions);
  }, [boards, chosenBoard?.id, isChecked]);

  const objectYup = {
    boardName: yup
      .string()
      .required("This field is required")
      .min(2, "Name is too short - should be 2 chars minimum."),
  };

  const schema = yup.object(objectYup).required();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    control,
    resetField,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      boardName: chosenBoard?.boardName || "",
    },
  });

  const onChangeCheckbox = useCallback(() => {
    setIsChecked(!isChecked);

    if (isChecked) {
      setBoardOptions([]);
      resetField("duplicateSchedule");
    }
  }, [isChecked, resetField]);

  useEffect(() => {
    if (success) {
      reset();
    }
  }, [reset, success]);

  const onSubmit = (value) => {
    dispatch(successEdit(null));
    dispatch(errorEdit(null));
    if (action === "BOARDS_REMOVE_ONE" && !clickDeletItem) {
      setClickDeletItem(true);
      return;
    }

    if (value.duplicateSchedule) {
      const payload = {
        boardName: value.boardName,
        userId: isAdmin ? params.id : id,
      };
      dispatch({ type: "BOARDS_DUPLICATE_ONE", payload, query: value.duplicateSchedule.value});
      setIsChecked(false);
      return;
    }

    const payload = {
      boardName: value.boardName,
      userId: isAdmin ? params.id : id,
      studioName: "Studio 1",
      id: chosenBoard?.id || "",
    };
    dispatch({ type: action, payload });
  };

  return (
    <>
      {loading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {clickDeletItem ? (
            <div className={styles.confirm}>
              Are you sure you want to delete the Schedule?
              <br />
              All the classes, room on the Schedule would be deleted too
            </div>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            {clickDeletItem ? null : (
              <>
                <div className={styles.formAnswer}>
                  {error ? <div className="text-danger">{error}</div> : null}
                  {success ? (
                    <div className="text-success">{success}</div>
                  ) : null}
                </div>
                <label>Schedule name</label>{" "}
                <input {...register("boardName")} />
                {errors.boardName ? (
                  <div className={styles.formAnswer}>
                    <div className="text-danger">
                      {errors.boardName?.message}
                    </div>
                  </div>
                ) : null}
                {isAddNew && (
                  <>
                    <div className={styles.checkbox}>
                      <input
                        type="checkbox"
                        value={isChecked}
                        onChange={onChangeCheckbox}
                      />
                      <label>Copy Schedule</label>
                    </div>
                    {isChecked && (
                      <>
                        <label>Select Schedule</label>
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              name="duplicateSchedule"
                              classNamePrefix="select"
                              className={[
                                "basic-multi-select",
                                styles.scheduleSelect,
                              ].join(" ")}
                              options={boardOptions}
                            />
                          )}
                          control={control}
                          name="duplicateSchedule"
                          {...register("duplicateSchedule")}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {!chosenBoard ? (
              <input
                type="submit"
                className={styles.submit}
                value="Save"
                onClick={() => setAction("BOARDS_ADD_ONE")}
              />
            ) : (
              <div className={styles.buttons}>
                {clickDeletItem ? (
                  <>
                    <button
                      className={styles.submitDelete}
                      onClick={() => setClickDeletItem(false)}
                    >
                      {" "}
                      No
                    </button>
                    <input
                      type="submit"
                      className={styles.submitUpdate}
                      value="Yes"
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="submit"
                      className={styles.submitDelete}
                      value="Delete"
                      onClick={() => setAction("BOARDS_REMOVE_ONE")}
                    />
                    <input
                      type="submit"
                      className={styles.submitUpdate}
                      value="Save"
                      onClick={() => setAction("BOARDS_UPDATE_ONE")}
                    />
                  </>
                )}
              </div>
            )}

            {errors.name ? (
              <div className={styles.formAnswer}>
                <div className="text-danger">
                  {errors.name?.message.toString}
                </div>
              </div>
            ) : null}
          </form>
        </>
      )}
    </>
  );
}

export default BoardForm;
