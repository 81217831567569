import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalEdit } from "../../redux/slices/modal.slice";
import OneSubscription from "../OneSubscription";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import {
  profileErrorEdit,
  profileLoadingEdit,
} from "../../redux/slices/users.slice";
import styles from "./index.module.scss";
import Field from "components/Form/Field";
import PhoneField, {
  PHONE_NUMBER_SIZE,
} from "components/Form/Field/PhoneField";

function ProfileInformation() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.personality.user);
  const { email, name, isAdmin, id, studioName, phone, studioAddress } = user;
  const { chosenUser, profileLoading, profileError } = useSelector(
    (state) => state.users
  );
  const subscription = chosenUser?.subscription?.[0] || user?.subscription?.[0];
  const [edit, setEdit] = useState(false);

  const objectYup = {
    ...(edit && {
      name: yup
        .string()
        .required("This field is required")
        .min(2, "Name is too short - should be 2 chars minimum.")
        .max(255, "Name is too long - should be 255 chars maximum"),
      studioName: yup
        .string()
        .required("This field is required")
        .min(2, "Studio Name is too short - should be 2 chars minimum.")
        .max(255, "Studio Name is too long - should be 255 chars maximum"),
      email: yup
        .string()
        .email("Please provide a valid email address.")
        .required("This field is required")
        .max(255, "Email is too long - should be 255 chars maximum"),
      phone: yup
        .string()
        .required("This field is required")
        .test("len", "Please provide a valid phone number.", (value) => {
          const valueLength = value.replace(/[\s\-()+_]/g, "").length;
          return valueLength === PHONE_NUMBER_SIZE;
        }),
      studioAddress: yup
        .string()
        .required("This field is required")
        .min(2, "Studio Address is too short - should be 2 chars minimum.")
        .max(
          255,
          "Studio Address Name is too long - should be 255 chars maximum"
        ),
    }),
  };

  const schema = yup.object(objectYup).required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: isAdmin
      ? {
          name: chosenUser.name,
          email: chosenUser.email,
          studioName: chosenUser.studioName,
          phone: chosenUser.phone,
          studioAddress: chosenUser.studioAddress,
        }
      : {
          name,
          email,
          studioName,
          phone,
          studioAddress,
        },
  });

  useEffect(() => {
    return () => {
      dispatch(profileErrorEdit(null));
      dispatch(profileLoadingEdit(false));
    };
  }, [dispatch]);

  const onSubmit = (value) => {
    dispatch(profileErrorEdit(null));
    dispatch(profileLoadingEdit(false));
    if (!edit) setEdit(true);
    if (edit) {
      const { phone, ...rest } = value;
      const payload = {
        ...rest,
        phone: phone.replace(/[^0-9]/g, ""),
        userId: isAdmin ? chosenUser.id : id,
      };

      if (isAdmin) {
        dispatch({ type: "CHANGE_PROFILE_DATA", payload, id: chosenUser.id });
      }

      dispatch({ type: "CHANGE_PROFILE_DATA", payload });
      setEdit(false);
    }
  };

  return (
    <>
      {profileError && (
        <div className={styles.answerWrapper}>
          <div className="text-danger">{profileError}</div>
        </div>
      )}

      {profileLoading ? (
        <div className={styles.centerWrapper}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.profile}>
            <div className={styles.info}>
              <div className={styles.header}>
                <div className={styles.title}> Profile Information</div>
                <input
                  type="submit"
                  className={styles["submit"]}
                  value={edit ? "Save" : "Edit"}
                />
              </div>
              <div className={styles.content}>
                <Field
                  registerProps={register("name")}
                  label={"Name"}
                  error={errors.name}
                  disabled={!edit}
                  fieldClassName={false}
                />
                <Field
                  registerProps={register("studioName")}
                  label={"Studio Name"}
                  error={errors.studioName}
                  disabled={!edit}
                  fieldClassName={false}
                />
                <Field
                  registerProps={register("email")}
                  label={"Email"}
                  error={errors.email}
                  type={"email"}
                  disabled
                />
                <PhoneField
                  registerProps={register("phone")}
                  label={"Phone"}
                  error={errors.phone}
                  disabled={!edit}
                />
                <Field
                  registerProps={register("studioAddress")}
                  label={"Studio Address"}
                  error={errors.studioAddress}
                  disabled={!edit}
                  fieldClassName={false}
                />
              </div>
            </div>
            <div className={styles.subscription}>
              {subscription && (
                <OneSubscription
                  data={{
                    stickersNumber: subscription.stickersNumber,
                    studiosNumber: subscription.studiosNumber,
                    payment: subscription.validUntil,
                    info: true,
                  }}
                />
              )}
            </div>
          </form>
          {!subscription && (
            <button
              className={styles["submit"]}
              onClick={() => dispatch(modalEdit("Subscriptions"))}
            >
              Add Subscription
            </button>
          )}
        </>
      )}
    </>
  );
}

export default ProfileInformation;
