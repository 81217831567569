import { takeLeading, call, put } from "redux-saga/effects";
import {
  subscriptionsSetAll,
  subscriptionAddOne,
  subscriptionUpdateOne,
  subscriptionRemoveOne,
  removeChosenSubscription,
  loadingEdit,
  errorEdit,
  successEdit,
} from "../slices/subscriptions.slice.js";
import {
  loadingEdit as loadingEditMain,
  errorEdit as errorEditMain,
} from "../slices/status.slice.js";
import { editChosenUser } from "../slices/users.slice.js";
import { subscriptionsService, usersService } from "../../services";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";
import { history } from "../../App";
import { updateUser } from "../slices/users.slice.js";

export function* getAll(action) {
  yield put(loadingEditMain(true));
  try {
    const response = yield call(subscriptionsService.getAll, action.payload);
    yield put(subscriptionsSetAll(response.data));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(
        errorEditMain("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(loadingEditMain(false));
  }
}

function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(subscriptionsService.create, action.payload);
    yield put(subscriptionAddOne(response.data.subscriptionPlan));
    yield put(successEdit("New subscription has been created"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(subscriptionsService.update, action.payload);
    yield put(subscriptionUpdateOne(action.payload));
    yield put(removeChosenSubscription());
    yield put(contentEdit("The subscription has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(subscriptionsService.delete, {
      subscriptionId: action.payload.id,
    });
    yield put(subscriptionRemoveOne(action.payload.id));
    yield put(removeChosenSubscription());
    yield put(contentEdit("The subscription has been deleted"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* buySubscription(action) {
  yield put(loadingEditMain(true));
  try {
    const response = yield call(
      subscriptionsService.buySubscription,
      action.payload
    );
    yield history.push(response.data);
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(
        errorEditMain("Something has gone wrong. Please try again later")
      );
    }
    yield put(loadingEditMain(false));
  }
}

function* createPaidSubscription(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(
      usersService.createPaidSubscription,
      action.payload
    );
    yield put(
      editChosenUser({
        subscription: response.data?.subscription,
      })
    );
    yield put(
      updateUser({
        subscription: response.data?.subscription,
        id: action.payload.userId,
      })
    );

    yield put(successEdit("New subscription has been created"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updatePaidSubscription(action) {
  yield put(loadingEdit(true));
  try {
     yield call(
      usersService.updatePaidSubscription,
      action.payload
    );
    yield put(
      editChosenUser({
        subscription: action.payload,
      })
    );
    yield put(removeChosenSubscription());
    yield put(
      updateUser({
        subscription: action.payload,
        id: action.payload.userId,
      })
    );
    yield put(contentEdit("The subscription has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* deletePaidSubscription(action) {
  yield put(loadingEdit(true));
  try {
    yield call(usersService.deletePaidSubscription, {
      userId: action.payload.userId,
    });
    yield put(
      editChosenUser({
        subscription: null,
      })
    );
    yield put(
      updateUser({
        subscription: null,
        id: action.payload.userId,
      })
    );
    yield put(removeChosenSubscription());
    yield put(contentEdit("The subscription has been deleted"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchSubscriptionsSaga() {
  yield takeLeading("SUBSCRIPTIONS_GET_ALL", getAll);
  yield takeLeading("SUBSCRIPTION_ADD_ONE", addOne);
  yield takeLeading("SUBSCRIPTION_UPDATE_ONE", updateOne);
  yield takeLeading("SUBSCRIPTION_REMOVE_ONE", removeOne);
  yield takeLeading("BUY_SUBSCRIPTION", buySubscription);
  yield takeLeading("CREATE_PAID_SUBSCRIPTION", createPaidSubscription);
  yield takeLeading("UPDATE_PAID_SUBSCRIPTION", updatePaidSubscription);
  yield takeLeading("DELETE_PAID_SUBSCRIPTION", deletePaidSubscription);
}
