import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from "../FormWrapper/index.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { errorEdit, successEdit, sentCodeEdit } from '../../../redux/slices/auth.slice';
import Field from "../Field";
import FormWrapper from "../FormWrapper";
import { AiOutlineArrowLeft } from 'react-icons/ai';

const ResetPasswordForm = () => {
  const { error, success, sentCode } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  
const resetPasswordFormYup = {
  email: yup.string()
    .email("Please provide a valid email address.")
    .required("This field is required"),
    ...(sentCode && {
      code: yup.string()
      .required("This field is required")
    })
};

  const { register, handleSubmit, formState: { errors }, clearErrors, reset, getValues, setValue } = useForm({
    resolver: yupResolver(yup.object(resetPasswordFormYup).required())
  });

  const onSubmit = (value) => {
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    if(!sentCode) {
      dispatch({ type: "SEND_VERIFICATION_CODE", payload: { email: value.email, type: 2 } });
      return;
    }
  
    dispatch({ type: 'RESET_PASSWORD', payload: { ...value, code: value.code?.trim() } });
  };

  const resendCode = () => {
    dispatch({ type: "SEND_VERIFICATION_CODE", payload: { email: getValues().email, type: 2 } });
  }

  const goBack = () => {
    setValue('code', '');
    dispatch(errorEdit(null));
    dispatch(successEdit(null));
    dispatch(sentCodeEdit(false))
  }

  return (
    <FormWrapper clearErrors={clearErrors} reset={reset}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles["auth-form"]}>
        <div className={styles[`auth-form__answer`]}>
          {error && <div className="text-danger">{error}</div>}
          {success && <div className="text-success">{success}</div>}
        </div>
        <div className={styles.inputsWrapper}>
          {sentCode ? (
            <div className={styles.code}>
              <div className={styles.goBack} onClick={goBack}>
                  <AiOutlineArrowLeft />
                  <span>Go Back</span>
              </div>
              <div className={styles.wrapperCode}>
                  <label>Enter code below</label>
                  <input {...register('code')} />
                  {errors['code'] && (
                    <div className={styles[`auth-form__answer`]}>
                      <div className="text-danger">{errors['code']?.message}</div>
                    </div>
                  )}
            </div>
            <div className={styles.resentCode} onClick={resendCode}>Resend code</div>
          </div>) : (
          <Field registerProps={register('email')} label={'Email'} error={errors.email} type={'email'}/>
          )}
        </div>
        <input type="submit" className={styles["submit"]} value={'Reset password'} />
      </form>
    </FormWrapper>
  )
}

export default ResetPasswordForm;