import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isAuthorized: false,
    loading: false,
    error: null,
    success: null,
    loadingMainPage: true,
    sentCode: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authEdit: (state, action) => {
            state.isAuthorized = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        successEdit: (state, action) => {
            state.success = action.payload
        },
        sentCodeEdit: (state, action) => {
            state.sentCode = action.payload
        },

        loadingMainPageEdit: (state, action) => {
            state.loadingMainPage = action.payload
        },


    }
})

const { actions, reducer } = authSlice;

export default reducer;
export const { authEdit, loadingEdit, errorEdit, successEdit, setUser, loadingMainPageEdit, sentCodeEdit, } = actions;


