import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import adaptivePlugin from '@fullcalendar/adaptive'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import moment from 'moment/moment'
import { Button, ToggleButton, OverlayTrigger, Tooltip  } from "react-bootstrap";
import { TIME_SLOTS, timeSlotInterval, weekDays } from "../../constants/variables";
import { CardsPullMemo } from "../FiltersSidebar";
import ClassCard from "../ClassCard";
import { ReactComponent as FilterIcon } from "../../assets/img/icons/filterList.svg";
import { ReactComponent as ScheduleIcon } from "../../assets/img/icons/schedule.svg";
import { DialogModal } from "../FilterDialogModal";
import { selectAll as selectAllBoards } from '../../redux/slices/boards.slice';
import { addChosenStudio, selectAll as selectAllStudio } from '../../redux/slices/studio.slice';
import Select, { components } from "react-select";
import "./index.scss"
import { useParams } from "react-router-dom";
import { addChosenSticker } from "../../redux/slices/stickers.slice";
import { modalEdit } from "../../redux/slices/modal.slice";
import styles from './index.module.scss';
import { addChosenBoard } from '../../redux/slices/boards.slice.js'
import { filterSetAll } from "../../redux/slices/scheduleFilters.slice";
import { getBackgroundColor } from "../../utilities";
import { resetHistory, resetNotificationHistory } from '../../redux/slices/events.slice';
import HistoryNotification from '../HistoryNotification';
import WeekDaysHeader from './WeekDaysHeader';
import { ReactComponent as ZoomInIcon } from "../../assets/img/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/img/icons/zoom-out.svg";


const Control = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            <ScheduleIcon className="ms-2" />
            {children}
        </components.Control>
    );
};


const createClassInstance = (data) => {
    const stickersArr = data.studios.map((item) => {
        return item.scheduleSticker
    }).flat();

    return stickersArr.map((item, index) => {
        return {
            id: item.id,
            resourceId: item.studioId,
            className: item.className,
            title: item.className || "None",
            length: item.length,
            start: moment(item.start, 'HH:mm:ss').format(),
            end: moment(item.start, 'HH:mm:ss').add(item.length, "hours").format(),
            background: getBackgroundColor(item),
            styles: item.styles,
            levels: item.levels,
            teachers: item.teachers,
            teachersId: item.teachersId,
            levelsId: item.levelsId,
        }
    })

}

export default function ScheduleTable({board, setBoard}) {
    const storedFilters = useSelector((state) => state.scheduleFilters.filters);
    const storedEvents = useSelector((state) => state.events.data);
    const eventsHistory = useSelector((state) => state.events.eventsHistory);

    const { isAdmin, id } = useSelector(state => state.personality.user);
    const { error, loadingErrors } = useSelector(state => state.events);
    const allStudio = useSelector(selectAllStudio)
    const boards = useSelector(selectAllBoards);
    const params = useParams()
    const dispatch = useDispatch();

    const [currentEvents, setCurrentEvents] = useState([]);
    const { chosenBoard } = useSelector(state => state.boards);
    const [boardOptions, setBoardOptions] = useState([]);
    const calendarRef = useRef(null)

    const [filterModal, showFilterModal] = useState(false);

    const studios = allStudio.map(item => ({ ...item, title: item.studioName }));
    const [weekday, setWeekday] = useState(null);
    const [isOpenHistoryNotification, setIsOpenHistoryNotification] = useState(false)

    const activeFilters = useMemo(() => {
        let activeFilters = {};
        if (storedFilters) {
            for (let [key, value] of Object.entries(storedFilters)) {
                activeFilters[key] = value.filter((item) => item?.checked === true)
                if (activeFilters[key].length === 0) {
                    delete activeFilters[key]
                }
            }
        }

        return activeFilters
    }, [storedFilters])


    useEffect(() => {
        let defaultIndex = 0;
        const boardsOptions = boards.map((item, index) => {
            if (chosenBoard?.id === item.id) defaultIndex = index;
            return {
                ...item,
                value: item.id,
                label: item.boardName
            }
        })
        setBoardOptions(boardsOptions)
        setBoard(boardsOptions[defaultIndex]);
        dispatch(addChosenBoard(boardsOptions[defaultIndex]));

    }, [boards, chosenBoard?.id, dispatch]);


    useEffect(() => {
        if (board?.boardDays) {
            for (const item of Object.keys(board.boardDays)) {
                if (!weekDays.includes(item)) continue;
                if (board.boardDays[item].end || board.boardDays[item].start) {
                    if (!weekday) setWeekday(item);
                    break;
                }
            }
        }


    }, [board, weekday]);

    useEffect(() => {
        dispatch(resetHistory());
        dispatch(resetNotificationHistory());
        setIsOpenHistoryNotification(false);
        if (board?.id) {
            dispatch({
                type: "STICKERS_GET_ALL",
                payload: {
                    userId: isAdmin ? params.id : id,
                    boardId: board?.id
                }
            })
        }


    }, [board?.id]);

    useEffect(() => {
        if (weekday) dispatch(resetHistory());
    }, [dispatch, weekday]);

    useEffect(() => {
        if (board?.boardDays || boardOptions[0]?.id) {
            if (!weekday) return
            dispatch({
                type: "EVENTS_SET_ALL",
                query: {
                    dayOfTheWeek: weekday?.[0].toUpperCase() + weekday?.slice(1),
                    userId: isAdmin ? params.id : id,
                    boardId: board.value || boardOptions[0].id
                }
            })

        }
    }, [weekday, board]);


    useEffect(() => {
        let newEvents = [];
        if (storedEvents) {
            newEvents = createClassInstance(storedEvents);
        }

        if (storedFilters && storedEvents) {
            const newEventsInstance = createClassInstance(storedEvents);
            newEvents = newEventsInstance.filter((item) => {
                let flag = true
                for (let key of Object.keys(activeFilters)) {
                    if (key === "length") {
                        flag = activeFilters[key].find(el => el.value === item[key]);
                        return !!flag
                    } else {
                        flag = item[key] !== null && !!(activeFilters[key].find(el => item[key].includes(el.value)));
                    }
                    if (flag === false) return !!flag
                }
                return true
            });
        }

        setCurrentEvents(newEvents)
    }, [storedFilters, storedEvents, activeFilters])

    const handleEventReceive = (eventInfo) => {
        if (isOpenHistoryNotification) return;
        const data = JSON.parse(eventInfo.draggedEl.getAttribute('data-event')) // receive data from sidebar class

        const body = {
            length: data.length,
            start: moment(eventInfo.date).format("HH:mm"),
            end: moment(eventInfo.date).add(data.length, "hours").format("HH:mm"),
            className: data.className,
            styles: data.styles,
            teachers: data.teachers,
            levels: data.levels,
            studioId: eventInfo.resource.id,
            dayOfTheWeek: weekday?.[0].toUpperCase() + weekday?.slice(1),
            userId: isAdmin ? params.id : id,
            teachersId: data.teachersId,
            levelsId: data.levelsId,
            boardId: board.id,
            stickerId: data.id,
            isReusable: data.isReusable
        }

        dispatch({
            type: "EVENTS_ADD_ONE",
            payload:
                { body, deleteSticker: !data.isReusable, sticker: data }
        })

        return false
    }

    const createEventBody = (event, oldEvent = null) => {
        return {
            scheduleId: event.id,
            id: event.id,
            length: event.extendedProps.length,
            className: event.extendedProps.className || event.title,
            start: moment(event.start).format("HH:mm"),
            end: moment(event.start).add(event.extendedProps.length, "hours").format("HH:mm"),
            styles: event.extendedProps.styles,
            levels: event.extendedProps.levels,
            teachers: event.extendedProps.teachers,
            teachersId: event.extendedProps.teachersId,
            levelsId: event.extendedProps.levelsId,
            studioId: event.getResources()[0].id,
            prevStudioId: oldEvent ? oldEvent.getResources()[0].id : event.getResources()[0].id,
            userId: isAdmin ? params.id : id,
            isReusable: false,
            dayOfTheWeek: weekday?.[0].toUpperCase() + weekday?.slice(1),
            boardId: board.id
        }
    }


    const handleEventClick = ({ event }) => {
        const item = createEventBody(event)
        dispatch(addChosenSticker(item));
        dispatch(modalEdit('Events'));
    }
    const showFilters = useCallback(() => {
        showFilterModal(!filterModal)
    }, [filterModal])

    const handleEvents = ({ event, oldEvent }) => {
        const body = createEventBody(event, oldEvent);
        let prevEvent = createEventBody(oldEvent);
        prevEvent = { ...prevEvent, studioId: oldEvent.getResources()[0].id, prevStudioId: event.getResources()[0].id }

        dispatch({ type: "EVENTS_UPDATE_ONE", payload: { body, prevEvent } })
    }

    const resourceLabels = (info) => {
        const editStudio = () => {
            dispatch(addChosenStudio({
                "id": info.resource.id,
                "studioName": info.resource.title,
                "boardId": board.value || boardOptions[0].id,
                "userId": isAdmin ? params.id : id
            }));

            dispatch(modalEdit('Studio'))
        }

        const addStudio = (i) => {
            const currentIndex = studios.findIndex(studio => {
                return studio.id === info.resource.id
            })
            let index = currentIndex + i;
            if (!i) index = currentIndex;
            dispatch(addChosenBoard({ ...board, indexNewStudio: index }));
            dispatch(modalEdit('Studio'));
        }


        return (
            <div className="resourceLabelWrapper">
                <Button className="text-decoration-none p-0 me-2" size="lg" variant="link"
                    onClick={() => addStudio()}>+</Button>
                <div onClick={editStudio}>{info.resource.title}</div>
                <Button className="text-decoration-none p-0 ms-2" size="lg" variant="link"
                    onClick={() => addStudio(+1)}>+</Button>
            </div>

        )
    }
    const isEventOverDiv = (x, y) => {
        const external_events = document.getElementById('events-pull');
        const domRect = external_events.getBoundingClientRect(); // get div coordinates
        return x >= domRect.left
            && y >= domRect.top
            && x <= domRect.right
            && y <= domRect.bottom;

    }
    const onEventDragStop = ({ event, jsEvent }) => {
        if (isOpenHistoryNotification) return;
        if (isEventOverDiv(jsEvent.clientX, jsEvent.clientY, jsEvent)) {
            const body = createEventBody(event);
            try {
                dispatch({
                    type: "EVENTS_REMOVE_ONE",
                    payload: { body, addSticker: true }
                })
                event.remove()
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    const onFilterRemove = useCallback((filterItem) => {
        const updatedFilters = JSON.parse(JSON.stringify(storedFilters))
        updatedFilters[filterItem.entity] = updatedFilters[filterItem.entity].map((item) => {
            if (item.value === filterItem.value) {
                item.checked = false
            }
            return item
        })
        dispatch(filterSetAll(updatedFilters))
    }, [dispatch, storedFilters])
    
    const filtersList = () => {
        let filterList = Object.values(activeFilters).flat();
        return (
            <div className="d-flex flex-wrap align-items-center">
                <span className="me-2 mb-1 fw-bold fs-6">Filter ({filterList.length})</span>
                {filterList.map((item, index) => {
                    return (
                        <div key={`event-filters-${index}`} className="selectedOption">
                            <span className="text-capitalize me-1">{item.entity}:</span>
                            {item.label}
                            <div name={item.value} onClick={() => onFilterRemove(item)}
                                className="closeButton">X
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }



    const onUndoHandle = () => {
        if (eventsHistory.length === 0) return
        const { eventBody, reversedType, addSticker, deleteSticker, sticker } = eventsHistory[eventsHistory.length - 1]
        dispatch({
            type: reversedType,
            payload: {
                body: eventBody,
                isUndo: true,
                addSticker,
                deleteSticker,
                sticker,
            }
        })

    }

    const [slotTime, setSlotTime] = useState(TIME_SLOTS.MINUTES_15);
    const onChangeScale = useCallback((event) => {
        setSlotTime(event.currentTarget.value === TIME_SLOTS.MINUTES_15 ? TIME_SLOTS.MINUTES_5 : TIME_SLOTS.MINUTES_15);
    }, [])

    return (
        <div className="demo-app">
            {board ? (
                <>
                    <div className="app-main">
                        <div className="d-flex  my-3 justify-content-between align-items-center position-relative flex-wrap">
                            <Select
                                components={{ Control }}
                                className="schedulesSelectButton my-1 mx-1"
                                placeholder={"Schedules"}
                                classNamePrefix="schedulesSelect"
                                defaultValue={boardOptions[0]}
                                value={board}
                                onChange={(value) => {
                                    setBoard(value);
                                    dispatch(addChosenBoard(value));
                                }}
                                options={boardOptions}
                            />
                            <div className={styles.buttons}>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Change the time precision of class-cards operations</Tooltip>}>
                                    <button className={[styles.editButton, styles.scaleButton].join(' ')} onClick={onChangeScale} value={slotTime}>
                                        {slotTime === TIME_SLOTS.MINUTES_15 ? <ZoomInIcon /> : <ZoomOutIcon />}
                                        <p>Zoom {`${slotTime === TIME_SLOTS.MINUTES_15 ? 'into' : 'out'}`} <b>{`${slotTime === TIME_SLOTS.MINUTES_15 ? timeSlotInterval.minutes_5 : timeSlotInterval.minutes_15}`} minutes</b> interval</p>
                                    </button>
                                </OverlayTrigger>
                                <ToggleButton
                                    className="filterButton greyButton my-1 mx-1"
                                    id="toggle-check"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={filterModal}
                                    value="filterModal"
                                    onChange={showFilters}
                                >
                                    <FilterIcon className="me-2" /> Filter
                                </ToggleButton>
                                <button className={styles.editButton} onClick={loadingErrors ? null : (
                                    () => {
                                        setIsOpenHistoryNotification(true);
                                        dispatch({
                                            type: "GET_ERRORS",
                                            payload:
                                            {
                                                userId: isAdmin ? params.id : id,
                                                boardId: board.id,
                                                dayOfTheWeek: weekday?.[0].toUpperCase() + weekday?.slice(1)
                                            }
                                        })

                                    }
                                )}>
                                    Check schedule
                                </button>
                            </div>
                        </div>
                        {Object.keys(activeFilters).length > 0 && filtersList()}
                        <DialogModal isOpen={filterModal} onClose={showFilters} />
                        <WeekDaysHeader 
                            board={board} 
                            onUndoHandle={onUndoHandle} 
                            eventsHistory={eventsHistory} 
                            setWeekday={setWeekday} 
                            weekday={weekday} 
                        />
                        <div ref={calendarRef} className={studios.length > 3 ? styles.calendar32 : styles.calendar21}>
                            <FullCalendar
                                aspectRatio={1.9}
                                slotMinTime={storedEvents?.boardDays[weekday?.toLowerCase()]?.start || "06:00"}
                                slotMaxTime={storedEvents?.boardDays[weekday?.toLowerCase()]?.end || "23:00"}
                                schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
                                events={currentEvents}
                                resources={studios}
                                resourceOrder={null}
                                headerToolbar={false}
                                plugins={[resourceTimeGridPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin, adaptivePlugin]}
                                initialView='resourceTimeGrid'
                                resourceLabelContent={resourceLabels}
                                defaultTimedEventDuration={'01:00'}
                                editable={true}
                                selectable={false}
                                droppable={true}
                                dragRevertDuration={0}
                                slotDuration={slotTime}
                                selectMirror={true}
                                dayMaxEvents={true}
                                allDaySlot={false}
                                drop={handleEventReceive}
                                slotLabelInterval={'01:00'}
                                slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }} // lower level of text
                                eventTimeFormat={{
                                    hour: "numeric",
                                    minute: "2-digit",
                                    meridiem: "lowercase"
                                }}
                                eventContent={ClassCard} // custom render function
                                eventClick={handleEventClick}
                                eventDurationEditable={false}
                                eventOverlap={false}
                                eventDragStop={onEventDragStop}
                                eventChange={handleEvents}
                                    eventColor={'#f2f2f2'}
                                eventReceive={(info) => {
                                    if (error /* === 'Not available to create more stickers in your subscription plan' */) {
                                        info.revert()
                                    }
                                }}

                            />
                        </div>
                    </div>
                    {isOpenHistoryNotification ? <HistoryNotification setIsOpenHistoryNotification={setIsOpenHistoryNotification} /> : <CardsPullMemo board={board} weekday={weekday} refProps={calendarRef} setSlotTime={setSlotTime}/>}

                </>
            ) : (
                <h2 className="m-auto">
                    Click ‘Add New’ to Create Your First Schedule
                </h2>
            )}
        </div>
    )
}



