import { takeLeading, call, put } from "redux-saga/effects";
import {
  eventsSetAll,
  eventAddOne,
  eventUpdateOne,
  eventRemoveOne,
  addChosenEvent,
  removeChosenEvent,
  addOneToHistory,
  removeOneFromHistory,
  changeHistory,
  removeOneToNotificationHistory,
  changeNotificationHistory,
  setScheduleErrors,
} from "../slices/events.slice";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";
import {
  loadingEdit,
  errorEdit,
  successEdit,
  loadingErrorsEdit,
} from "../slices/events.slice";
import { scheduleService, stickersService } from "../../services";
import { studioSetAll } from "../slices/studio.slice";
import { toast } from "react-toastify";
import {
  stickerAddOne,
  removeChosenSticker,
  stickerRemoveOne,
} from "../slices/stickers.slice";

export function* getAll(action) {
  yield put(loadingEdit(true));

  try {
    const response = yield call(
      scheduleService.getOne,
      action.payload,
      action.query
    );
    yield put(eventsSetAll(response.data));
    yield put(studioSetAll(response.data.studios));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
      yield put(contentEdit(error.response.data.message));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
      yield put(
        contentEdit("Something has gone wrong. Please try again later")
      );
      yield put(modalEdit("Confirm"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(scheduleService.update, action.payload.body);
    const { sameLevels, sameTeacher, teacherAvailableTime } =
      response.data || {};
    if (
      sameLevels !== "ok" ||
      sameTeacher !== "ok" ||
      teacherAvailableTime !== "ok"
    ) {
      yield put(
        changeNotificationHistory({
          ...action.payload.body,
          notification: response.data.message,
        })
      );
      Object.values({
        sameLevels,
        sameTeacher,
        teacherAvailableTime,
      }).forEach((item) => {
        if (item !== "ок") toast.warn(item);
      });
    } else {
      yield put(removeOneToNotificationHistory(action.payload.body.id));
    }
    if (!action.payload.isUndo) {
      const event = {
        reversedType: action.type,
        eventBody: action.payload.prevEvent,
      };
      yield put(addOneToHistory(event));
      yield put(modalEdit(null));
    } else {
      yield put(removeOneFromHistory());
    }
    yield put(eventUpdateOne(action.payload.body));
    yield put(addChosenEvent(action.payload.body));

    yield put(removeChosenSticker());
    yield put(modalEdit(null));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
      yield put(contentEdit(error.response.data.message));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
      yield put(
        contentEdit("Something has gone wrong. Please try again later")
      );
      yield put(modalEdit("Confirm"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* addOne(action) {
  yield put(loadingEdit(true));
  const { sticker, deleteSticker } = action.payload;
  try {
    const response = yield call(scheduleService.create, action.payload.body);
    yield put(eventAddOne({ ...response.data.schedule }));
    if (deleteSticker) {
      yield put(stickerRemoveOne(sticker.id));
    }

    yield put(successEdit("New event has created"));

    const { sameLevels, sameTeacher, teacherAvailableTime } =
      response.data || {};
    if (
      sameLevels !== "ok" ||
      sameTeacher !== "ok" ||
      teacherAvailableTime !== "ok"
    ) {
      yield put(
        changeNotificationHistory({
          ...action.payload.body,
          notification: response.data.message,
        })
      );
      Object.values({
        sameLevels,
        sameTeacher,
        teacherAvailableTime,
      }).forEach((item) => {
        if (item !== "ок") toast.warn(item);
      });
    }
    // if it's not undo feature add to history
    if (!action.payload.isUndo) {
      const event = {
        reversedType: "EVENTS_REMOVE_ONE",
        eventBody: response.data.schedule,
        addSticker: !sticker.isReusable,
        sticker: sticker,
      };
      yield put(addOneToHistory(event));
    } else {
      yield put(
        changeHistory({
          newId: response.data.schedule.id,
          oldId: action.payload.body.id,
        })
      );
      yield put(removeOneFromHistory());
    }
  } catch (error) {
    if (
      error.response.data.message ===
      "Not available to create more stickers in your subscription plan"
    ) {
      yield put(
        contentEdit(
          "Not available to create more stickers in your subscription plan"
        )
      );
      yield put(modalEdit("Confirm"));
    }

    if (error.response) {
      yield put(errorEdit(error.response.data.message));
      yield put(contentEdit(error.response.data.message));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
      yield put(
        contentEdit("Something has gone wrong. Please try again later")
      );
      yield put(modalEdit("Confirm"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  const { body, addSticker, deleteSticker } = action.payload;
  try {
    const response = yield call(scheduleService.delete, { userId: body.userId, scheduleId: body.id });
    yield put(eventRemoveOne({ studioId: body.studioId, id: body.id }));
    yield put(removeChosenEvent(action.payload.id));
    yield put(removeOneToNotificationHistory(body.id))
    let sticker = {}
    if (addSticker) {
      const responseSticker = yield call(stickersService.create, action.payload.body);
      yield put(stickerAddOne(responseSticker.data.sticker));
      sticker = yield responseSticker.data.sticker;
    }


    if (!action.payload.isUndo) {
      const event = {
        reversedType: 'EVENTS_ADD_ONE',
        eventBody: body,
        deleteSticker: deleteSticker,
        sticker
      }
      yield put(addOneToHistory(event))
    } else {
      yield put(changeHistory({ newId: sticker.id, oldId: action.payload.sticker.id }))
      yield put(removeOneFromHistory())
    }
    yield put(modalEdit(null))
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
      yield put(contentEdit(error.response.data.message));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
      yield put(
        contentEdit("Something has gone wrong. Please try again later")
      );
      yield put(modalEdit("Confirm"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export function* getErrors(action) {
  yield put(loadingErrorsEdit(true));
  try {
    const response = yield call(scheduleService.getErrors, action.payload);
    yield put(setScheduleErrors(response.data));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
      yield put(contentEdit(error.response.data.message));
      yield put(modalEdit("Confirm"));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
      yield put(
        contentEdit("Something has gone wrong. Please try again later")
      );
      yield put(modalEdit("Confirm"));
    }
  } finally {
    yield put(loadingErrorsEdit(false));
  }
}
export default function* watchEventsSaga() {
  yield takeLeading("EVENTS_SET_ALL", getAll);
  yield takeLeading("EVENTS_ADD_ONE", addOne);
  yield takeLeading("EVENTS_UPDATE_ONE", updateOne);
  yield takeLeading("EVENTS_REMOVE_ONE", removeOne);
  yield takeLeading("GET_ERRORS", getErrors);
}
