import React, { useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { modalEdit } from '../../redux/slices/modal.slice';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { burger } from '../../assets/img';
import styles from './index.module.scss';
import LevelForm from '../Form/LevelForm';
import TeacherForm from '../Form/TeacherForm';
import StickerForm from '../Form/StickerForm';
import StyleForm from '../Form/StyleForm';
import ChangePasswordForm from 'components/Form/ChangePasswordForm';
import SubscriptionForm from '../Form/SubscriptionForm';
import Menu from '../Menu';
import Modal from '../Modal';
import BoardForm from "../Form/BoardForm";
import RoomForm from '../Form/RoomForm';
import ModalConfirm from '../ModalConfirm';
import DaysOfWeekForm from '../Form/DaysOfWeekForm';

const Wrapper = ({ BaseComponent }) => {
    const { modal } = useSelector(state => state.modal);

    const [isOpenMenu, changeIsOpenMenu] = useState(false);
    const [isDefaultScreen, changeIsDefaultScreen] = useState(true);
    const dispatch = useDispatch();
    const { chosenBoard, chosenDay } = useSelector(state => state.boards);


    const getModal = () => {
        switch (modal) {
            case null: break;
            case "Teachers":
                return <Modal entity="Teacher" >
                    <TeacherForm />
                </Modal>
            case "Styles":
                return <Modal entity="Style" >
                    <StyleForm />
                </Modal>
            case "Levels":
                return <Modal entity="Level" >
                    <LevelForm />
                </Modal>
            case "Subscriptions":
                return <Modal entity="Subscription" >
                    <SubscriptionForm />
                </Modal>
            case "Stickers":
                return <Modal entity="Class" >
                    <StickerForm />
                </Modal>
            case "Events":
                return <Modal entity="Event" title='Edit the class' >
                    <StickerForm isEvent={true} />
                </Modal>
            case "ChangePassword":
                return <Modal title="Change Password" >
                    <ChangePasswordForm />
                </Modal>
            case "BoardsEdit":
                return <Modal entity="Board" title='Edit the Schedule'>
                    <BoardForm chosenBoard={chosenBoard} />
                </Modal>
            case "BoardsAdd":
                return <Modal entity="Board" title='Add a new Schedule'  >
                    <BoardForm isAddNew />
                </Modal>
            case "Studio":
                return <Modal entity="Room" >
                    <RoomForm />
                </Modal>
            case "Day":
                return <Modal entity="Day" title={chosenDay ? 'Edit  Day' : 'Add  new day'} >
                    <DaysOfWeekForm />
                </Modal>
            case "Confirm":
                return <ModalConfirm />

            default:
                dispatch(modalEdit(null))
        }
    }

    return (
        <div className={styles.wrapper} onClick={(e) => {
            if (e.target.getAttribute('data-menu') === 'burger') return
            changeIsOpenMenu(false)
        }}>
            {getModal()}
            <Container className="py-0" fluid>
                <Row className="py-0">
                    {isDefaultScreen ? (
                        <Col className="px-0" sm={0} lg={2} style={{ position: 'relative' }}>
                            <Menu isOpenMenu={isOpenMenu} changeIsOpenMenu={changeIsOpenMenu} />
                        </Col>) : null}

                    <Col className="px-0" sm={12} lg={isDefaultScreen ? 10 : 12} >
                        <div className={styles.main} >
                            {!isDefaultScreen ? null : (<div className={styles.menuBurger} onClick={() => {
                                changeIsOpenMenu((isOpenMenu) => !isOpenMenu)
                            }}>
                                <img src={burger} alt='menu' data-menu='burger' />
                            </div>)}
                            <BaseComponent isDefaultScreen={isDefaultScreen} changeIsDefaultScreen={changeIsDefaultScreen} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Wrapper
