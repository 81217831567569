import { RequestService } from "./RequestService";

export class CommonService extends RequestService {

    entity;

    constructor(name_entity) {
        super(`${name_entity}s`);
        this.entity = name_entity;
    }


    getAll = (queryParams) => this.request('get', `get-all-${this.entity}s`, '', '', queryParams);
    getOne = (id, queryParams) => this.request('get', `get-${this.entity}`, id, '', queryParams);
    delete = (body, queryParams) => this.request('delete', `delete-${this.entity}`, '', body, queryParams);
    update = (body) => this.request('patch', `update-${this.entity}`, '', body);
    create = (body) => this.request('post', `create-${this.entity}`, '', body);
    duplicate = (body, id) => this.request('post', `duplicate`, id, body);
}
