import React from "react";

const FilterLabel = ({filters, filterName, onRemoveHandle}) => (
  <>
    {filters[filterName].length > 0 &&
      <div className="d-flex flex-wrap">
        <span className="me-1">{filterName}:</span>
        {filters[filterName].map((item, index) => {
          return (
            <div key={`filters-level-${index}`} className="selectedOption">
              {item.label}
              <div name={item.value} onClick={onRemoveHandle(filterName)}
                className="closeButton">X
              </div>
            </div>
          )
        })}
      </div>
    }
  </>
)

export default FilterLabel;