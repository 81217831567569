import { CommonService } from './CommonService';

export class ScheduleService extends CommonService {

    entity;

    constructor(name_entity) {
        super(`${name_entity}`);
        this.entity = name_entity;
    }

    getErrors = (queryParams) => this.request('get', `get-errors`, '', '', queryParams);
}
