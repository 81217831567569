import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const boardsAdapter = createEntityAdapter();

export const initialState = boardsAdapter.getInitialState({
    chosenBoard: null,
    chosenDay: null,
    activeDays: null,
    loading: false,
    error: null,
    success: null,
});

const boardsSlice = createSlice({
    name: 'boards',
    initialState,
    reducers: {
        boardsSetAll: (state, action) => {
            boardsAdapter.setAll(state, action.payload);
        },
        boardAddOne: (state, action) => {
            boardsAdapter.addOne(state, action.payload);
        },
        boardUpdateOne: (state, action) => {
            boardsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
        },
        boardRemoveOne: (state, action) => {
            boardsAdapter.removeOne(state, action.payload);
        },
        addChosenBoard: (state, action) => {
            state.chosenBoard = action.payload
        },
        removeChosenBoard: (state, action) => {
            state.chosenBoard = null
        },
        addChosenDay: (state, action) => {
            state.chosenDay = action.payload
        },
        removeChosenDay: (state, action) => {
            state.chosenDay = null
        },
        addActiveDays: (state, action) => {
            state.activeDays = action.payload
        },
        removeActiveDays: (state, action) => {
            state.activeDays = null
        },
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        successEdit: (state, action) => {
            state.success = action.payload
        },


    },
})

const { actions, reducer } = boardsSlice;
export const { boardsSetAll, boardAddOne, boardUpdateOne, boardRemoveOne, addChosenBoard, removeChosenBoard,
    addChosenDay, removeChosenDay, addActiveDays, removeActiveDays,
    loadingEdit, errorEdit, successEdit
} = actions;
export default reducer;
export const { selectAll } = boardsAdapter.getSelectors(state => state.boards);

