export const getTimeOptions = () => {
    const options = [{ value: '00:00', label: '12 AM' }];
    for (let i = 1; i < 24; i++) {
        let label = i < 12 ? `${i} AM` : `${i - 12} PM`;
        let value = '';
        if (i < 10) value = `0${i}:00`;
        if (i > 9 && i < 12) value = `${i}:00`;
        if (i > 11) value = `${i}:00`;
        if (i === 12) {
            label = `12 PM`;
            value = '12:00'
        }
        options.push({ value: value, label: label })
    }
    return options;
}



export const getDefaultTimeValue = (start, end, options) => {
    let defaultStartTime = [];
    let defaultEndTime = [];
    let count = 0;
    for (let i = 0; i < options.length; i++) {
        if (count > 1) break
        if (options[i].value === start) {
            defaultStartTime = options[i];
            count++;

        }
        if (options[i].value === end) {
            defaultEndTime = options[i];
            count++;
        }
    }

    return [defaultStartTime, defaultEndTime]

}


export const getTimeOptionsTeacher = () => {
    let options = [{ value: '00:00', label: '12:00 AM' },
    { value: '00:15', label: '12:15 AM' },
    { value: '00:30', label: '12:30 AM' },
    { value: '00:45', label: '12:45 AM' }];
    for (let i = 1; i < 24; i++) {
        for (let k = 0; k < 60; k = k + 15) {
            let label = i < 12 ? `${i}:${k > 0 ? k : '00'} AM` : `${i - 12}:${k > 0 ? k : '00'} PM`;
            let value = '';
            if (i < 10) value = `0${i}:${k > 0 ? k : '00'}`;
            if (i > 9 && i < 12) value = `${i}:${k > 0 ? k : '00'}`;
            if (i > 11) value = `${i}:${k > 0 ? k : '00'}`;
            if (i === 12) {
                label = `12:${k > 0 ? k : '00'} PM`;
                value = `12:${k > 0 ? k : '00'}`
            }
            options.push({ value: value, label: label })
        }

    }
    options = [...options.slice(28), ...options.slice(0, 28)];

    return options;
}






