import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';


import { errorEdit, successEdit, loadingEdit } from '../../../redux/slices/studio.slice';
import styles from "./index.module.scss";
import { removeChosenStyle } from '../../../redux/slices/styles.slice';


function RoomForm(props) {
    const dispatch = useDispatch();
    const [action, setAction] = useState('ROOM_ADD_ONE');
    const { chosenStudio, loading, error, success } = useSelector(state => state.studio);
    const { isAdmin } = useSelector(state => state.personality.user);
    const { chosenUser } = useSelector(state => state.users);
    const { chosenBoard } = useSelector(state => state.boards)
    const { id } = useSelector(state => state.personality.user);
    const [clickDeletItem, setClickDeletItem] = useState(false);

    useEffect(() => {
        return () => {
            clearErrors();
            reset();
            dispatch(successEdit(null));
            dispatch(errorEdit(null));
            dispatch(loadingEdit(false))
        }
    }, [])


    useEffect(() => {
        if (success) reset();
    }, [success])


    const objectYup = {
        studioName: yup.string()
            .required("This field is required")
            .min(2, 'Name is too short - should be 2 chars minimum.'),
    };

    const schema = yup.object(objectYup).required();


    const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            studioName: chosenStudio?.studioName || ''
        }
    });





    const onSubmit = (value) => {
        dispatch(successEdit(null));
        dispatch(errorEdit(null));
        dispatch(loadingEdit(false));
        if (action === 'ROOM_REMOVE_ONE' && !clickDeletItem) {
            setClickDeletItem(true);
            return
        }
        const payload = {
            userId: isAdmin ? chosenUser.id : id,
            studioName: value.studioName,
            studioId: chosenStudio?.id || '',
            boardId: chosenBoard?.id
        };
        // console.log(chosenBoard.indexNewStudio)
        dispatch({ type: action, payload })
    };


    return (<>
        {loading ? (
            <div className={styles.spinnerWrapper}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>) : (
            <>
                {clickDeletItem ? (<div className={styles.confirm}>Are you sure you want to delete the Room?<br />
                    All the classes on the Room would be deleted too</div>) : null}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    {clickDeletItem ? null : (<> <div className={styles.formAnswer}>
                        {error ? <div className="text-danger">{error}</div> : null}
                        {success ? <div className="text-success">{success}</div> : null}
                    </div>
                        <label >Room name</label>  <input  {...register("studioName")} />
                        {errors.studioName ? (
                            <div className={styles.formAnswer}>
                                <div className="text-danger">{errors.studioName?.message}</div>
                            </div>) : null}
                    </>)}
                    {!chosenStudio ? <input type="submit" className={styles.submit} value="Save" onClick={() => setAction('ROOM_ADD_ONE')} /> : (
                        <div className={styles.buttons}>
                            {clickDeletItem ? (<>
                                <button className={styles.submitDelete} onClick={() => setClickDeletItem(false)}> No</button>
                                <input type="submit" className={styles.submitUpdate} value="Yes" />

                            </>) : (<>
                                <input type="submit" className={styles.submitDelete} value="Delete" onClick={() => setAction('ROOM_REMOVE_ONE')} />
                                <input type="submit" className={styles.submitUpdate} value="Save" onClick={() => setAction('ROOM_UPDATE_ONE')} />
                            </>)}

                        </div>)}
                </form></>)}
    </>)
}

export default RoomForm;





