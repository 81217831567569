import React, { useState, useEffect } from 'react'; // Не убирать  не будет работать 
import { useDispatch, useSelector } from "react-redux";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './index.module.scss';
import Toast from 'react-bootstrap/Toast';
import { removeOneToNotificationHistory } from '../../redux/slices/events.slice';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
const HistoryNotification = ({ setIsOpenHistoryNotification }) => {
    const notificationHistory = useSelector((state) => state.events.notificationHistory);
    const scheduleErrors = useSelector((state) => state.events.scheduleErrors);
    const dispatch = useDispatch();
    const { loadingErrors } = useSelector(state => state.events);


    return (
        <div className='app-sidebar' style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', /* background: 'red' */ }}>
            <div className=' my-3 '>
                <button className={styles.editButton} onClick={loadingErrors ? null : (() => {
                    setIsOpenHistoryNotification(false);
                })}>
                    Close history errors
                </button>
            </div>

            {loadingErrors ? <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner> : (
                <>
                    {scheduleErrors.map(item => (
                        <div style={{ marginBottom: '5px' }}>
                            <Toast style={{ width: 'auto' }} >
                                <Toast.Header style={{ background: '#ffe69c' }} closeButton={false}>
                                    <strong className="me-auto" style={{ color: 'black' }}>{item.sticker.className}</strong>
                                </Toast.Header>
                                <Toast.Body className="me-auto text-red" bg='primary' style={{ color: '#a21321', background: '#fff3cd' }}>
                                    {item.errorMessage}:
                                    {/*                             <b> 
                                    {item.errorMessage === 'One or more levels at the same time' ? null : (
                                      <>
                                       {item.sticker.teachers.map((teacher, i) => {
                                        if (i === item.sticker.teachers.length - 1) return " " + teacher
                                        return " " + teacher + ','
                                    })}
                                      </>  
                                    )}
                                   
                                </b> */}

                                </Toast.Body>
                            </Toast>
                        </div>

                    ))}
                </>
            )}

        </div>
    );
}

export default HistoryNotification 
