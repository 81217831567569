import { takeLeading, call, put } from "redux-saga/effects";
import {
  boardsSetAll,
  boardAddOne,
  boardUpdateOne,
  boardRemoveOne,
  addChosenBoard,
  removeChosenBoard,
} from "../slices/boards.slice";

import { loadingEdit, errorEdit, successEdit } from "../slices/boards.slice";
import { boardsService } from "../../services";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";
import { checkTokenAuth } from "./auth.saga";

export function* getAll(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(
      boardsService.getAll,
      { userId: action.query.userId },
      action.query
    );
    yield put(boardsSetAll(response.data));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}
export function* getOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(
      boardsService.getOne,
      action.payload,
      action.query
    );
    yield put(boardsSetAll(response.data));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(boardsService.create, action.payload);
    const data = yield {
      ...response.data.board,
      boardDays: response.data.boardDays,
      studio: response.data.studio,
    };
    yield put(boardAddOne(data));
    yield put(successEdit("New schedule has created"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* duplicateOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(
      boardsService.duplicate,
      action.payload,
      action.query
    );
    const data = yield {
      ...response.data.board,
      boardDays: response.data.boardDays,
      studio: response.data.studio,
    };
    yield put(boardAddOne(data));
    yield put(successEdit("New schedule has created"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(boardsService.update, {
      ...action.payload,
      boardId: action.payload.id,
    });
    yield put(boardUpdateOne(action.payload));
    yield put(addChosenBoard(action.payload));
    yield put(contentEdit("The board has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(boardsService.delete, {
      userId: action.payload.userId,
      boardId: action.payload.id,
    });
    yield put(boardRemoveOne(action.payload.id));
    yield put(removeChosenBoard());
    yield put(contentEdit("The schedule has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateBoardTime(action) {
  yield put(loadingEdit(true));
  try {
    yield call(boardsService.updateBoardTime, action.payload);
    yield put(
      boardUpdateOne({
        id: action.payload.boardId,
        boardDays: action.payload.boardTime,
      })
    );
    yield put(contentEdit("Days have  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response.data.message) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchBoardsSaga() {
  yield takeLeading("BOARDS_GET_ALL", getAll);
  yield takeLeading("BOARDS_GET_ONE", getOne);
  yield takeLeading("BOARDS_ADD_ONE", addOne);
  yield takeLeading("BOARDS_UPDATE_ONE", updateOne);
  yield takeLeading("BOARDS_REMOVE_ONE", removeOne);
  yield takeLeading("UPDATE_BOARD_TIME", updateBoardTime);
  yield takeLeading("BOARDS_DUPLICATE_ONE", duplicateOne);
}
