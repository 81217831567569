import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        modal: null,
        content: null
    },
    reducers: {
        modalEdit: (state, action) => {
            state.modal = action.payload
        },
        contentEdit: (state, action) => {
            state.content = action.payload
        },
    }
})

const { actions, reducer } = modalSlice;

export default reducer;
export const { modalEdit, contentEdit } = actions;



