import { takeLeading, call, put } from "redux-saga/effects";
import {
  levelsSetAll,
  levelAddOne,
  levelUpdateOne,
  levelRemoveOne,
  removeChosenLevel,
} from "../slices/levels.slice";
import { loadingEdit, errorEdit, successEdit } from "../slices/status.slice";
import { levelsService } from "../../services";
import { modalEdit } from "../slices/modal.slice";
import { contentEdit } from "../slices/modal.slice";

export function* getAll(action) {
  const response = yield call(levelsService.getAll, {
    userId: action.payload.userId,
  });
  yield put(levelsSetAll(response.data));
}

function* addOne(action) {
  yield put(loadingEdit(true));
  try {
    const response = yield call(levelsService.create, action.payload);
    yield put(levelAddOne(response.data.level));
    yield put(successEdit("New level has been  created"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* updateOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(levelsService.update, action.payload);
    yield put(levelUpdateOne(action.payload));
    yield put(removeChosenLevel());
    yield put(contentEdit("The level has  been updated"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

function* removeOne(action) {
  yield put(loadingEdit(true));
  try {
    yield call(levelsService.delete, {
      levelId: action.payload.levelId,
      userId: action.payload.userId,
    });
    yield put(levelRemoveOne(action.payload.levelId));
    yield put(removeChosenLevel());
    yield put(contentEdit("The level has been deleted"));
    yield put(modalEdit("Confirm"));
  } catch (error) {
    if (error.response) {
      yield put(errorEdit(error.response.data.message));
    } else {
      yield put(errorEdit("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(loadingEdit(false));
  }
}

export default function* watchLevelsSaga() {
  yield takeLeading("LEVELS_GET_ALL", getAll);
  yield takeLeading("LEVEL_ADD_ONE", addOne);
  yield takeLeading("LEVEL_UPDATE_ONE", updateOne);
  yield takeLeading("LEVEL_REMOVE_ONE", removeOne);
}
