import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const levelsAdapter = createEntityAdapter();

export const initialState = levelsAdapter.getInitialState({
    chosenLevel: null,
})

const levelsSlice = createSlice({
    name: 'levels',
    initialState,
    reducers: {
        levelsSetAll: (state, action) => {
            levelsAdapter.setAll(state, action.payload);
        },
        levelAddOne: (state, action) => {
            levelsAdapter.addOne(state, action.payload);
        },
        levelUpdateOne: (state, action) => {
            levelsAdapter.updateOne(state, {
                id: action.payload.levelId,
                changes: {
                    levelName: action.payload.levelName,
                    color: action.payload.color
                }
            },);
        },
        levelRemoveOne: (state, action) => {
            levelsAdapter.removeOne(state, action.payload);
        },
        addChosenLevel: (state, action) => {
            state.chosenLevel = action.payload
        },
        removeChosenLevel: (state, action) => {
            state.chosenLevel = null
        },

    },
})

const { actions, reducer } = levelsSlice;
export const { levelsSetAll, levelAddOne, levelUpdateOne, levelRemoveOne, addChosenLevel, removeChosenLevel } = actions;

export default reducer;
export const { selectAll } = levelsAdapter.getSelectors(state => state.levels);
