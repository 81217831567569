import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {
        isAdmin: false,
        subscription: []
    },
    userLoading: 'idle'

}

const personalityslice = createSlice({
    name: 'personality',
    initialState,
    reducers: {
        userSet: (state, action) => {
            state.user = action.payload;
        },
        userUpdate: (state, action) => {
            state.user = { ...state, ...action.payload };
        }
    }
})


const { actions, reducer } = personalityslice;
export default reducer;

export const {
    userSet,
    userUpdate,

} = actions