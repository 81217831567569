import React from 'react';
import { useNavigate } from 'react-router-dom';
import { history } from '../../App';
import { useSelector } from 'react-redux';
import { Schedule, Settings, Subscription, User, Users, logo, } from '../../assets/img';

import styles from './index.module.scss';


function Menu({ isOpenMenu, changeIsOpenMenu }) {
    const { isAdmin, subscription } = useSelector(state => state.personality.user);
    const activeMenu = history.location.pathname.slice(1) || ((isAdmin ? "users" : "schedule"));
    const navigate = useNavigate();

    const onChangeMenuItem = (e) => {
        const newActiveItem = e.target.getAttribute("data-menu");
        navigate(`/${newActiveItem}`);
        changeIsOpenMenu(false)

    }

    const getMenuItem = () => {
        let listItemMenu = []
        let dataMenu = isAdmin ? {
            users:
                { title: 'Users', iconReactComponent: Users },
            subscription:
                { title: 'Subscription ', iconReactComponent: Subscription },
            settings:
                { title: 'Settings', iconReactComponent: Settings }
        } : {
            schedule:
                { title: 'My Schedule', iconReactComponent: Schedule },
            profile:
                { title: 'Profile', iconReactComponent: User },
            subscription:
                { title: 'Subscription ', iconReactComponent: Subscription },
            settings:
                { title: 'Settings', iconReactComponent: Settings }
        }

        dataMenu = subscription?.length > 0 || isAdmin ? dataMenu : {
            subscription:
                { title: 'Subscription ', iconReactComponent: Subscription },
            settings:
                { title: 'Settings', iconReactComponent: Settings }
        };

        for (let key in dataMenu) {
            const classNameText = (key === activeMenu) ? [styles.menuItem, styles.activeMenuItem].join(' ') : styles.menuItem;
            const classNameIcon = (key === activeMenu) ? [styles.icon, styles.activeIcon].join(' ') : styles.icon;
            const IconComponent = dataMenu[key]['iconReactComponent'];

            listItemMenu.push(<li className={classNameText} data-menu={key} key={key}>
                <div>
                    <IconComponent className={classNameIcon} color="red" data-menu={key} />
                    <div data-menu={key}>{dataMenu[key].title}</div>
                </div>
                {(key === activeMenu) ? <div className={styles.point} /> : null}
            </li>)
        }

        return listItemMenu;

    }

    const classNameMenu = isOpenMenu ? [styles.menu, styles.activeMenu].join(' ') : styles.menu;

    return (
        <div className={classNameMenu} >
            <img className={styles.logo} alt='logo' src={logo} />
            <ul onClick={onChangeMenuItem} >
                {getMenuItem()}
            </ul>
        </div>
    );
}

export default Menu;
