import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { levelsAdapter } from "./levels.slice";

export const eventsAdapter = createEntityAdapter();

export const initialState = eventsAdapter.getInitialState({
    chosenEvent: null,
    data: null,
    eventsHistory: [],
    notificationHistory: [],
    scheduleErrors: [],
    loading: false,
    error: null,
    success: null,
    loadingErrors: null
})

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        loadingEdit: (state, action) => {
            state.loading = action.payload
        },
        errorEdit: (state, action) => {
            state.error = action.payload
        },
        loadingErrorsEdit:
            (state, action) => {
                state.loadingErrors = action.payload
            },
        successEdit: (state, action) => {
            state.success = action.payload
        },
        eventsSetAll: (state, action) => {
            state.data = action.payload
        },
        eventAddOne: (state, action) => {
            let studioIndex = state.data.studios.findIndex(item => +item.id === +action.payload.studioId)
            if (studioIndex > -1) {
                state.data.studios[studioIndex].scheduleSticker.push(action.payload)

            }
        },
        eventUpdateOne: (state, action) => {
            const { studioId, prevStudioId, id } = action.payload;
            // Find the index of the relevant studio in the state
            let studioIndex = state.data.studios.findIndex(item => +item.id === +studioId)
            // Find the index of the relevant sticker for that studio
            let stickerIndex = state.data.studios[studioIndex].scheduleSticker.findIndex(item => +item.id === +id);


            if (prevStudioId === studioId && stickerIndex > -1) {
                // If the previous studio ID and the current one are the same and there is an existing sticker at the specified index, replace it with the new payload
                state.data.studios[studioIndex].scheduleSticker.splice(stickerIndex, 1, action.payload)
            }
            else {

                // Find the index of the previous studio in the state
                let prevStudioIndex = state.data.studios.findIndex(item => +item.id === +prevStudioId);

                // Remove the sticker from the previous studio's schedule sticker array

                state.data.studios[prevStudioIndex].scheduleSticker.some((sticker, idx) => {
                    if (+sticker.id === +id) {
                        state.data.studios[prevStudioIndex].scheduleSticker.splice(idx, 1);
                        return true; // Exit loop once the sticker has been removed
                    }
                });
                // Add the updated sticker to the current studio's schedule sticker array
                state.data.studios[studioIndex].scheduleSticker.push(action.payload)
            }
        },
        eventRemoveOne: (state, action) => {
            const { studioId, id } = action.payload;
            let studioIndex = state.data.studios.findIndex(item => +item.id === +studioId)
            let stickerIndex = state.data.studios[studioIndex].scheduleSticker.findIndex(item => +item.id === +id);
            if (stickerIndex > -1) {
                state.data.studios[studioIndex].scheduleSticker.splice(stickerIndex, 1)
            }
        },
        addChosenEvent: (state, action) => {
            state.chosenEvent = action.payload
        },

        removeChosenEvent: (state, action) => {
            state.chosenEvent = null
        },
        setScheduleErrors: (state, action) => {
            state.scheduleErrors = action.payload
        },
        addOneToHistory: (state, action) => {
            state.eventsHistory.push(action.payload)
            if (state.eventsHistory.length > 15) {
                state.eventsHistory.shift()
            }
        },
        removeOneFromHistory: (state, action) => {
            if (state.eventsHistory.length > 0) {
                state.eventsHistory.pop()
            }

        },
        addOneToNotificationHistory: (state, action) => {
            state.notificationHistory.push(action.payload)

        },
        removeOneToNotificationHistory: (state, action) => {
            state.notificationHistory = state.notificationHistory.filter(item => +item.id !== +action.payload)

        },
        resetNotificationHistory: (state, action) => {
            state.notificationHistory = [];

        },
        changeNotificationHistory: (state, action) => {
            const newHistoryNotification = state.notificationHistory.filter(item => +item.id !== +action.payload.id);
            newHistoryNotification.push(action.payload);
            state.notificationHistory = newHistoryNotification;

        },
        resetHistory: (state, action) => {
            state.eventsHistory = [];

        },
        changeHistory: (state, action) => {
            state.eventsHistory = state.eventsHistory.map(item => {
                if (item.eventBody.id == action.payload.oldId && action.payload.newId) {
                    return {
                        ...item,
                        eventBody: {
                            ...item.eventBody,
                            id: action.payload.newId,
                            scheduleId: action.payload.newId
                        }

                    }
                } else {
                    if (item.sticker?.id == action.payload.oldId && action.payload.newId) {
                        return {
                            ...item,
                            sticker: {
                                ...item.sticker,
                                id: action.payload.newId,

                            }
                        }
                    }
                    return item
                }
            })
        }

    },
})

const { actions, reducer } = eventsSlice;
export const { loadingErrorsEdit, loadingEdit, errorEdit, successEdit, setScheduleErrors, resetNotificationHistory, changeNotificationHistory, addOneToNotificationHistory, removeOneToNotificationHistory, eventsSetAll, eventAddOne, eventUpdateOne, eventRemoveOne, addChosenEvent, removeChosenEvent, addOneToHistory, removeOneFromHistory, resetHistory, changeHistory } = actions;

export default reducer;

