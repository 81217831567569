export const customStylesSelect = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: "1px solid #D9D9D9",
        borderRadius: "10px",
        background: "#F9F9FC",
        marginBottom: 10
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    }),

};


export const customStylesSelectForTime = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: "1px solid #D9D9D9",
        borderRadius: "10px",
        background: "#F9F9FC",
        marginBottom: 10,
        width: "180px"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};