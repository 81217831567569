import {Accordion, Button, CloseButton, Form, Modal} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {filterSetAll} from '../../redux/slices/scheduleFilters.slice';
import {useDispatch, useSelector} from "react-redux";
import {getLengthOptions} from "../../utilities";
import {selectAll as selectAllStyles} from "../../redux/slices/styles.slice";
import {selectAll as selectAllTeachers} from "../../redux/slices/teachers.slice";
import {selectAll as selectAllLevels} from "../../redux/slices/levels.slice";
import { timeSlotInterval } from "constants/variables";

const DialogModalMemo = ({onClose, isOpen}) => {
    const allStyles = useSelector(selectAllStyles);
    const allTeachers = useSelector(selectAllTeachers);
    const allLevels = useSelector(selectAllLevels);
    const storedFilters = useSelector((state) => state.scheduleFilters.filters)
    const [filters, setFilters] = useState(storedFilters);
    
    const dispatch = useDispatch()
    const onSubmit = () => {
        let activeFilters = {}
        for (let [key, value] of Object.entries(filters)) {
            activeFilters[key] = value.filter((item) => item.checked === true)
        }

        activeFilters = Object.keys(filters).filter(item => activeFilters[item].length > 0)
        if(activeFilters.length === 0) {
            dispatch(filterSetAll(null))

        }
        else {
            dispatch(filterSetAll(filters))
        }
        onClose(false)
    }
    
    const defaultOptions = useCallback(() => {
        const getOptions = (data, entity) => {
            return data.map((item) => ({ value: item.color || item[`${entity}Name`], label: item[`${entity}Name`], entity: `${entity}s`, id: item.id }))
        }
        const [lengthOptions] = getLengthOptions([], timeSlotInterval.minutes_5);
        const optionsStyles = getOptions(allStyles, 'style');
        const optionsTeachers = getOptions(allTeachers, 'teacher');
        const optionsLevels = getOptions(allLevels, 'level');

        const filters = {
            length: lengthOptions,
            levels: optionsLevels,
            styles: optionsStyles,
            teachers: optionsTeachers
        }
        setFilters(filters)
    }, [allLevels, allStyles, allTeachers])

    const resetFilters = () => {
        defaultOptions()
    }

    useEffect(() => {
        if(!storedFilters){
            defaultOptions()
        } else {
            setFilters(storedFilters)
        }
    }, [defaultOptions, storedFilters]);

    const onSelectChange = (field) => (event, index) => {
        const {checked} = event.currentTarget
        const newFiltersArr = JSON.parse(JSON.stringify(filters))
        newFiltersArr[field][index].checked = checked
        setFilters(newFiltersArr)
    }

    if (!isOpen) {
        return null
    }

    return (
        <Modal.Dialog className="modal show filtersModal">
            <Modal.Header className="justify-content-center">
                <Modal.Title>Filters</Modal.Title>
                <CloseButton onClick={onClose}/>
            </Modal.Header>

            <Modal.Body>
                <Accordion className="" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><p className="m-0">Level</p></Accordion.Header>
                        <Accordion.Body className="accordionBodyFilter">
                            <div className="d-flex flex-column mb-2">
                                {filters?.levels?.map((item, index) => {
                                    return (
                                        <Form.Check
                                            checked={item?.checked || false}
                                            onChange={(event) => onSelectChange("levels")(event, index)}
                                            bsPrefix="checkboxFilters"
                                            inline
                                            value={item.value}
                                            name={item.label}
                                            label={item.label}
                                            type="checkbox"
                                            id={`level-checkbox-${index}`}
                                            key={`level-checkbox-${index}`}

                                        />
                                    )
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><p className="m-0">Style</p></Accordion.Header>
                        <Accordion.Body className="accordionBodyFilter">
                            <div className="d-flex flex-column mb-2">
                                {filters.styles?.map((item, index) => {
                                    return (
                                        <Form.Check
                                            checked={item?.checked || false}
                                            value={item.value}
                                            bsPrefix="checkboxFilters"
                                            inline
                                            onChange={(event) => onSelectChange("styles")(event, index)}
                                            label={item.label}
                                            name={item.label}
                                            type="checkbox"
                                            id={`styles-checkbox-${index}`}
                                            key={`styles-checkbox-${index}`}
                                        />
                                    )
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><p className="m-0">Teacher</p></Accordion.Header>
                        <Accordion.Body className="accordionBodyFilter">
                            <div className="d-flex flex-column mb-2">
                                {filters.teachers?.map((item, index) => {
                                    return (
                                        <Form.Check
                                            checked={item?.checked || false}
                                            value={item.value}
                                            onChange={(event) => onSelectChange("teachers")(event, index)}
                                            bsPrefix="checkboxFilters"
                                            inline
                                            label={item.label}
                                            name={item.label}
                                            type="checkbox"
                                            id={`teacher-checkbox-${index}`}
                                            key={`teacher-checkbox-${index}`}
                                        />
                                    )
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><p className="m-0">Length</p></Accordion.Header>
                        <Accordion.Body className="accordionBodyFilter">
                            <div className="d-flex flex-column mb-2">
                                {filters.length.map((item, index) => {
                                    return (
                                        <Form.Check
                                            checked={item?.checked || false}
                                            onChange={(event) => onSelectChange("length")(event, index)}
                                            bsPrefix="checkboxFilters"
                                            inline
                                            value={item.value}
                                            label={item.label}
                                            name={item.label}
                                            type="checkbox"
                                            id={`length-checkbox-${index}`}
                                            key={`length-checkbox-${index}`}
                                        />
                                    )
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button onClick={resetFilters} className="greyButton mediumSizeButton" variant="outline-secondary">Reset
                    All</Button>
                <Button onClick={onSubmit} className="primaryButton mediumSizeButton"
                        variant="primary">Submit</Button>
            </Modal.Footer>
        </Modal.Dialog>
    );
}
export const DialogModal = React.memo(DialogModalMemo);
