import React from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { modalEdit, contentEdit } from '../../redux/slices/modal.slice';

function ModalConfirm() {
    const { content } = useSelector(state => state.modal);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(contentEdit(null));
        dispatch(modalEdit(false));
    }


    return (
        <div className={styles.overlay}>
            <div className={styles.window}>
                {content}
                <input type="submit" className={styles["submit"]} value="Ok" onClick={onClose} />
            </div>
        </div>
    );
}

export default ModalConfirm;

